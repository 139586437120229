<template>
    <div>
        <div class="row">
            <div class="col-md-8">
                <div class="heading-orange mt-3">
                    団体会員専用ページ
                </div>
                <levelIcon
                    :set_level="setLevel"
                />
            </div>
            <div class="col-md-4 mt-4">
                <button class="btn btn-bcp-restrictions py-2 me-3" @click="backMenu" :disabled="isProcessing"><i class="bi bi-gear"></i><span class="ms-3">マイページ</span></button>
                <input type="button" class="btn btn-black px-4 py-2" value="前ページへ戻る" @click="backAdministratorHome" :disabled="isProcessing">
            </div>
        </div>
        <div class="row mt-3 my-5">
            <table class="table table-bordered bg-light">
                <thead>
                    <tr>
                        <th>会員ID</th>
                        <th>組織名</th>
                        <th>管理者所属部署</th>
                        <th>管理者役職</th>
                        <th>管理者名</th>
                        <th>管理者メールアドレス</th>
                        <th>管理者電話番号</th>
                        <th>利用開始日</th>
                        <th>有効期限日</th>
                        <th>退会</th>
                        <th>請求情報</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="member in group_member_list" :key="member.id">
                        <td>{{ member.企業ID }}</td>
                        <td>{{ member.組織名 }}</td>
                        <td>{{ member.管理者_所属部署 }}</td>
                        <td>{{ member.管理者_役職 }}</td>
                        <td>{{ member.管理者名_姓 + " " + member.管理者名_名 }}</td>
                        <td>{{ member.管理者_メールアドレス }}</td>
                        <td>{{ member.管理者_電話番号 }}</td>
                        <td>{{ member.有償会員切替日 }}</td>
                        <td>{{ member.有効期限日 }}</td>
                        <td>
                            <button 
                                v-if="!isBeforeToday(member.有効期限日)"
                                class="btn"
                                :class="getButtonClass(member.会員資格更新種別)"
                                @click="updateWithdrawal(member.id, member.組織名, member.会員資格更新種別)"
                                :disabled="isProcessing"
                            >
                                {{ getButtonLabel(member.会員資格更新種別) }}
                            </button>
                        </td>
                        <td><input type="button" class="btn btn-green" value="請求情報" @click="showBill(member.id)" :disabled="isProcessing"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import { inject, ref } from 'vue'
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const dayjs = inject('dayjs');

        const setLevel = ref('111111');

        const backMenu = () => {
            const url = '/al/myPage/home';

            location.href = url;
        }

        const backAdministratorHome = () => {
            const url = "/al/administrator/home";

            location.href = url;
        }

        const isBeforeToday = (date) => {
            const today = dayjs().startOf('day'); // 今日の日付を取得
            const itemDate = dayjs(date).startOf('day'); // 比較する日付

            return itemDate.isBefore(today); // 今日より前かどうかを判定
        }

        const getButtonClass = (code) => {
            if(code === 10060003){
                //退会予告
                return "btn-withdrawal";
            }
            return "btn-black";
        }

        const getButtonLabel = (code) => {
            if(code === 10060003){
                //退会予告
                return "退会取消";
            }
            return "退会";
        }

        const showBill = (id) => {
            const url = "/al/administrator/show_group_bill/" + id;

            location.href = url;
        }

        const isProcessing = ref(false);

        const updateWithdrawal = (corp_id, name, status) => {
            const msg = getButtonLabel(status);

            if(confirm(name + "の" + msg + "処理を行います") === false){
                return;
            }

            const url = "/api/administrator/updateWithdrawal";

            isProcessing.value = true;

            axios.post(url, {
                id: corp_id,
            }).then(res=>{
                location.reload();
            }).catch(err=>{

            }).finally(fin=>{
                isProcessing.value = false;
            })
        }

        return {
            setLevel,
            isProcessing,

            backMenu,
            backAdministratorHome,
            isBeforeToday,
            getButtonClass,
            getButtonLabel,
            showBill,
            updateWithdrawal,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        group_member_list: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
    .btn-withdrawal {
        border: 1px solid #afabab;
        color: white;
        background-color: #afabab;
    }

    .btn-withdrawal:hover,
    .btn-withdrawal:disabled {
        border: 1px solid #afabab;
        color: #afabab;
        background-color: white;
    }

</style>