<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            BCP更新記録
        </div>
    </div>
    <levelIcon
        :set_level="setLevel"
    />
    <div class="table-responsive-md">
        <table class="ms-3 mt-3 table table-bordered border-black">
            <thead>
                <tr>
                    <th>BCPを見直す基準（入門コース策定／自動表示）</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in standardList" :key="item.seq">
                    <td>
                        <div class="objective-content">{{ item.title }}<br><div class="second-line">{{ item.body }}</div></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="objective-content" v-html="formattedText(textOther)"></div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="p2 ms-3 mt-3 mb-5 col-md-4">
            修正する➔　<input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="入門｜BCPの見直しへ" :disabled="isProcessing" @click="showBcpReview">
        </div>
    </div>

    <hr>

    <div class="table-responsive-md">
        <table class="ms-3 mt-5 table-bordered border-black">
            <thead>
                <tr>
                    <th><div class="mx-2 py-2">BCPの更新時期</div></th>
                    <th><div class="mx-2 py-2">更新回数（年）</div></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div class=" mx-2 d-flex align-items-top justify-content-between py-2">
                            <div>毎年</div>
                            <div>{{ valueForms.K00010.value }}</div>
                            <div>月</div>
                        </div>
                    </td>
                    <td>
                        <div class=" mx-2 d-flex align-items-top justify-content-around py-2">
                            <div>{{ valueForms.K00011.value }}</div>
                            <div>回</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="p2 ms-3 mt-3 mb-5 col-md-4">
            修正する➔　<input type="button" class="btn btn-bcp-blue" value="基本｜BCPの更新時期へ" :disabled="isProcessing" @click="showUpdateSchedule">
        </div>
    </div>

    <hr>

    <div class="table-responsive-md">
        <div class="d-flex align-items-top justify-content-center">
            <table class="ms-3 mt-3 table table-bordered border-black">
                <thead>
                    <tr>
                        <th colspan="1" scope="col"><div class="">BCPの更新日</div></th>
                        <th colspan="2" scope="col"><div class="">更新内容</div></th>
                        <th class="border-none"></th>
                    </tr>
                </thead>
                <tbody v-for="(version, versionIndex) in versionList" :key="versionIndex">
                    <template v-for="(val, index) in versionList[versionIndex]" :key="index">
                        <tr>
                            <td style="width: 10%" v-if="index === 0" :rowspan="version.length">
                                <div class="d-flex align-items-top">
                                    <div>{{ val.作成日 }}</div>
                                </div>
                            </td>
                            <td style="width: 12%">
                                <div class="d-flex align-items-top justify-content-center">
                                    <div class="py-1" v-if="val.version === 1">作成</div>
                                    <div v-if="val.version !== 1">
                                        <select name="selectAdminCandidate" class="form-select" v-model="val.update_type">
                                            <option value="" selected>-更新内容-</option>
                                            <option value="1" >追加</option>
                                            <option value="2" >削除</option>
                                            <option value="3" >修正</option>
                                        </select>
                                    </div>
                                </div>
                            </td>
                            <td class="col-md-9">
                                <div class="ms-2" v-if="val.version === 1">
                                    <div></div>
                                </div>
                                <div class="d-flex align-items-top" v-if="val.version !== 1">
                                    <input type="text" class="form-control" v-model="val.update_details">
                                </div>
                            </td>
                            <td class="border-none">
                                <div v-if="val.version===1" class="d-flex justify-content-center">
                                    <VDropdown
                                        theme="hint-theme"
                                    >
                                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                        <template #popper>
                                            <div class="row">
                                                <label>{{ hint_word_1 }}</label>
                                            </div>
                                        </template>
                                    </VDropdown>
                                </div>
                                <div v-if="val.version!==1 && index===versionList[versionIndex].length-1" class="mt-2">
                                    <input type="button" class="btn btn-black btn-sm" value="1行増やす" @click="addRow(versionIndex)">
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div class="p2 mt-3 ms-3 mb-5 col-md-4">
            修正する➔　<input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="入門｜BCP表紙へ" :disabled="isProcessing" @click="showBcpTitle">
        </div>
    </div>

    <div class="mt-5 ms-3 pb-5">
        <input type="button" class="btn btn-new-window-button btn-sm" value="診断改善HOMEへ戻る" :disabled="isProcessing" @click="showHome" />
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import { useTextConverter } from '../../function/CommonConvertNewLine';
const { convertNewLine } = useTextConverter();

import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_introduction_texts_info.length > 0) {
                props.bcp_introduction_texts_info.forEach(data => {
                    if(data.column_name === "N00061" || data.column_name === "N00062" || data.column_name === "N00063"){
                        if(textOther.value.length === 0){
                            textOther.value = data.text_value;
                        }else{
                            textOther.value = textOther.value + "\r\n" + data.text_value;
                        }
                    }
                });
                // Object.keys(textForms).forEach(columnName => {
                //     textForms[columnName].value = props.bcp_introduction_texts_info.find(
                //         (fetchData) => fetchData.column_name === columnName
                //     )?.text_value;
                // });
            }

            if (props.bcp_values_info.length > 0) {
                Object.keys(valueForms).forEach(columnName => {
                    valueForms[columnName].value = props.bcp_values_info.find(
                        (fetchData) => fetchData.column_name === columnName
                    )?.value;
                });
            }

            if (props.bcp_introduction_values_info.length > 0){
                const wkArray = props.bcp_introduction_values_info.filter(item => item.column_name === 'N10061').map(item => item.seq);
                if(wkArray.length > 0){
                    console.log(wkArray.length);
                    wkArray.forEach(val=>{
                        let wkObj;
                        wkObj = textForms.filter(item=>item.seq === val);

                        if(wkObj.length > 0){
                            standardList.value.push({
                                columnName: wkObj[0].columnName,
                                title: wkObj[0].title,
                                body: wkObj[0].body,
                                seq: wkObj[0].seq,
                            });
                        }
                    });
                }
            }

            if (props.bcp_update_records.length > 0) {
                // 更新日ごとにグルーピングする
                const groupByUpdate = function(xs, key) {
                    return xs.reduce(function(rv, x) {
                        (rv[x[key]] = rv[x[key]] || []).push(x);
                        return rv;
                    }, {});
                };
                versionList.value = groupByUpdate(props.bcp_update_records, 'version');
                for(let key in versionList.value) {
                    versionList.value[key].forEach((val, index) => {
                        // seq振り直し
                        val.seq = index + 1;
                        val.update_type = val.update_type !== null ? val.update_type : '';
                        val.update_details = val.update_details !== null ? val.update_details : '';
                    });
                }
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('111100');
        const isProcessing = ref(false);

        const valueForms = reactive({
            K00010: {
                value: '',
            },
            K00011: {
                value: '',
            },
        });

        const versionList = ref([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const hint_word_1 = ref(`「追加」「削除」「修正」から更新区分を選択し、理由および更新内容を備忘として残します。このページは${callReplaceWord('従業員')}全員が閲覧することができます。`);

        // 1行増やすボタン
        const addRow = (versionIndex) => {
            versionList.value[versionIndex].push({
                version: versionIndex,
                seq: versionList.value[versionIndex].length + 1,
                update_type: '',
                update_details: '',
            });
        }

        const showBcpReview = async() => {
            if(props.bcp_update_records.length > 0){
                const result = await registerPdca8();
                if (result == '200') {
                    const url = '/bcp/bcp_introduction/8#a';
                    location.href = url;
                }
            }else{
                const url = '/bcp/bcp_introduction/8#a';
                location.href = url;
            }
        }

        const showUpdateSchedule = async() => {
            if(props.bcp_update_records.length > 0){
                const result = await registerPdca8();
                if (result == '200') {
                    const url = '/bcp/bcp_first/1#a';
                    location.href = url;
                }
            }else{
                const url = '/bcp/bcp_first/1#a';
                location.href = url;
            }
        }

        const showBcpTitle = async() => {
            if(props.bcp_update_records.length > 0){
                const result = await registerPdca8();
                if (result == '200') {
                    const url = '/bcp/bcp_introduction/1';
                    location.href = url;
                }
            }else{
                const url = '/bcp/bcp_introduction/1';
                location.href = url;
            }
        }

        const showHome = async () => {
            const result = await registerPdca8();
            if (result == '200') {
                const url = '/pdca/home';
                location.href = url;
            }
        }

        // BCP更新記録を登録
        const registerPdca8 = async() => {
            // リクエストの整形
            const reqUpdateRecordForms = [];
            for(let key in versionList.value) {
                versionList.value[key].forEach((val, index) => {
                    if (val.update_type !== '' || val.update_details !== '') {
                            reqUpdateRecordForms.push({
                            version: val.version,
                            seq: index + 1,
                            update_type: val.update_type,
                            update_details: val.update_details,
                        });
                    }
                });
            }

            const postUrl = '/api/pdca/registerPdca8';
            isProcessing.value = true;

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_update_records[0].id,
                'update_record_forms': reqUpdateRecordForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const textForms = reactive([
            {
                columnName: 'N10061',
                title: "定期的な見直し",
                body: "毎年1回以上、事前対策の進捗状況や問題点をチェックし、必要に応じてBCPを見直す。",
                seq: 1,
            },
            {
                columnName: 'N10061',
                title: callReplaceWord("組織") + "の構造変更",
                body: "合併、買収、新規事業の開始、人事異動、組織改編など、内部環境に大きな変化があった場合にBCPを見直す。",
                seq: 2,
            },
            {
                columnName: 'N10061',
                title: "業務プロセスの変更",
                body: "業務プロセスの大幅な変更など、内部環境に大きな変化があった場合にBCPを見直す。",
                seq: 3,
            },
            {
                columnName: 'N10061',
                title: "サプライチェーンや主要パートナーの変更",
                body: "主要なサプライヤー、サービスプロバイダー、パートナー企業の変更や破綻、またはサプライチェーンの大幅な再編成があった場合にBCPを見直す。",
                seq: 4,
            },
            {
                columnName: 'N10061',
                title: "設備や技術の導入・更新",
                body: "新しいシステム、ソフトウェア、ハードウェア、設備の導入や既存技術の更新が行われた場合にBCPを見直す。",
                seq: 5,
            },
            {
                columnName: 'N10061',
                title: "法規制や業界標準の変更",
                body: "法律や業界の規制、標準、ガイドラインが変更された場合にBCPを見直す。",
                seq: 6,
            },
            {
                columnName: 'N10061',
                title: "組織の財務状況の変化",
                body: "重大な財務状況の変化（経済状況の悪化、予算の大幅な削減、資金繰りの変化など）があった場合にBCPを見直す。",
                seq: 7,
            },
            {
                columnName: 'N10061',
                title: "新たなリスクの出現やリスク評価の変化",
                body: "新しい脅威やリスク（自然災害の頻度増加、サイバー攻撃の増加、パンデミックなど）が明らかになった場合、または既存のリスク評価が変化した場合にBCPを見直す。",
                seq: 8,
            },
            {
                columnName: 'N10061',
                title: "BCP訓練で問題点が発見された場合や実際の緊急対応後のフィードバック",
                body: "BCPの訓練や演習を実施した結果、問題や改善点が見つかった場合。または実際の緊急対応後に得られた教訓や問題点を反映するために見直す。",
                seq: 9,
            },
        ]);

        const standardList = ref([]);

        const textOther = ref("");

        const formattedText = (text) => {
            return convertNewLine(text);
        }

        return {
            setLevel,
            isProcessing,
            textForms,
            valueForms,
            versionList,
            hint_word_1,
            addRow,
            showBcpReview,
            showUpdateSchedule,
            showBcpTitle,
            showHome,
            registerPdca8,
            standardList,
            textOther,
            formattedText,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_update_records: {
            type: Array,
        },
        bcp_introduction_texts_info: {
            type: Array,
        },
        bcp_values_info: {
            type: Array,
        },
        bcp_introduction_values_info: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
.no-display {
    display: none;
}

.border-black {
    border-color: #808080;
}

.border-none {
    background: #f2f2f2;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
}

table thead tr th {
    background-color: #d9d9d9;
}

table tbody tr td {
    background-color: #f2f2f2;
}

.objective-content {
    min-height: 3.5em;
}

.second-line {
    display: inline-block;
    text-indent: 20px; /* インデントの幅を調整できます */
}

</style>
