<template>
    <div class="row">
        <div class="col-md-6">
            <div class="h5 heading-orange mt-3">
                メモ書き一覧
            </div>
            <levelIcon
                :set_level="setLevel"
            />
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-start justify-content-end pe-md-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <div class="row ms-5 flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
        {{ flashMessage }}
    </div>

    <div class="row mt-5">
        <div class="col-md-4">
            <label for="select部署" class="form-label">部署</label>
            <select class="form-select"  v-model='select部署' id="select部署" @change="changeDepartment">
                <option value="指定なし">指定なし</option>
                <option v-for="department in department_list" :key="department.部署" :value="department.部署">{{ department.部署 }}</option>
            </select>
        </div>
    </div>
    <div class="row mt-5 mx-1 mx-md-0 fs-half">
        <table class="table table-bordered table-border-resilience-gray">
            <tbody>
                <tr>
                    <td>回答があった{{ callReplaceWord('従業員') }}等</td>
                    <td>投稿時間</td>
                    <td>メモ書きの内容</td>
                </tr>
                <tr>
                    <td colspan="3" class="header">◯　二次災害の防止措置（事業所からの退避、応急手当や初期消火、警察・消防への通報、重要書類の保護）</td>
                </tr>
                <tr v-for="(memo, index) in memo_list_30300001" :key="memo.id">
                    <td>{{ memo.入力者 }}<br v-if="is('administrator')"><input type="button" class="btn btn-delete mt-2 btn-sm px-3" value="削除" v-if="is('administrator')" @click="deleteMemo(memo.id, '30300001', index)"></td>
                    <td>{{ this.$root.dayjs(memo.created_at).format('YYYY-MM-DD HH:mm') }}</td>
                    <td v-html="formattedText(memo.memo)"></td>
                </tr>
                <tr>
                    <td colspan="3" class="header">◯　{{ callReplaceWord('従業員') }}の参集（{{ callReplaceWord('経営者') }}の対応、{{ callReplaceWord('従業員') }}の参集）</td>
                </tr>
                <tr v-for="(memo, index) in memo_list_30300002" :key="memo.id">
                    <td>{{ memo.入力者 }}<br v-if="is('administrator')"><input type="button" class="btn btn-delete mt-2 btn-sm px-3" value="削除" v-if="is('administrator')" @click="deleteMemo(memo.id, '30300002', index)"></td>
                    <td>{{ this.$root.dayjs(memo.created_at).format('YYYY-MM-DD HH:mm') }}</td>
                    <td v-html="formattedText(memo.memo)"></td>
                </tr>
                <tr>
                    <td colspan="3" class="header">◯　安否・被災状況の把握（{{ callReplaceWord('顧客') }}、{{ callReplaceWord('従業員') }}とその家族の安否、建屋、{{ callReplaceWord('生産') }}機械、通信機器、情報システム、地域住民や近隣事業所、自然災害、交通やライフライン）</td>
                </tr>
                <tr v-for="(memo, index) in memo_list_30300003" :key="memo.id">
                    <td>{{ memo.入力者 }}<br v-if="is('administrator')"><input type="button" class="btn btn-delete mt-2 btn-sm px-3" value="削除" v-if="is('administrator')" @click="deleteMemo(memo.id, '30300003', index)"></td>
                    <td>{{ this.$root.dayjs(memo.created_at).format('YYYY-MM-DD HH:mm') }}</td>
                    <td v-html="formattedText(memo.memo)"></td>
                </tr>
                <tr>
                    <td colspan="3" class="header">◯　{{ callReplaceWord('顧客') }}・{{ callReplaceWord('協力会社') }}への連絡（連絡手段の確保、{{ callReplaceWord('顧客') }}への被災状況報告、{{ callReplaceWord('協力会社') }}の被災状況把握）</td>
                </tr>
                <tr v-for="(memo, index) in memo_list_30300004" :key="memo.id">
                    <td>{{ memo.入力者 }}<br v-if="is('administrator')"><input type="button" class="btn btn-delete mt-2 btn-sm px-3" value="削除" v-if="is('administrator')" @click="deleteMemo(memo.id, '30300004', index)"></td>
                    <td>{{ this.$root.dayjs(memo.created_at).format('YYYY-MM-DD HH:mm') }}</td>
                    <td v-html="formattedText(memo.memo)"></td>
                </tr>
                <tr>
                    <td colspan="3" class="header">◯　中核{{ callReplaceWord('事業') }}の継続方針立案・体制確立（中核{{ callReplaceWord('事業') }}のダメージ判断、目標復旧時間設定、応急・復旧対策方針の決定、財務の予測診断、実施体制の確立、拠点場所の確保）</td>
                </tr>
                <tr v-for="(memo, index) in memo_list_30300005" :key="memo.id">
                    <td>{{ memo.入力者 }}<br v-if="is('administrator')"><input type="button" class="btn btn-delete mt-2 btn-sm px-3" value="削除" v-if="is('administrator')" @click="deleteMemo(memo.id, '30300005', index)"></td>
                    <td>{{ this.$root.dayjs(memo.created_at).format('YYYY-MM-DD HH:mm') }}</td>
                    <td v-html="formattedText(memo.memo)"></td>
                </tr>
                <tr>
                    <td colspan="3" class="header">◯　{{ callReplaceWord('顧客') }}・{{ callReplaceWord('協力会社') }}向け対策（取引調整〈他の{{ callReplaceWord('組織') }}等への一時移管を含む〉、取引復元）</td>
                </tr>
                <tr v-for="(memo, index) in memo_list_30300006" :key="memo.id">
                    <td>{{ memo.入力者 }}<br v-if="is('administrator')"><input type="button" class="btn btn-delete mt-2 btn-sm px-3" value="削除" v-if="is('administrator')" @click="deleteMemo(memo.id, '30300006', index)"></td>
                    <td>{{ this.$root.dayjs(memo.created_at).format('YYYY-MM-DD HH:mm') }}</td>
                    <td v-html="formattedText(memo.memo)"></td>
                </tr>
                <tr>
                    <td colspan="3" class="header">◯　{{ callReplaceWord('従業員') }}・{{ callReplaceWord('事業') }}資源対策（{{ callReplaceWord('従業員') }}との情報共有と生活支援、建屋の修理・一時移転、{{ callReplaceWord('生産') }}機械の修理・調達、情報システムの回復、供給品の調達）</td>
                </tr>
                <tr v-for="(memo, index) in memo_list_30300007" :key="memo.id">
                    <td>{{ memo.入力者 }}<br v-if="is('administrator')"><input type="button" class="btn btn-delete mt-2 btn-sm px-3" value="削除" v-if="is('administrator')" @click="deleteMemo(memo.id, '30300007', index)"></td>
                    <td>{{ this.$root.dayjs(memo.created_at).format('YYYY-MM-DD HH:mm') }}</td>
                    <td v-html="formattedText(memo.memo)"></td>
                </tr>
                <tr>
                    <td colspan="3" class="header">◯　財務対策（運転資金の確保、決済不渡り対策、仕入支払い・給与支払い、復旧資金の確保）</td>
                </tr>
                <tr v-for="(memo, index) in memo_list_30300008" :key="memo.id">
                    <td>{{ memo.入力者 }}<br v-if="is('administrator')"><input type="button" class="btn btn-delete mt-2 btn-sm px-3" value="削除" v-if="is('administrator')" @click="deleteMemo(memo.id, '30300008', index)"></td>
                    <td>{{ this.$root.dayjs(memo.created_at).format('YYYY-MM-DD HH:mm') }}</td>
                    <td v-html="formattedText(memo.memo)"></td>
                </tr>
                <tr>
                    <td colspan="3" class="header">◯　地域貢献活動（被災者の救出・応急救護・初期消火、商品等の提供、ボランティア活動）</td>
                </tr>
                <tr v-for="(memo, index) in memo_list_30300009" :key="memo.id">
                    <td>{{ memo.入力者 }}<br v-if="is('administrator')"><input type="button" class="btn btn-delete mt-2 btn-sm px-3" value="削除" v-if="is('administrator')" @click="deleteMemo(memo.id, '30300009', index)"></td>
                    <td>{{ this.$root.dayjs(memo.created_at).format('YYYY-MM-DD HH:mm') }}</td>
                    <td v-html="formattedText(memo.memo)"></td>
                </tr>
            </tbody>
        </table>
    </div>

</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import { useTextConverter } from '../../../function/CommonConvertNewLine';
const { convertNewLine } = useTextConverter();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            if(props.param_memo_list_30300001.length > 0){
                memo_list_30300001.value = [...props.param_memo_list_30300001];
            }
            if(props.param_memo_list_30300002.length > 0){
                memo_list_30300002.value = [...props.param_memo_list_30300002];
            }
            if(props.param_memo_list_30300003.length > 0){
                memo_list_30300003.value = [...props.param_memo_list_30300003];
            }
            if(props.param_memo_list_30300004.length > 0){
                memo_list_30300004.value = [...props.param_memo_list_30300004];
            }
            if(props.param_memo_list_30300005.length > 0){
                memo_list_30300005.value = [...props.param_memo_list_30300005];
            }
            if(props.param_memo_list_30300006.length > 0){
                memo_list_30300006.value = [...props.param_memo_list_30300006];
            }
            if(props.param_memo_list_30300007.length > 0){
                memo_list_30300007.value = [...props.param_memo_list_30300007];
            }
            if(props.param_memo_list_30300008.length > 0){
                memo_list_30300008.value = [...props.param_memo_list_30300008];
            }
            if(props.param_memo_list_30300009.length > 0){
                memo_list_30300009.value = [...props.param_memo_list_30300009];
            }
        })
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('111100');
        const isProcessing = ref(false);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = async () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const select部署 = ref('指定なし');

        const formattedText = (text) => {
            return convertNewLine(text);
            return text.replace(/\n/g, '<br>');
        }

        const memo_list_30300001 = ref([]);
        const memo_list_30300002 = ref([]);
        const memo_list_30300003 = ref([]);
        const memo_list_30300004 = ref([]);
        const memo_list_30300005 = ref([]);
        const memo_list_30300006 = ref([]);
        const memo_list_30300007 = ref([]);
        const memo_list_30300008 = ref([]);
        const memo_list_30300009 = ref([]);

        const changeDepartment = () => {
            const url = '/api/bcp/activate/getMemo?department=' + select部署.value;

            axios.get(url).then(res=>{
                memo_list_30300001.value = [...res.data.memo_list_30300001];
                memo_list_30300002.value = [...res.data.memo_list_30300002];
                memo_list_30300003.value = [...res.data.memo_list_30300003];
                memo_list_30300004.value = [...res.data.memo_list_30300004];
                memo_list_30300005.value = [...res.data.memo_list_30300005];
                memo_list_30300006.value = [...res.data.memo_list_30300006];
                memo_list_30300007.value = [...res.data.memo_list_30300007];
                memo_list_30300008.value = [...res.data.memo_list_30300008];
                memo_list_30300009.value = [...res.data.memo_list_30300009];

            });
        }

        const deleteMemo = (id, kbn, index) => {
            let kbn_name;
            switch(kbn){
                case "30300001":
                    kbn_name = "二次災害の防止措置";
                    break;
                case "30300002":
                    kbn_name = "従業員の参集";
                    break;
                case "30300003":
                    kbn_name = "安否・被災状況の把握";
                    break;
                case "30300004":
                    kbn_name = "顧客・協力会社への連絡";
                    break;
                case "30300005":
                    kbn_name = "中核事業の継続方針立案・体制確立";
                    break;
                case "30300006":
                    kbn_name = "顧客・協力会社向け対策";
                    break;
                case "30300007":
                    kbn_name = "従業員・事業資源対策";
                    break;
                case "30300008":
                    kbn_name = "財務対策";
                    break;
                case "30300009":
                    kbn_name = "地域貢献活動";
                    break;
                default:
                    kbn_name = "";
            }

            if(kbn_name === ""){
                return;
            }

            if(!confirm(kbn_name + "の" + (index + 1) + "番目を削除します。よろしいですか？")){
                return;
            }

            isProcessing.value = true;

            const url = "/api/bcp/activate/deleteMemo";

            axios.delete(url, {data: {id: id}}).then(res=>{
                window.location.reload();
            }).finally(fin=>{
                isProcessing.value = false;
            });
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }


        return {
            setLevel,
            isProcessing,
            callReplaceWord,
            showBack,

            select部署,

            memo_list_30300001,
            memo_list_30300002,
            memo_list_30300003,
            memo_list_30300004,
            memo_list_30300005,
            memo_list_30300006,
            memo_list_30300007,
            memo_list_30300008,
            memo_list_30300009,

            formattedText,

            changeDepartment,

            deleteMemo,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        department_list: {
            type: Array,
        },
        param_memo_list_30300001: {
            type: Array,
        },
        param_memo_list_30300002: {
            type: Array,
        },
        param_memo_list_30300003: {
            type: Array,
        },
        param_memo_list_30300004: {
            type: Array,
        },
        param_memo_list_30300005: {
            type: Array,
        },
        param_memo_list_30300006: {
            type: Array,
        },
        param_memo_list_30300007: {
            type: Array,
        },
        param_memo_list_30300008: {
            type: Array,
        },
        param_memo_list_30300009: {
            type: Array,
        },
    }

}
</script>

<style lang="scss" scoped>
@use '../../../../sass/common_colors.scss';

.header {
    background-color: common_colors.colors(resilience-light-gray);
}

.table-border-resilience-gray {
    border: 1px solid common_colors.colors(resilience-gray);
}

@media (max-width: 767px) {
    .fs-half {
        font-size: 0.75em;;
    }
}

</style>
