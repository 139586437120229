<template>
    <div class="row">
        <div class="h5 heading-orange mt-3 col-md-8">
            {{ callReplaceWord('組織') }}情報登録
        </div>
        <div class="col-md-4 mt-3">
            <button class="btn btn-bcp-restrictions py-2 me-3" @click="backMenu"><i class="bi bi-gear"></i><span class="ms-3">マイページ</span></button>
            <input type="button" class="btn btn-black px-4 py-2" value="前ページへ戻る" @click="backAdminHome">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row d-none d-md-flex mt-3">
        <!-- 表のタイトル行 -->
        <div class="row header">
            <div class="col-md-1 cell"><strong>{{ titles[0] }}</strong></div>
            <div class="col-md-5 cell"><strong>{{ titles[1] }}</strong></div>
            <div class="col-md-6 cell"><strong>{{ titles[2] }}</strong></div>
        </div>
        <!-- 表のボディー行 -->
        <div v-for="(row, rowIndex) in rows" :key="'row-' + rowIndex" class="row w-100">
            <div class="col-md-1 custom-col-width cell">{{ row.function }}</div>
            <div class="col-md-5 custom-col-width cell">{{ row.content }}</div>
            <div class="col-md-6 custom-col-width cell">
                <template v-if="row.type === 'button'">
                    <div class="d-flex flex-row">
                        <button type="button" class="btn btn-none w-50 me-5" @click="movePage(row.page)">
                            <div class="d-flex fill align-middle">
                                <i :id="row.image_id"></i>
                                <span class="ps-3 flex-fill text-start">{{ row.title }}</span>
                            </div>
                        </button>
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <span>サイト内の規定の呼称を、自己・自社の呼称に合わせて読み替えます。（例：「代表」という呼称を、「店主」「校長」「理事長」等任意の呼称に読み替え）</span>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <!-- md以下の画面サイズ -->
    <div class="d-md-none">
        <div v-for="(row, rowIndex) in rows" :key="'row-sm-' + rowIndex" class="table-bordered p-2 mb-3">
            <div class="row">
                <div class="col custom-col-width cell bg-light">{{ titles[0] }}</div>
            </div>
            <div class="row">
                <div class="col custom-col-width cell">{{ row.function }}</div>
            </div>
            <div class="row">
                <div class="col custom-col-width cell bg-light">{{ titles[1] }}</div>
            </div>
            <div class="row">
                <div class="col custom-col-width cell">{{ row.content }}</div>
            </div>
            <div class="row">
                <div class="col custom-col-width cell bg-light">{{ titles[2] }}</div>
            </div>
            <div class="row">
                <div class="col-md-7 custom-col-width cell">
                    <template v-if="row.type === 'button'">
                        <div class="d-flex flex-column">
                            <button type="button" class="btn btn-none" @click="movePage(row.page)">
                                <div class="d-flex fill align-middle">
                                    <i :id="row.image_id"></i>
                                    <span class="ps-3 flex-fill text-start">{{ row.title }}</span>
                                </div>
                            </button>
                            <VDropdown
                                theme="hint-theme"
                            >
                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                <template #popper>
                                    <div class="row">
                                        <span>{{ row.hint_text }}</span>
                                    </div>
                                </template>
                            </VDropdown>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import levelIcon from '../../common/UserLevelIconsComponent.vue';
import ReplaceWord from '../../../function/CommonReplaceWord';
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('111111');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list);
        }

        const titles = ref([
            "機能",
            "項目・設定内容",
            "設定",
        ]);

        const rows = ref([
            {
                function: callReplaceWord('組織') + "情報",
                content: "利用者の新規登録、編集、削除",
                type: "button",
                image_id: "admin_riyosya",
                title: "利用者登録",
                page: '/al/administrator/show_user_list',
                hint_text: "役員・従業員の登録、利用者IDの発行、利用者ごとの権限を登録します。",
            },
            {
                function: callReplaceWord('組織') + "情報",
                content: "拠点・支店の情報登録、追加、削除",
                type: "button",
                image_id: "admin_kyoten",
                title: "拠点・支店登録",
                page: '/al/administrator/register_branch',
                hint_text: "BCP策定の対象となる事業所を登録します。",
            },
            {
                function: callReplaceWord('組織') + "情報",
                content: "ヒト・コト・モノの依頼と要請、検索ワードの登録、追加、削除",
                type: "button",
                image_id: "admin_word",
                title: "マッチングワード登録",
                page: '/al/administrator/register_matching_word',
                hint_text: "B&B災害応援協定ビジネスマッチング用の検索キーワードを登録・変更します。",
            },
        ]);

        const movePage = (url) => {
            location.href = url;
        }

        const backMenu = () => {
            const url = '/al/myPage/home';

            location.href = url;
        }

        const backAdminHome = () => {
            const url = '/al/administrator/home';

            location.href = url;
        }

        return {
            setLevel,
            titles,
            rows,

            callReplaceWord,
            movePage,
            backMenu,
            backAdminHome,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
    .cell {
        padding: 8px;
        border: 1px solid #ddd;
        box-sizing: border-box;
    }

    .btn-none {
        color: #3f3f3f;
        background-color: snow;
        border: thin solid #3f3f3f;
        height: 45px;
    }

    .btn-none:hover {
        color: snow;
        background-color: #3f3f3f;
        border: thin solid #3f3f3f;
        height: 45px;
    }

    #admin_riyosya {
        background-image: url('../../../../svg/administrator_button/admin_riyosya.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

    #admin_kyoten {
        background-image: url('../../../../svg/administrator_button/admin_kyoten.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

    #admin_word {
        background-image: url('../../../../svg/administrator_button/admin_word.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

</style>