export const useTextConverter = () => {
    // 改行を `<br>` タグに変換する関数
    const convertNewLine = (text) => {
      return text.replace(/\n/g, '<br>');
    }
  
    return {
      convertNewLine
    };
  }
  