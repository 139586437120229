<template>
    <div class="row mb-3">
        <label for="email" class="col-md-4 col-form-label text-md-end">登録したメールアドレス</label>

        <div class="col-md-6">
            <input type="email" class="form-control" name="email" v-model="input_email" autocomplete="email" autofocus :class="{'is-invalid': email_message.length > 0}">
            <div class="invalid-feedback">{{ email_message }}</div>
        </div>
    </div>

    <div class="row mb-0">
        <div class="col-md-6 offset-md-4">
            <button type="submit" class="btn btn-resilience-red" @click="sendEmail" :disabled="isProcessing">
                パスワードリセット
            </button>
        </div>
    </div>
    <input type="hidden" name="corp_id" value="{{ $corp_id }}">
</template>

<script>
import { ref } from 'vue'
import axios from 'axios';
export default {
    setup() {
        const input_email = ref("");
        const email_message = ref("");

        const isProcessing = ref(false);

        const sendEmail = () => {
            const url = "/api/password/email";

            isProcessing.value = true;

            axios.post(url, {
                email: input_email.value,
            }).then(res => {

            }).catch(err => {
                if(err.response.status === 422){
                    if(err.response.data.errors.email){
                        email_message.value = err.response.data.errors.email[0];
                    }
                }

            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            input_email,
            email_message,
            isProcessing,
            sendEmail,
        }
    },
}
</script>