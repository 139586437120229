<template>
    <div class="row">
        <div class="col-md-8">
            <div class="h5 heading-orange mt-3" style="font-size:14pt">
                みんなの安否
            </div>
            <levelIcon
                :set_level="setLevel"
            />
        </div>
        <div class="p2 mt-3 ms-1 col-md d-flex align-items-start justify-content-end me-md-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backPrevious" :disabled="isProcessing">
        </div>
    </div>

    <div class="row mt-md-5 mt-1 pb-5">
        みんなの安否は、1カ月間表示されます。表示期間を延長したい場合は、「保存」ボタンをクリックしてください。
    </div>

    <div class="row mt-1 pb-5">
        <table class="table table-bordered table-striped fs-md-half">
            <thead>
                <tr>
                    <th>No.</th>
                    <th>配信日時</th>
                    <th>配信拠点・支店</th>
                    <th>登録名称</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(safety, index) in safety_list" :key="safety.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ safety.配信日時 }}</td>
                    <td>{{ safety.拠点名 }}</td>
                    <td>{{ safety.登録名称 }}</td>
                    <td>
                        <div class="d-flex justify-content-evenly">
                            <input type="button" class="btn btn-green fs-md-half" value="詳細" @click="showDetail(safety.id)" :disabled="isProcessing" />
                            <input type="button" 
                                class="btn fs-md-half" 
                                value="保存" 
                                @click="saveButtonClick(safety.id, safety.save_flg)" 
                                :class="saveButtonClass(safety.save_flg)" 
                                :disabled="isProcessing"
                                v-if="is('administrator')"
                            />
                            <input type="button" 
                                class="btn btn-save-not-click fs-md-half" 
                                value="保存" 
                                v-if="!is('administrator') && safety.save_flg == 1"
                                disabled
                            />

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row ms-5 flash col-5 alert" :class="flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
        {{ flashMessage }}
    </div>

</template>

<script>
import { ref } from 'vue';
import levelIcon from '../common/UserLevelIconsComponent.vue';
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('111111');

        const showDetail = (id) => {
            const url = '/safety/detail/' + id;

            location.href = url;
        }

        const backPrevious = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const saveButtonClass = (mode) => {
            return mode == 1 ? 'btn-save' : 'btn-not-save';
        }

        const isProcessing = ref(false);

        const saveButtonClick = (id, mode) => {
            const url = "/api/safety/updateSafetyConfirmSave";

            isProcessing.value = true;

            axios.post(url, {
                safety_id: id,
            }).then(res=>{
                visibleFlashMessage('正常に更新されました', 'success');
                location.reload();
            }).finally(fin=>{
                isProcessing.value = false;
            })
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }


        return {
            setLevel,
            isProcessing,

            showDetail,

            backPrevious,
            saveButtonClass,
            saveButtonClick,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,

        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        safety_list: {
            type: Array,
        },
    },
}

</script>

<style lang="scss" scoped>
    @media (max-width: 767px) {
        .fs-md-half {
            font-size: 0.5rem;
        }
    }

    .btn-save {
        border: 1px solid;
        color: white;
        border-color: #2e75b6;
        background-color: #2e75b6;
    }

    .btn-save:hover {
        border: 1px solid;
        color: #bdd7ee;
        border-color: #bdd7ee;
        background-color: white;
    }

    .btn-save-not-click {
        border: 1px solid;
        color: white;
        border-color: #2e75b6;
        background-color: #2e75b6;
        cursor: none;
    }

    .btn-save-not-click:disabled {
        opacity: 1;
    }

    .btn-not-save {
        border: 1px solid;
        color: white;
        border-color: #bdd7ee;
        background-color: #bdd7ee;
    }

    .btn-not-save:hover {
        border: 1px solid;
        color: #2e75b6;
        border-color: #2e75b6;
        background-color: white;
    }

</style>