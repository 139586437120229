<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card pb-5">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式09-2</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">• 発動フロー</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">発動フローに沿った実施項目</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">初動対応</label>
                </div>
            </div>
            <div class="card small-fs-half width-100">
                <div class="card-body">
                    <div class="row">
                        <div class="p2 col-md-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">◯ 二次災害の防止措置</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="p2 col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">事業所からの退避</label>
                        </div>
                        <div class="p2 col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">事業所に留まっていると危険な場合、お客さまや{{ callReplaceWord('従業員') }}を事業所の外の安全な場所に退避させる。</label>
                            <label class="border-top form-label">退避が必要な状況としては、津波の来襲、洪水、土砂災害、火災、有毒ガスの漏洩など。</label>
                        </div>
                        <div class="p2 py-md-1 px-1 px-md-3 col-3 col-md-2 border border-bottom-0 with-danger">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="避難計画シート" @click="showBrowse('K10')" />
                            <div class="px-1 mt-2 bg-danger text-white text-center">災害ごとに相違</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">応急手当や初期消火</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">負傷者の救出や応急手当を行う。</label>
                            <label class="border-top form-label">火災が発生した場合は初期消火を行う。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="災害対応用具チェックリスト" @click="showBrowse('K18')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">警察・消防への通報</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">事件性がある場合は110番通報する。</label>
                            <label class="border-top form-label">火災発生時や救急車出動要請の場合は119番通報する。</label>
                            <label class="border-top form-label">その他、法律や協定で決められた機関がある場合は、そこに通報する</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="主要組織の連絡先" @click="showBrowse('K11')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-end-0 bg-gray-3">
                            <label class="form-label">重要書類の保護</label>
                        </div>
                        <div class="col-6 col-md-8 border border-end-0">
                            <label class="form-label">重要書類が損傷するおそれのある場合、事業所内の安全な場所に移動するか、事業所外へ持ち出す。</label>
                            <label class="border-top form-label">重要書類が損傷した場合、予め別の場所に保管していた書類のコピーで然るべき処置を行う。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="重要書類等" @click="" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="p2 col-md-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">◯ {{ callReplaceWord('従業員') }}の参集</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">{{ callReplaceWord('経営者') }}の対応</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">{{ callReplaceWord('組織') }}外にいる場合、直ちに{{ callReplaceWord('出社') }}する。</label>
                            <label class="border-top form-label">{{ callReplaceWord('出社') }}までの間、電話等で{{ callReplaceWord('従業員') }}に指示を出す。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="従業員連絡先リスト" @click="showBrowse('C3')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-end-0 bg-gray-3">
                            <label class="form-label">{{ callReplaceWord('従業員') }}の参集</label>
                        </div>
                        <div class="col-6 col-md-8 border border-end-0">
                            <label class="form-label">就業時間外に緊急事態が発生した場合、{{ callReplaceWord('従業員') }}を招集する。</label>
                            <label class="border-top form-label">地震や風水害では{{ callReplaceWord('従業員') }}が自主的に参集する基準を事前に設けておく。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="みんなの安否" @click="showSafetyList()" :disabled="!is('administrator') && !visible_everyone_safety" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">◯ 安否・被災状況の把握</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">{{ callReplaceWord('顧客') }}、{{ callReplaceWord('従業員') }}とその家族の安否</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">来所中の{{ callReplaceWord('顧客') }}に負傷がないか確認する。</label>
                            <label class="border-top form-label">{{ callReplaceWord('従業員') }}とその家族に負傷がないか、住家の損傷がないかを確認する。</label>
                            <label class="border-top form-label">{{ callReplaceWord('従業員') }}と電話がつながらない場合、近所の{{ callReplaceWord('従業員') }}等に様子を見に行かせる。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="みんなの安否" @click="showSafetyList()" :disabled="!is('administrator') && !visible_everyone_safety" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">建屋、{{ callReplaceWord('生産') }}機械、通信機器</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">事業所内への立入りが危険でなくなってから実施。</label>
                            <label class="border-top form-label">建屋の損傷状況を調べる。</label>
                            <label class="border-top form-label">{{ callReplaceWord('生産') }}機械の損傷状況を調べる。</label>
                            <label class="border-top form-label">一般電話、携帯電話、FAX、インターネット等の通信機器が使えるかどうかを調べる。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" :value="`中核${callReplaceWord('事業')}に係るボトルネック資源｜設備など`" @click="showBrowse('K15')" />
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list mt-2" :value="`中核${callReplaceWord('事業')}に係るボトルネック資源｜その他`" @click="showBrowse('J6')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">情報システム</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">事業所内への立入りが危険でなくなってから実施。</label>
                            <label class="border-top form-label">パソコン、ソフトウェアが使えるかどうかを調べる。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" :value="`中核${callReplaceWord('事業')}に係るボトルネック資源｜コンピュータ`" @click="showSafety('R2')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">地域住民や近隣事業所</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">延焼火災や有毒ガス漏洩等、直ちに避難が必要な状況でないかどうかを調べる。</label>
                            <label class="border-top form-label">初期消火や下敷き者の救出等、地域貢献活動が必要な状況かどうかを把握する。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="主要組織の連絡先" @click="showBrowse('K11')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-end-0 bg-gray-3">
                            <label class="form-label">自然災害、交通やライフライン</label>
                        </div>
                        <div class="col-6 col-md-8 border border-end-0">
                            <label class="form-label">大雨の場合、河川増水の状況や土砂災害の兆候に注意する。</label>
                            <label class="border-top form-label">交通機関の混乱状況を調べる。</label>
                            <label class="border-top form-label">電気、ガス、上下水道の停止状況を調べる。</label>
                            <label class="border-top form-label">それらの情報源としては、ラジオ、インターネット、テレビ、電話問合せなどがある。</label>
                        </div>
                        <div class="col-3 col-md-2 border"></div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">{{ callReplaceWord('事業') }}継続のための緊急対策</label>
                </div>
            </div>
            <div class="card width-100 small-fs-half">
                <div class="card-body">
                    <div class="row">
                        <div class="p2 col-md d-flex align-items-center justify-content-start">
                            <label class="form-label">◯ {{ callReplaceWord('顧客') }}・{{ callReplaceWord('協力会社') }}への連絡</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">連絡手段の確保</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">{{ callReplaceWord('顧客') }}や{{ callReplaceWord('協力会社') }}との連絡手段を確保する。</label>
                            <label class="border-top form-label">電話、メールのほか、{{ callReplaceWord('従業員') }}による自転車往来を含めて、あらゆる手段を検討する。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="情報通信手段の情報" @click="showBrowse('J4')" />
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list mt-2" value="電話／FAX番号シート" @click="showBrowse('K13')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">{{ callReplaceWord('顧客') }}への被災状況報告</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">{{ callReplaceWord('顧客') }}に対して、事業所の被災状況、今後の{{ callReplaceWord('納入') }}等の目処、確実な連絡手段、次回の連絡時期を報告する。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="主要顧客情報" @click="showBrowse('K14')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-end-0 bg-gray-3">
                            <label class="form-label">{{ callReplaceWord('協力会社') }}の被災状況把握</label>
                        </div>
                        <div class="col-6 col-md-8 border border-end-0">
                            <label class="form-label">{{ callReplaceWord('協力会社') }}に対して、事業所の被災状況、今後の{{ callReplaceWord('納入') }}の目処、確実な連絡手段、次回の連絡時期について報告を求める。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="主要供給者・業者情報" @click="showBrowse('K16')" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md d-flex align-items-center justify-content-start">
                            <label class="form-label">◯ 中核{{ callReplaceWord('事業') }}の継続方針立案・体制確立</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">中核{{ callReplaceWord('事業') }}のダメージ判断</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">中核{{ callReplaceWord('事業') }}について、ボトルネックとなる事業資源の被災状況等から、中核{{ callReplaceWord('事業') }}が被ったダメージの大きさを把握する。</label>
                            <label class="border-top form-label">ボトルネックとなる{{ callReplaceWord('事業') }}資源としては、{{ callReplaceWord('顧客') }}や{{ callReplaceWord('協力会社') }}、{{ callReplaceWord('従業員') }}、建屋や{{ callReplaceWord('生産') }}機械、情報システム、ライフライン、交通機関などがあげられる。</label>
                        </div>
                        <div class="col-3 py-1 px-1 px-md-3 col-md-2 border border-bottom-0 with-danger">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" :value="`中核${callReplaceWord('事業')}影響度評価フォーム`" @click="showBrowse('J2')" />
                            <div class="px-1 mt-2 bg-danger text-white text-center">災害ごとに相違</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">目標復旧時間設定</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">予め検討していた「目標復旧時間の目処」を元に、現在の被災状況、今後の事態進展の予測を考慮して設定する。</label>
                            <label class="border-top form-label">{{ callReplaceWord('顧客') }}の納得が得られるか、復旧後に{{ callReplaceWord('経営') }}が成り立つか、現実的かどうかを総合的に考えて設定する。</label>
                            <label class="border-top form-label">下記の「財務の予測診断」の結果も考慮する。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0"></div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">応急・復旧対策方針の決定</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">
                                事業資源の損害が大きい場合、次のどの方針で目標復旧時間内に中核{{ callReplaceWord('事業') }}の復旧を目指すかを決定します（組み合わせもある）。<br>
                                ①現在の事業所を復旧させて操業<br>
                                ②代替場所に{{ callReplaceWord('生産') }}機械等を移動して操業<br>
                                ③他の{{ callReplaceWord('組織') }}等に{{ callReplaceWord('生産') }}を一時移管する<br>
                            </label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0"></div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">財務の予測診断</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">財務診断モデルを用いて、復旧費用、今後のキャッシュフロー、不足資金を予測する。</label>
                            <label class="border-top form-label">予測結果は融資申請の際にも役立てる。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0"></div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">実施体制の確立</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">指揮命令系統と役割分担を{{ callReplaceWord('従業員') }}に明示する。</label>
                            <label class="border-top form-label">必要なら、会社OB、協同組合、{{ callReplaceWord('取引先') }}等から要員応援を仰ぐ（あるいは応援を出す）。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0"></div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-end-0 bg-gray-3">
                            <label class="form-label">拠点場所の確保</label>
                        </div>
                        <div class="col-6 col-md-8 border border-end-0">
                            <label class="form-label">事業所が損傷した場合、{{ callReplaceWord('顧客') }}や{{ callReplaceWord('協力会社') }}と連絡が取れ、{{ callReplaceWord('従業員') }}を指揮できる拠点場所を確保する（自宅やプレハブ、自動車でも良い）。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" :value="`${callReplaceWord('事業')}継続に係る各種資源の代替の情報`" @click="showBrowse('K4f')" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">{{ callReplaceWord('事業') }}継続のための応急・復旧対策</label>
                </div>
            </div>
            <div class="card width-100 small-fs-half">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">◯　{{ callReplaceWord('顧客') }}・{{ callReplaceWord('協力会社') }}向け対策</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">取引調整（他の{{ callReplaceWord('組織') }}等への一時移管を含む）</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">{{ callReplaceWord('顧客') }}に対して今後の{{ callReplaceWord('納入') }}等の計画を説明し了解を得る。</label>
                            <label class="border-top form-label">必要に応じて、他の{{ callReplaceWord('組織') }}での一時的な代替{{ callReplaceWord('生産') }}等を調整する。</label>
                            <label class="border-top form-label">{{ callReplaceWord('協力会社') }}に対して今後の{{ callReplaceWord('納入') }}等の計画の説明を求め、必要に応じて、他の{{ callReplaceWord('組織') }}での一時的な代替生産等を調整する。</label>
                            <label class="border-top form-label">{{ callReplaceWord('顧客') }}や{{ callReplaceWord('協力会社') }}との取引ルールとして、他の{{ callReplaceWord('組織') }}での代替{{ callReplaceWord('生産') }}は一時的なものであり、復旧後は{{ callReplaceWord('発注') }}を戻すことを原則とする。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="主要顧客情報" @click="showBrowse('K14')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-end-0 bg-gray-3">
                            <label class="form-label">取引復元</label>
                        </div>
                        <div class="col-6 col-md-8 border border-end-0">
                            <label class="form-label">{{ callReplaceWord('自社') }}の{{ callReplaceWord('事業') }}資源が復旧した時点で、代替{{ callReplaceWord('生産') }}を引き上げ、{{ callReplaceWord('顧客') }}に被災前の{{ callReplaceWord('取引') }}に復元してもらう（上記の調整結果どおり）。</label>
                            <label class="border-top form-label">{{ callReplaceWord('協力会社') }}の{{ callReplaceWord('事業') }}資源が復旧した時点で、代替{{ callReplaceWord('生産') }}を引き上げ、被災前の{{ callReplaceWord('取引') }}に復元する（上記の調整結果どおり）。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="主要供給者・業者情報" @click="showBrowse('K16')" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md">
                            <label class="form-label">◯ {{ callReplaceWord('従業員') }}・{{ callReplaceWord('事業') }}資源対策</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">{{ callReplaceWord('従業員') }}との情報共有と生活支援</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">全{{ callReplaceWord('従業員') }}に対して{{ callReplaceWord('事業') }}継続方針を説明し、適宜、その進捗状況を示す。</label>
                            <label class="border-top form-label">{{ callReplaceWord('従業員') }}の食事や日用品等を確保する。</label>
                            <label class="border-top form-label">{{ callReplaceWord('従業員') }}の本人や家族が死傷した場合、できる限りの配慮を行う。</label>
                            <label class="border-top form-label">住家が被災した{{ callReplaceWord('従業員') }}に対して、可能であれば仮住居を提供する。</label>
                        </div>
                        <div class="col-3 py-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="災害対応用具チェックリスト" @click="showBrowse('K18')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">建屋の修理・一時移転</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">建屋が損傷した場合、その修理を建設会社等に要請する（目標復旧時間に間に合うスケジュールで）。</label>
                            <label class="border-top form-label">建屋の早期復旧が困難な場合は、他の場所に移転する。</label>
                        </div>
                        <div class="col-3 py-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" :value="`${callReplaceWord('事業')}継続に係る各種資源の代替の情報`" @click="showBrowse('K4f')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">{{ callReplaceWord('生産') }}機械の修理・調達</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">{{ callReplaceWord('生産') }}機械の修理・調達を専門メーカー等に要請する。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" :value="`中核${callReplaceWord('事業')}に係るボトルネック資源｜設備など`" @click="showBrowse('K15')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">情報システムの回復</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">パソコン等ハードウェアの修理・調達を専門メーカー等に要請する。</label>
                            <label class="border-top form-label">ソフトウェアの破損は、予めバックアップしていたデータを用いて回復させる。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" :value="`中核${callReplaceWord('事業')}に係るボトルネック資源｜コンピュータ`" @click="showSafety('R2')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-end-0 bg-gray-3">
                            <label class="form-label">供給品の調達</label>
                        </div>
                        <div class="col-6 col-md-8 border border-end-0">
                            <label class="form-label">通常のルートからの調達が困難な場合、予め定めた代替ルート（業者や搬送方法）により調達する。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" :value="`中核${callReplaceWord('事業')}に必要な供給品目情報`" @click="showBrowse('K17')" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md">
                            <label class="form-label">◯ 財務対策</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">運転資金の確保</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">緊急時発生後1ヶ月間、当面必要な運転資金を確保する。</label>
                            <label class="border-top form-label">銀行預金（積立金）を引き出す。</label>
                            <label class="border-top form-label">必要に応じて、地方自治体等の制度による緊急貸付を受ける。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0"></div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">決済不渡り対策</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">発行済みの手形が不渡りにならないよう、取引銀行等と調整する。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0"></div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">仕入支払い・給与支払い</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">できる限り、{{ callReplaceWord('協力会社') }}や{{ callReplaceWord('納入') }}業者等に対して過日分の支払いを行う。</label>
                            <label class="border-top form-label">できる限り、{{ callReplaceWord('従業員') }}に対して給料を支払う。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0"></div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-end-0 bg-gray-3">
                            <label class="form-label">復旧資金の確保</label>
                        </div>
                        <div class="col-6 col-md-8 border border-end-0">
                            <label class="form-label">財務診断結果から、建物や生産機械の修理費用等、復旧に必要な費用を見積もる。</label>
                            <label class="border-top form-label">損害保険や共済の支払いを受ける。</label>
                            <label class="border-top form-label">証券等の資産を売却する。</label>
                            <label class="border-top form-label">必要に応じて、政府系金融機関等から災害復旧貸付を受ける。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border"></div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md">
                            <label class="form-label">◯ 地域貢献活動</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">被災者の救出・応急救護・初期消火</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">{{ callReplaceWord('組織') }}の近所で被災者や火災が発生した場合に協力する。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-bottom-0">
                            <input type="button" class="btn btn-sm btn-resilience-green btn-in-list" value="地域貢献活動" @click="showBrowse('J7')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-bottom-0 border-end-0 bg-gray-3">
                            <label class="form-label">商品等の提供</label>
                        </div>
                        <div class="col-6 col-md-8 border border-bottom-0 border-end-0">
                            <label class="form-label">食料品や日用品の小売業の場合、在庫商品を避難所に無償提供する案もある。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-top-0 border-bottom-0"></div>
                    </div>
                    <div class="row">
                        <div class="col-3 col-md-2 border border-end-0 bg-gray-3">
                            <label class="form-label">ボランティア活動</label>
                        </div>
                        <div class="col-6 col-md-8 border border-end-0">
                            <label class="form-label">損傷した住家の後片付け、救援物資の仕分け等のボランティア活動がある。</label>
                            <label class="border-top form-label">{{ callReplaceWord('従業員') }}の自主的なボランティア参加を支援する（ボランティア保険の負担等を含め）。</label>
                            <label class="border-top form-label">必要に応じて、{{ callReplaceWord('従業員') }}に業務としてボランティア活動に参加させることも検討する。</label>
                        </div>
                        <div class="col-3 py-md-1 px-1 px-md-3 col-md-2 border border-top-0"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);
        const visible_everyone_safety = ref(true);

        onMounted(() => {
            visible_everyone_safety.value = true;
            if(props.corp_settings.length > 0){
                const filtered = props.corp_settings.filter(item=>item.setting_kbn === 9200);
                if(filtered.length > 0){
                    if(filtered[0].setting_val == 92000002){
                        visible_everyone_safety.value = false;
                    }
                }
            }
        });

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/K8';
            location.href = url;
        }

        const showNext = () => {
            const url = '/browse/K10';
            location.href = url;
        }

        const showBrowse = (path) => {
            const url = `/browse/${path}`;
            location.href = url;
        }

        const showSafety = (path) => {
            const url = `/safety/${path}`;
            location.href = url;
        }

        const showSafetyList = () => {
            const url = "/safety/list";
            location.href = url;
        }

        return {
            setLevel,
            isMargin1,
            visible_everyone_safety,
            callReplaceWord,
            showBack,
            showNext,
            showBrowse,
            showSafety,
            showSafetyList,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        corp_settings: {
            type: Array,
        },

    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.with-danger {
    display: flex;
    flex-direction: column;
}

.btn-sm.btn-resilience-green {
    width: 100%;
    white-space: normal;
}

.row > .col-md-8 {
    padding: 0;
    display: flex;
    flex-direction: column;
}

.row > .col-md-8 > .form-label {
    display: block;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    flex: 1 1 auto;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

.border-top {
    border-color: common_colors.colors(resilience-gray) !important;
}

.width-100 {
    width: 100%;
}

@media (max-width: 576px) {
    .small-fs-half {
        font-size: 0.5em;
    }

    .btn-in-list {
        font-size: 0.5rem !important;
    }
}

@media (max-width: 767px) {
}

@media print {
    .btn-resilience-green {
        border: none;
    }

    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }

    .border-top {
        border-color: black !important;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-8 {
        width: 66.66666667%;
    }
}
</style>
