<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            4. 財務診断と事前対策計画
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="back">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row mt-5">
        <div class="col-md-4">
            <label>〇　財務診断モデルを下記よりダウンロードしてください。</label>
        </div>
    </div>
    <div class="row mt-3">
        <div class="d-flex justify-content-between col-md-6">
            <input type="button" class="btn btn-download-excel p-3" value="[製造業] 財務診断モデル" @click="downloadTemplate(1)" :disabled="isProcessing">
            <input type="button" class="btn btn-download-excel p-3" value="[建設業] 財務診断モデル" @click="downloadTemplate(3)" :disabled="isProcessing">
            <input type="button" class="btn btn-download-excel p-3" value="[小売業] 財務診断モデル" @click="downloadTemplate(2)" :disabled="isProcessing">
        </div>
        <span class="ms-2">出典：中小企業BCP策定運用指針（経済産業省中小企業庁）財務診断モデル</span>
    </div>

    <div class="row mt-3">
        <span class="ms-2">※ダウンロードしたExcelファイルを利用し、財務状況（復旧費用総額、キャッシュフローなど）を予測します。</span>
        <span class="ms-4">ファイルの「使い方」シートをご確認のうえ、作成してください。作成後、「BCP用印刷シート」及び「追記シート」をPDFで保存し、アップロードしてください。</span>
    </div>

    <div class="row mt-5">
        <div class="col-md-6" :disabled="isProcessing">
            <input type="file" style="display:none" @change="dropFile"/>
            <div class="px-5 py-3 btn-upload-blue text-center" 
                    @dragleave="dragLeave"
                    @dragover.prevent="dragEnter"
                    @drop.prevent="dropFile"
                    :class="{enter: isEnter}"
                >
                <label class="h2">
                    作成した財務診断シートを<br>アップロードする
                </label>
                <div class="h5 mt-2">
                    財務診断シートPDFデータをTOTONO-Lクラウドに保管する
                </div>
                <label class="col-form-label btn btn-bnb-blue" for="submit_pdf">
                    ファイルを選択
                    <input id="submit_pdf" type="file" class="no-display" accept=".pdf" @change="dropFile" />
                </label>
            </div>
        </div>
    </div>
    <div class="row mt-5 ms-3 ">
        <div class="ms-4 p2 d-flex align-items-center justify-content-start">
            <label for="" class="form-label col-md-6 font-color-danger">×&emsp;著作権侵害など違法なファイルはアップロードしないでください</label>
        </div>
        <div class="ms-4 p2 d-flex align-items-center justify-content-start">
            <label for="" class="form-label col-md font-color-danger">×&emsp;フォルダのアップロードはできません。</label>
        </div>
    </div>

    <div class="row mt-5 mx-1">
        <span class="col-md-10">〇　保管されている財務診断シート一覧</span>
        <div class="col-md-2">
            <VDropdown
                theme="browsing_restrictions"
            >
                <input type="button" class="ms-5 btn btn-bcp-restrictions btn-sm" value="閲覧制限｜設定" />
                <template #popper>
                    <div class="d-flex justify-content-start">
                        <label>閲覧制限</label>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="isViewLimit">
                            <label class="form-check-label" for="checkbox_1">
                                あり
                            </label>
                        </div>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>
    <div class="row mt-3 mx-3">
        <div class="col-md">
            <table class="table table-responsive">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>ファイル名</th>
                        <th>作成日時</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(file, index) in analysis_file_array" :key="file.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ file.name }}</td>
                        <td>{{ this.$root.dayjs(file.register_date).format('YYYY-MM-DD HH:mm:ss') }}</td>
                        <td>
                            <div class="d-flex justify-content-evenly">
                                <input type="btn" class="btn btn-success" value="ダウンロード" @click="downloadAnalysisFile(file.id, file.name)" :disabled="isProcessing">
                                <input type="btn" class="btn btn-danger" value="削除" @click="deleteAnalysisFile(file.id)" :disabled="isProcessing">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mt-3 mx-3">
        <div>
            <input type="button" class="btn btn-bcp-blue" value="目次構成 へ戻る" @click="showContents" :disabled="isProcessing">
        </div>
    </div>

    <!-- <div class="row mt-3 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">評価を行う際の金額の単位を以下の2種類から選んでください。</label>
        </div>
    </div>

    <div class="row ms-3 mt-3 d-flex flex-row">
        <div class="col-md-10">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked>
                <label class="form-check-label" for="inlineRadio1">百万円</label>
            </div>
            <div class="ms-5 form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                <label class="form-check-label" for="inlineRadio2">千円</label>
            </div>
        </div>
    </div>

    <div class="row ms-4 mt-5 d-flex flex-row">
        <div class="col-md">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="col-md-6 text-start btn btn btn-manufacture" value="［製造業］損益計算書・製造原価報告書へ" @click="showPLManufacture">
            </div>
        </div>
    </div>

    <div class="row ms-4 mt-5 d-flex flex-row">
        <div class="col-md">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="col-md-6 text-start btn btn btn-construction" value="［建設業］損益計算書・完成工事原価計算書 へ" @click="showPLConstruction">
            </div>
        </div>
    </div>

    <div class="row ms-4 mt-5 d-flex flex-row">
        <div class="col-md">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="col-md-6 text-start btn btn btn-retail" value="［小売業］損益計算書へ" @click="showPLRetail">
            </div>
        </div>
    </div> -->

</template>

<script>
import { onMounted, ref } from 'vue';
import levelIcon from '../../common/UserLevelIconsComponent.vue';
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            if(props.analysis_file_list.length > 0){
                analysis_file_array.value = [...props.analysis_file_list];
            }
            if(props.bcp_middle_financial_limit_view){
                isViewLimit.value = props.bcp_middle_financial_limit_view;
            }
        })

        const setLevel = ref('001000');

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const isProcessing = ref(false);

        const isEnter = ref(false);

        const dragEnter = () => {
            isEnter.value = true;
        }

        const dragLeave = () => {
            isEnter.value = false;
        }

        const files = ref([]);
        const fd_2 = ref(new FormData());

        const dropFile = (e, key = '', image = {}) => {
            isEnter.value = false;

            const fileList = e.target.files ? e.target.files : e.dataTransfer.files;

            if(fileList.length === 0){
                return false;
            }

            let files = [];

            for(let i = 0; i < fileList.length; i++){
                files.push(fileList[i]);
            }

            // 今回は1ファイルのみ送ることにする。
            let file = files.length > 0 ? files[0] : [];
            if(file.type !== 'application/pdf'){
                return false;
            }
            if(file.size > 10000000){
                return false;
            }
            // fd_2.value = new FormData(); //★②
            fd_2.value.append('file', file);
            fd_2.value.append('bcp_id', props.bcp_basic_frame_info.id);
            fd_2.value.append('version', props.bcp_basic_frame_info.version);
            fd_2.value.append('financial_kbn', select業種.value);

            const url = '/api/bcp/postFinancialPDF';

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            isProcessing.value = true;
            axios.post(url, fd_2.value, config).then(res => {
                analysis_file_array.value = [...res.data];
            }).finally(fin => {
                e.target.value = null;
                isProcessing.value = false;
            });
        }

        const analysis_file_array = ref([]);

        const downloadAnalysisFile = (id, fileName) => {
            const url = '/api/bcp/downloadFinancialPDF';

            isProcessing.value = true;
            axios.post(url, {
                'id': id
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = fileName;
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);

            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const deleteAnalysisFile = (id) => {
            if(!confirm(analysis_file_array[id].name + "を削除します。よろしいですか？")){
                return false;
            }
            
            const url = '/api/bcp/deleteFinancialPDF';

            isProcessing.value = true;
            axios.delete(url, {data: {'id': id}})
            .then(res => {
                analysis_file_array.value = [...res.data];
            }).finally(fin => {
                isProcessing.value = false;
            });

        }

        const showPLManufacture = async() => {
            const result = await registerIsViewLimit();

            if (result == '200') {
                const url = '/bcp/bcp_financial/製1';

                location.href = url;
            }
        }

        const showPLConstruction = async() => {
            const result = await registerIsViewLimit();

            if (result == '200') {
                const url = '/bcp/bcp_financial/建1';

                location.href = url;
            }
        }

        const showPLRetail = async() => {
            const result = await registerIsViewLimit();

            if (result == '200') {
                const url = '/bcp/bcp_financial/小1';

                location.href = url;
            }
        }

        const back = async () => {
            const result = await registerIsViewLimit();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const registerIsViewLimit = async () => {
            isProcessing.value = true;
            const postUrl = '/api/bcp/registerMiddleFinancialIsViewLimit';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'middle_financial_view_limit': isViewLimit.value,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const select業種 = ref(30400001);

        const downloadTemplate = (kbn) => {
            const url = '/api/downloadOldExcel?kbn=' + kbn;

            isProcessing.value = true;

            let file_name = '';

            switch(kbn){
                case 1:
                    file_name = 'bcpfnc_b_manu.xls';
                    break;
                case 2:
                    file_name = 'bcpfnc_b_retail.xls';
                    break;
                case 3:
                    file_name = 'bcpfnc_b_const.xls';
                    break;
                default:
                    return false;
                    break;
            }

            axios.post(url, {
                'kbn': kbn,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = file_name;
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const showContents = async() => {
            const result = await registerIsViewLimit();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const isViewLimit = ref(false);

        return {
            setLevel,

            isProcessing,
            isEnter,
            dragEnter,
            dragLeave,
            files,
            fd_2,
            dropFile,

            analysis_file_array,
            downloadAnalysisFile,
            deleteAnalysisFile,

            showPLManufacture,
            showPLConstruction,
            showPLRetail,
            back,

            select業種,

            downloadTemplate,

            showContents,

            isViewLimit,
            registerIsViewLimit,
        }
    },
    props:{
        financial_kbn: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        analysis_file_list: {
            type: Array,
        },
        bcp_middle_financial_limit_view: {
            type: Boolean,
        },
    }

}
</script>

<style lang="scss" scoped>
.btn-download-excel {
    border: 1px solid #d0cece;
    color: #404040;
    background-color: #d0cece;
    font-size: 14px;
}

.btn-download-excel:hover {
    border: 1px solid #d0cece;
    color: #d0cece;
    background-color: #404040;
    font-size: 14px;
}

.no-display {
    display: none;
}

.btn-upload-blue {
    border: 1px solid;
    color: white;
    border-color: #1f4e78;
    background-color: #1f4e78;
}

.enter {
    border: 1px solid;
    color: #1f4e78;
    border-color: #1f4e78;
    background-color: white;
}

</style>