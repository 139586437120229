<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            BCP更新記録の閲覧
        </div>
    </div>
    <levelIcon
        :set_level="setLevel"
    />
    <div class="table-responsive-md d-flex align-items-top justify-content-start">
        <table class="ms-3 mt-3 table table-bordered border-black">
            <thead>
                <tr>
                    <th>BCPを見直す基準（入門コース策定／自動表示）</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in standardList" :key="item.seq">
                    <td>
                        <div class="objective-content">{{ item.title }}<br><div class="second-line">{{ item.body }}</div></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="objective-content" v-html="formattedText(textOther)"></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="table-responsive-md d-flex align-items-top justify-content-start">
        <table class="ms-3 mt-3 table-bordered border-black">
            <thead>
                <tr>
                    <th><div class="mx-2 py-2">BCPの更新時期</div></th>
                    <th><div class="mx-2 py-2">更新回数（年）</div></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div class=" mx-2 d-flex align-items-top justify-content-between py-2">
                            <div>毎年</div>
                            <div>{{ valueForms.K00010.value }}</div>
                            <div>月</div>
                        </div>
                    </td>
                    <td>
                        <div class=" mx-2 d-flex align-items-top justify-content-around py-2">
                            <div>{{ valueForms.K00011.value }}</div>
                            <div>回</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="table-responsive-md d-flex align-items-top justify-content-start">
        <table class="ms-3 mt-5 table table-bordered border-black">
            <thead>
                <tr>
                    <th colspan="1" scope="col"><div class="">BCPの更新日</div></th>
                    <th colspan="2" scope="col"><div class="">更新内容</div></th>
                </tr>
            </thead>
            <tbody v-for="(version, versionIndex) in versionList" :key="versionIndex">
                <template v-for="(val, index) in versionList[versionIndex]" :key="index">
                    <tr>
                        <td v-if="index === 0" :rowspan="version.length">
                            <div class="d-flex align-items-top">
                                <div>{{ val.作成日 }}</div>
                            </div>
                        </td>
                        <td class="col-md-1">
                            <div class="d-flex align-items-top justify-content-center">
                                <div> {{ versionIndex == 1 ? "作成" : updateType.find((type) => type.id === val.update_type)?.name }} </div>
                            </div>
                        </td>
                        <td class="col-md-10">
                            <div class="d-flex align-items-top">
                                <div>{{ val.update_details }}</div>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>

    <div class="mt-5 ms-3">
        <input type="button" class="btn btn-new-window-button btn-sm" value="診断改善HOMEへ戻る" @click="showHome" />
    </div>
    <div class="mt-3 ms-3 pb-5">
        ※ 入門コースの書き換えは行われませんのでご注意ください。
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import { useTextConverter } from '../../function/CommonConvertNewLine';
const { convertNewLine } = useTextConverter();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_introduction_texts_info.length > 0) {
                props.bcp_introduction_texts_info.forEach(data => {
                    if(data.column_name === "N00061" || data.column_name === "N00062" || data.column_name === "N00063"){
                        if(textOther.value.length === 0){
                            textOther.value = data.text_value;
                        }else{
                            textOther.value = textOther.value + "\r\n" + data.text_value;
                        }
                    }
                });
                // Object.keys(textForms).forEach(columnName => {
                //     textForms[columnName].value = props.bcp_introduction_texts_info.find(
                //         (fetchData) => fetchData.column_name === columnName
                //     )?.text_value;
                // });
            }

            if (props.bcp_values_info.length > 0) {
                Object.keys(valueForms).forEach(columnName => {
                    valueForms[columnName].value = props.bcp_values_info.find(
                        (fetchData) => fetchData.column_name === columnName
                    )?.value;
                });
            }

            if (props.bcp_introduction_values_info.length > 0){
                const wkArray = props.bcp_introduction_values_info.filter(item => item.column_name === 'N10061').map(item => item.seq);
                if(wkArray.length > 0){
                    console.log(wkArray.length);
                    wkArray.forEach(val=>{
                        let wkObj;
                        wkObj = textForms.filter(item=>item.seq === val);

                        if(wkObj.length > 0){
                            standardList.value.push({
                                columnName: wkObj[0].columnName,
                                title: wkObj[0].title,
                                body: wkObj[0].body,
                                seq: wkObj[0].seq,
                            });
                        }
                    });
                }
            }

            if (props.bcp_update_records.length > 0) {
                // 更新日ごとにグルーピングする
                const groupByUpdate = function(xs, key) {
                    return xs.reduce(function(rv, x) {
                        (rv[x[key]] = rv[x[key]] || []).push(x);
                        return rv;
                    }, {});
                };
                versionList.value = groupByUpdate(props.bcp_update_records, 'version');
            }
        });

        const setLevel = ref('111100');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const updateType = [
            {
                id:"1",
                name: "追加"
            },
            {
                id:"2",
                name: "削除"
            },
            {
                id:"3",
                name: "修正"
            },
        ];

        const valueForms = reactive({
            K00010: {
                value: '',
            },
            K00011: {
                value: '',
            },
        });

        const versionList = ref([]);

        const showHome = () => {
            const url = '/pdca/home';
            location.href = url;
        }

        const textForms = reactive([
            {
                columnName: 'N10061',
                title: "定期的な見直し",
                body: "毎年1回以上、事前対策の進捗状況や問題点をチェックし、必要に応じてBCPを見直す。",
                seq: 1,
            },
            {
                columnName: 'N10061',
                title: callReplaceWord("組織") + "の構造変更",
                body: "合併、買収、新規事業の開始、人事異動、組織改編など、内部環境に大きな変化があった場合にBCPを見直す。",
                seq: 2,
            },
            {
                columnName: 'N10061',
                title: "業務プロセスの変更",
                body: "業務プロセスの大幅な変更など、内部環境に大きな変化があった場合にBCPを見直す。",
                seq: 3,
            },
            {
                columnName: 'N10061',
                title: "サプライチェーンや主要パートナーの変更",
                body: "主要なサプライヤー、サービスプロバイダー、パートナー企業の変更や破綻、またはサプライチェーンの大幅な再編成があった場合にBCPを見直す。",
                seq: 4,
            },
            {
                columnName: 'N10061',
                title: "設備や技術の導入・更新",
                body: "新しいシステム、ソフトウェア、ハードウェア、設備の導入や既存技術の更新が行われた場合にBCPを見直す。",
                seq: 5,
            },
            {
                columnName: 'N10061',
                title: "法規制や業界標準の変更",
                body: "法律や業界の規制、標準、ガイドラインが変更された場合にBCPを見直す。",
                seq: 6,
            },
            {
                columnName: 'N10061',
                title: "組織の財務状況の変化",
                body: "重大な財務状況の変化（経済状況の悪化、予算の大幅な削減、資金繰りの変化など）があった場合にBCPを見直す。",
                seq: 7,
            },
            {
                columnName: 'N10061',
                title: "新たなリスクの出現やリスク評価の変化",
                body: "新しい脅威やリスク（自然災害の頻度増加、サイバー攻撃の増加、パンデミックなど）が明らかになった場合、または既存のリスク評価が変化した場合にBCPを見直す。",
                seq: 8,
            },
            {
                columnName: 'N10061',
                title: "BCP訓練で問題点が発見された場合や実際の緊急対応後のフィードバック",
                body: "BCPの訓練や演習を実施した結果、問題や改善点が見つかった場合。または実際の緊急対応後に得られた教訓や問題点を反映するために見直す。",
                seq: 9,
            },
        ]);

        const standardList = ref([]);

        const textOther = ref("");

        const formattedText = (text) => {
            return convertNewLine(text);
        }

        return {
            setLevel,
            callReplaceWord,
            textForms,
            valueForms,
            versionList,
            updateType,
            showHome,
            standardList,
            textOther,
            formattedText,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_update_records: {
            type: Array,
        },
        bcp_introduction_texts_info: {
            type: Array,
        },
        bcp_values_info: {
            type: Array,
        },
        bcp_introduction_values_info: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
.no-display {
    display: none;
}

.border-black {
    border-color: #808080;
}

table thead tr th {
    background-color: #d9d9d9;
}

table tbody tr td {
    background-color: #f2f2f2;
}

.objective-content {
    min-height: 3.5em;
}
</style>
