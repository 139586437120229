<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-11">
                <div class="heading mt-3">会員情報一覧</div>
            </div>
            <div class="col-md-1">
                <input type="button" class="btn btn-black" value="戻る" @click="back" :disabled="isProcessing">
            </div>
        </div>
        <div class="row mt-5">
            <table class="table table-bordered table-striped table-primary">
                <thead>
                    <tr>
                        <th>企業ID</th>
                        <th>会員種別</th>
                        <th>法人番号</th>
                        <th>組織名</th>
                        <th>支払方法</th>
                        <th>利用開始日</th>
                        <th colspan="2">有効期限日</th>
                        
                        <th>会員更新種別</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in corp_list" :key="item.id">
                        <td class="text-nowrap">{{ item.企業ID }}</td>
                        <td class="text-nowrap">{{ item.会員種別名 }}</td>
                        <td class="text-nowrap">{{ item.法人番号 === 0 ? '' : item.法人番号 }}</td>
                        <td>{{ item.組織名 }}</td>
                        <td class="text-nowrap">{{ item.請求方法名 }}</td>
                        <td class="text-nowrap">{{ this.$root.dayjs(item.利用開始日).format("YYYY/MM/DD") }}</td>
                        <td><input type="date" class="form-control form-control-sm" v-model="item.有効期限日" :disabled="isProcessing" /></td>
                        <td><button class="btn btn-sm btn-resilience-red" @click="updateExpiredDay(index)" :disabled="isProcessing"><div class="text-nowrap">更新</div></button></td>
                        <td class="text-nowrap">{{ item.更新種別名 }}</td>
                    </tr>
                </tbody>

            </table>
        </div>
        <div class="row ms-3 mt-5">
            <div class="col">
                <ul class="pagination">
                    <li :class="[{disabled: current_page <= 1}, 'page-item']"><a href="#" class="page-link mx-3" @click="change(1)" aria-label="Top" v-scroll-to="'#top'">&laquo;</a></li>
                    <li :class="[{disabled: current_page <= 1}, 'page-item']"><a href="#" class="page-link mx-3" @click="change(current_page - 1)" aria-label="Previous" v-scroll-to="'#top'">&lt;</a></li>
                    <li v-for="page in pages" :key="page" :class="[{active: page === current_page}, 'page-item', 'mx-3']">
                        <a href="#" class="page-link" @click="change(page)" v-scroll-to="'#top'">{{page}}</a>
                    </li>
                    <li :class="[{disabled: current_page >= last_page}, 'page-item']"><a href="#" class="page-link mx-3" @click="change(current_page + 1)" aria-label="Next" v-scroll-to="'#top'">&gt;</a></li>
                    <li :class="[{disabled: current_page >= last_page}, 'page-item']"><a href="#" class="page-link mx-3" @click="change(last_page)" aria-label="Last" v-scroll-to="'#top'">&raquo;</a></li>
                </ul>
            </div>
        </div>
        <div class="row ms-5 flash col-5 alert" :class="flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage" role="alert">
            {{ flashMessage }}
        </div>
    </div>
</template>

<script>
import { computed, inject, onMounted, reactive, ref } from 'vue'
import axios from 'axios';

export default{
    setup() {
        const dayjs = inject('dayjs');

        onMounted(() => {
            getCorpInfo();
        });

        const back = () => {
            const url = '/home';

            location.href = url;
        }

        const isProcessing = ref(false);

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const corp_list = ref([]);
        const current_page = ref(1);
        const last_page = ref(1);
        const total = ref(0);
        const from = ref(0);
        const to = ref(0);

        const pages = computed(() => {
            let start = Math.max(current_page.value - 2, 1);
            let end = Math.min(start + 5, last_page.value + 1);
            start = Math.max(end - 5, 1);
    
            // Array.from を使って範囲を生成する方法
            return Array.from({ length: end - start }, (_, index) => start + index);
        })

        const getCorpInfo = async(page) => {
            isProcessing.value = true;

            const url = "/api/s-admin/getCorpInfo";

            await axios.post(url, {
                page: page,
            }).then(res=>{
                corp_list.value = res.data.data;
                current_page.value = res.data.current_page;
                last_page.value = res.data.last_page;
                total.value = res.data.total;
                from.value = res.data.from;
                to.value = res.data.to;
            }).catch(err => {

            }).finally(fin => {
                isProcessing.value = false;
            })
        }

        const change = (page) => {
            getCorpInfo(page);
        }

        const updateExpiredDay = async(index) => {
            const item = corp_list.value[index];

            if(!confirm(item.企業ID + ":" + item.組織名 + "の有効期限日を" + dayjs(item.有効期限日).format("YYYY/MM/DD") + "に更新します。")){
                return;
            }

            const url = "/api/s-admin/updateExpiredDay";

            isProcessing.value = true;

            await axios.post(url, {
                id: item.id,
                expired_day: dayjs(item.有効期限日).format("YYYY-MM-DD"),
            }).then(res=>{
                visibleFlashMessage('正常に更新されました', 'success');
            }).catch(err=>{
                visibleFlashMessage('更新に失敗しました', 'error');
            }).finally(fin=>{
                isProcessing.value = false;
            })
        }

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }


        return {
            back,

            isProcessing,
            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            corp_list,
            current_page,
            last_page,
            total,
            from,
            to,
            pages,

            getCorpInfo,
            change,
            updateExpiredDay,
            visibleFlashMessage,
        }
    },
}
</script>
