<template>
    <div id="industrial-modal" v-show="modalIndustrialVisible" @click.self="closeModal">
        <div id="modal-content">
            <div class="row">
                <label class="col-form-label">新たな日本標準産業分類を選択してください。</label>
            </div>
            <div class="row mt-3">
                <label class="col-md-4 col-form-label">大分類（A～T）</label>
                <div class="col-md-8">
                    <select name="業種_大分類" class="form-select" v-model="業種_大分類" required @change="changeLargeClass" :class="{'is-invalid': 業種_error}">
                        <option type="text" v-for="large in large_class_list" :key="large.id" :value="large.大分類">{{ large.大分類 + ':' + large.項目名 }}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <label class="col-md-4 col-form-label">中分類（2桁）</label>
                <div class="col-md-8">
                    <select name="業種_中分類" class="form-select" v-model="業種_中分類" required @change="changeMiddleClass" :class="{'is-invalid': 業種_error}">
                        <option type="text" v-for="middle in middle_class_list" :key="middle.id" :value="middle.中分類">{{ middle.中分類 + ':' + middle.項目名 }}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <label class="col-md-4 col-form-label">小分類（3桁）</label>
                <div class="col-md-8">
                    <select name="業種_中分類" class="form-select" v-model="業種_小分類" required @change="changeMinorClass" :class="{'is-invalid': 業種_error}">
                        <option type="text" v-for="minor in minor_class_list" :key="minor.id" :value="minor.小分類">{{ minor.小分類 + ':' + minor.項目名 }}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <label class="col-md-4 col-form-label">細分類（4桁）</label>
                <div class="col-md-8">
                    <select name="業種_中分類" class="form-select" v-model="業種_細分類" required @change="changeSubClass" :class="{'is-invalid': 業種_error}">
                        <option type="text" v-for="sub in sub_class_list" :key="sub.id" :value="sub.細分類">{{ sub.細分類 + ':' + sub.項目名 }}</option>
                    </select>
                    <div class="invalid-feedback">{{ 業種_message }}</div>
                </div>
            </div>
            <div class="d-flex justify-content-between mt-3">
                <input type="button" class="btn btn-red" value="選択" :disabled="業種_細分類.length === 0" @click="select細分類" />
                <input type="button" class="btn btn btn-gray" value="閉じる" @click="closeModal" />
            </div>
        </div>
    </div>

    <div id="change-payment-modal" v-show="modalChangePaymentVisible" @click.self="closeChangePaymentModal">
        <div id="modal-content">
            <div class="row">
                <div class="d-flex flex-column">
                    <input type="button" class="btn btn-black" value="処理中（未払い）の年会費を口座振替からクレジット決済に切替" @click="showSwitchPayMethodBeforeDeposit" />
                    <input type="button" class="btn btn-black mt-3" value="支払方法をクレジット決済に切替" @click="showRegisterCredit"/>
                </div>
            </div>
            <div class="mt-5">
                <input type="button" class="btn btn btn-gray" value="閉じる" @click="closeChangePaymentModal" />
            </div>

        </div>
    </div>

    <div class="h5 heading-orange mt-3">
      会員情報の管理
    </div>
    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-5 mx-5 mt-5">
        <div class="p2 col-md-4 border border d-flex align-items-center border-bottom-0 py-2 bg-white">
            企業ID（会員番号）
        </div>
        <div class="p2 col-md-6 border border d-flex align-items-center border-bottom-0 py-2 bg-white">
            {{ corp_info.企業ID }}
        </div>
        <div class="p2 col-md-2">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border border d-flex align-items-center border-bottom-0 py-2 bg-white">
            事業形態
        </div>
        <div class="p2 col-md-6 border border d-flex align-items-center border-bottom-0 py-2 bg-white">
            <select class="form-select" v-model="select事業形態" :disabled="edit事業形態">
                <option v-for="business_form in business_form_list" :key="business_form.code" :value="business_form.code">{{ business_form.name }}</option>
            </select>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('事業形態')" :disabled="isProcessing" />
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border border d-flex align-items-center border-bottom-0 py-2 bg-white">
            法人番号
        </div>
        <div class="p2 col-md-6 border border d-flex align-items-center border-bottom-0 py-2 bg-white">
            <input type="number" class="form-control" v-model="input法人番号" :disabled="edit法人番号" :class="{'is-invalid': 法人番号_error}" />
            <div class="invalid-feedback">{{ 法人番号_message }}</div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center justify-content-between">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('法人番号')" :disabled="isProcessing" />
            <input type="button" class="btn btn-black btn-sm" value="法人情報取得" @click="getCorporationInfo" :disabled="String(input法人番号).length !== 13 || isProcessing">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border border d-flex align-items-center border-bottom-0 py-2 bg-white">
            組織・企業の名称
        </div>
        <div class="p2 col-md-6 border border d-flex align-items-center border-bottom-0 py-2 bg-white">
            <input type="text" class="form-control" v-model="input組織名" :disabled="edit組織名" maxlength="100" :class="{'is-invalid': 組織名_error}" />
            <div class="invalid-feedback">{{ 組織名_message }}</div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('組織名')" :disabled="isProcessing" />
            <!-- <input type="button" class="btn btn-black btn-sm" value="法人情報取得"> -->
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border border d-flex align-items-center border-bottom-0 border-top-0 py-2 bg-white">
            フリガナ
        </div>
        <div class="p2 col-md-6 border border d-flex align-items-center border-bottom-0 py-2 bg-white">
            <input type="text" class="form-control" v-model="input組織名_フリガナ" :disabled="edit組織名_フリガナ" maxlength="255" :class="{'is-invalid': 組織名_フリガナ_message.length > 0}" />
            <div class="invalid-feedback">{{ 組織名_フリガナ_message }}</div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('組織名_フリガナ')" :disabled="isProcessing" />
            <!-- <input type="button" class="btn btn-black btn-sm" value="法人情報取得"> -->
        </div>
    </div>

    <div class="row mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            住所
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            {{ label郵便番号 }}
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
        </div>
    </div>

    <div class="row mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center border-top-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            {{ label住所 }}
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            代表電話番号
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            <input type="text" class="form-control" v-model="input電話番号" :disabled="edit電話番号" maxlength="30" :class="{'is-invalid': 電話番号_error}" />
            <div class="invalid-feedback">{{ 電話番号_message }}</div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('電話番号')" :disabled="isProcessing" />
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            ホームページ
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            <input type="text" class="form-control" v-model="inputURL" :disabled="editURL" maxlength="255" :class="{'is-invalid': url_error}" />
            <div class="invalid-feedback">{{ url_message }}</div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('URL')" :disabled="isProcessing" />
        </div>
    </div>

    <div class="row ms-5 mx-5">
      <div class="p2 col-md-2 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            代表者
        </div>
        <div class="p2 col-md-2 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            氏名
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            <div class="d-flex flex-row">
                <input type="text"  class="form-control" v-model="input代表者名_姓" :disabled="edit代表者名" maxlength="255" :class="{'is-invalid': 代表者名_姓_error}" />
                <div class="invalid-feedback">{{ 代表者名_カナ_姓_message }}</div>
                <input type="text"  class="form-control" v-model="input代表者名_名" :disabled="edit代表者名" maxlength="255" :class="{'is-invalid': 代表者名_名_error}" />
                <div class="invalid-feedback">{{ 代表者名_カナ_姓_message }}</div>
            </div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('代表者名')" :disabled="isProcessing" />
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border d-flex align-items-center border-top-0 border-bottom-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-2 border d-flex align-items-center py-2 bg-white">
            フリガナ
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center py-2 bg-white">
            <div class="d-flex flex-row">
                <input type="text"  class="form-control" v-model="input代表者名_カナ_姓" :disabled="edit代表者名_カナ" maxlength="255" :class="{'is-invalid': 代表者名_カナ_姓_error}" />
                <div class="invalid-feedback">{{ 代表者名_カナ_姓_message }}</div>
                <input type="text"  class="form-control" v-model="input代表者名_カナ_名" :disabled="edit代表者名_カナ" maxlength="255" :class="{'is-invalid': 代表者名_カナ_名_error}" />
                <div class="invalid-feedback">{{ 代表者名_カナ_姓_message }}</div>
            </div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('代表者名_カナ')" :disabled="isProcessing" />
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border d-flex align-items-center border-top-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-2 border d-flex align-items-center py-2 bg-white">
            役職
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center py-2 bg-white">
            <input type="text"  class="form-control" v-model="input代表者_役職" :disabled="edit代表者_役職" maxlength="255" :class="{'is-invalid': 代表者_役職_message.length > 0}" />
            <div class="invalid-feedback">{{ 代表者_役職_message }}</div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('代表者_役職')" :disabled="isProcessing" />
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            管理者
        </div>
        <div class="p2 col-md-2 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            利用者ID
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            000001
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border d-flex align-items-center border-top-0 border-bottom-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-2 border d-flex align-items-center py-2 bg-white">
            氏名
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center py-2 bg-white">
            <div class="d-flex flex-row">
                <input type="text"  class="form-control" v-model="input管理者名_姓" :disabled="edit管理者名" maxlength="255" :class="{'is-invalid': 管理者名_姓_error}" />
                <div class="invalid-feedback">{{ 管理者名_姓_message }}</div>
                <input type="text"  class="form-control" v-model="input管理者名_名" :disabled="edit管理者名" maxlength="255" :class="{'is-invalid': 管理者名_名_error}" />
                <div class="invalid-feedback">{{ 管理者名_名_message }}</div>
            </div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('管理者名')" :disabled="isProcessing" />
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border d-flex align-items-center border-top-0 border-bottom-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-2 border d-flex align-items-center py-2 bg-white">
            フリガナ
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center py-2 bg-white">
            <div class="d-flex flex-row">
                <input type="text"  class="form-control" v-model="input管理者名_カナ_姓" :disabled="edit管理者名_カナ" maxlength="255" :class="{'is-invalid': 管理者名_カナ_姓_error}" />
                <div class="invalid-feedback">{{ 管理者名_カナ_姓_message }}</div>
                <input type="text"  class="form-control" v-model="input管理者名_カナ_名" :disabled="edit管理者名_カナ" maxlength="255" :class="{'is-invalid': 管理者名_カナ_名_error}" />
                <div class="invalid-feedback">{{ 管理者名_カナ_名_message }}</div>
            </div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('管理者名_カナ')" :disabled="isProcessing" />
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border d-flex align-items-center border-top-0 border-bottom-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-2 border d-flex align-items-center py-2 bg-white">
            部署・役職
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center py-2 bg-white">
            <div class="d-flex flex-row">
                <input type="text"  class="form-control" v-model="input管理者_所属部署" :disabled="edit管理者_部署役職" maxlength="255" :class="{'is-invalid': 管理者_所属部署_error}" />
                <div class="invalid-feedback">{{ 管理者_所属部署_message }}</div>
                <input type="text"  class="form-control" v-model="input管理者_役職" :disabled="edit管理者_部署役職" maxlength="255" :class="{'is-invalid': 管理者_役職_error}" />
                <div class="invalid-feedback">{{ 管理者_役職_message }}</div>
            </div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('管理者_部署役職')" :disabled="isProcessing" />
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border d-flex align-items-center border-top-0 border-bottom-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-2 border d-flex align-items-center py-2 bg-white">
            電話番号
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center py-2 bg-white">
            <input type="text" class="form-control" v-model="input管理者_電話番号" :disabled="edit管理者_電話番号" maxlength="30" :class="{'is-invalid': 管理者_電話番号_error}" />
            <div class="invalid-feedback">{{ 管理者_電話番号_message }}</div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('管理者_電話番号')" :disabled="isProcessing" />
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border d-flex align-items-center border-top-0 border-bottom-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-2 border d-flex align-items-center py-2 bg-white">
            メールアドレス
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center py-2 bg-white">
            <input type="mail" class="form-control" v-model="input管理者_メールアドレス" :disabled="edit管理者_メールアドレス" maxlength="255" :class="{'is-invalid': mail_error}" />
            <div class="invalid-feedback">{{ mail_message }}</div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('管理者_メールアドレス')" :disabled="isProcessing" />
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border d-flex align-items-center border-top-0 border-bottom-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-2 border d-flex align-items-center py-2 bg-white">
            メルマガ登録
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center py-2 bg-white">
            <select class="form-select" v-model="selectメルマガ登録" :disabled="editメルマガ登録">
                <option value=1>登録する</option>
                <option value=0>登録しない</option>
            </select>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
            <input type="button" class="btn btn-black btn-sm" value="変更" :disabled="corp_info.企業ID.slice(0,2) === '00' || isProcessing" @click="changeColumn('メルマガ登録')" />
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border d-flex align-items-center border-top-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-2 border d-flex align-items-center py-2 bg-white">
            パスワード
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center py-2 bg-white">
            <input type="password" class="form-control" v-model="input管理者_パスワード" :disabled="edit管理者_パスワード" maxlength="100" :class="{'is-invalid': 管理者_パスワード_error}" />
            <div class="invalid-feedback">{{ 管理者_パスワード_message }}</div>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
            <input type="button" class="btn btn-black btn-sm" value="変更" @click="changeColumn('管理者_パスワード')" :disabled="isProcessing" >
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            業種（日本標準産業分類）
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            {{ label業種コード }}　　{{ label業種名 }}
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-black btn-sm" value="変更" :disabled="isProcessing" @click="changeIndustrial">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            サイトの利用開始日<br>（年会費発生日）
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            {{ this.$root.dayjs(corp_info.利用開始日).format('YYYY年MM月DD日') }}
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center py-2 bg-white">
            会員の種類
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center py-2 bg-white">
            {{ corp_info.会員種別名 }}
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-warning btn-sm" value="有償会員切替"  v-show="corp_info.企業ID.slice(0,2) === '00'" :disabled="isProcessing" @click="changePayedMember">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center py-2 bg-white">
            利用規約等の確認
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center py-2 bg-white">
            {{ corp_info.contract_agree === 0 ? '同意しない' : '同意する' }}
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-warning btn-sm" value="有償会員切替"  v-show="corp_info.企業ID.slice(0,2) === '00'" :disabled="isProcessing" @click="changePayedMember">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center border-bottom-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-1 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            1
        </div>
        <div class="p2 col-md-3 d-flex border align-items-center py-2 bg-white">
            登録している拠点・支店の数
        </div>
        <div class="p2 col-md-1 border py-2 text-end bg-white">
            <label class="col-form-label">{{ formatNumber(pay_per_use_info.拠点数) }}</label>
        </div>
        <div class="p2 col-md-1 border d-flex align-items-center border-left-0 border-bottom-0 py-2 bg-white">
            ヶ所
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
            <input type="button" class="btn btn-black btn-sm" value="拠点・支店変更" :disabled="isProcessing" @click="showChangeBranch">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center border-top-0 border-bottom-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-1 border d-flex align-items-center border-top-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-3 d-flex border align-items-center py-2 bg-white">
            ▶実際に登録している拠点・支店の数
        </div>
        <div class="pp2 col-md-1 border py-2 text-end bg-white">
            <label class="col-form-label">{{ formatNumber(branch_cnt_actual) }}</label>
        </div>
        <div class="p2 col-md-1 border d-flex align-items-center border-left-0 border-bottom-0 py-2 bg-white">
            ヶ所
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center border-top-0 border-bottom-0 py-2 bg-white">
            従量課金情報
        </div>
        <div class="p2 col-md-1 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            2
        </div>
        <div class="p2 col-md-3 d-flex border align-items-center py-2 bg-white">
            登録している利用者（役員・従業員）の人数
        </div>
        <div class="p2 col-md-1 border py-2 text-end bg-white">
            <label class="col-form-label">{{ formatNumber(pay_per_use_info.利用者数) }}</label>
        </div>
        <div class="p2 col-md-1 border d-flex align-items-center border-left-0 border-bottom-0 py-2 bg-white">
            人
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
            <input type="button" class="btn btn-black btn-sm" value="利用者・登録変更" :disabled="isProcessing" @click="showUserList">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center border-top-0 border-bottom-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-1 border d-flex align-items-center border-top-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-3 d-flex border align-items-center py-2 bg-white">
            ▶実際に使用している人数
        </div>
        <div class="p2 col-md-1 border py-2 text-end bg-white">
            <label class="col-form-label">{{ formatNumber(user_cnt_actual) }}</label>
        </div>
        <div class="p2 col-md-1 border d-flex align-items-center border-left-0 border-bottom-0 py-2 bg-white">
            人
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center border-top-0 border-bottom-0 py-2 bg-white">
            <input type="button" class="btn btn-red px-3" value="従量課金の登録・変更" :disabled="isProcessing" @click="showChangePayPerUse">
        </div>
        <div class="p2 col-md-1 border d-flex align-items-center border-bottom-0 py-2 bg-white">
            3
        </div>
        <div class="p2 col-md-3 d-flex border align-items-center py-2 bg-white">
            登録している利用者家族の人数
        </div>
        <div class="p2 col-md-1 border py-2 text-end bg-white">
            <label class="col-form-label">{{ formatNumber(pay_per_use_info.利用家族数) }}</label>
        </div>
        <div class="p2 col-md-1 border d-flex align-items-center border-left-0 py-2 bg-white">
            人
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
            <input type="button" class="btn btn-black btn-sm" value="利用者・登録変更" :disabled="isProcessing" @click="showUserList">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center border-top-0 border-bottom-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-1 border d-flex align-items-center border-top-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-3 border d-flex align-items-center py-2 bg-white">
            ▶実際に登録している利用者家族の人数
        </div>
        <div class="p2 col-md-1 border py-2 text-end bg-white">
            <label class="col-form-label">{{ formatNumber(family_cnt_actual) }}</label>
        </div>
        <div class="p2 col-md-1 border border d-flex align-items-center border-left-0 py-2 bg-white">
            人
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
        </div>
    </div>

    <div class="row ms-5 mx-5" v-if="showLINE === 'true'">
        <div class="p2 col-md-4 border d-flex align-items-center border-top-0 border-bottom-0 py-2 bg-white">
        </div>
        <div class="p2 col-md-1 border d-flex align-items-center py-2 bg-white">
            4
        </div>
        <div class="p2 col-md-3 d-flex border align-items-center py-2 bg-white">
            LINE連携オプション
        </div>
        <div class="p2 col-md-2 border py-2 text-center bg-white">
            <label class="col-form-label">{{ corp_info.LINE連携 == 1 ? "あり" : "なし" }}</label>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center border-bottom-0 py-2">
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center py-2 bg-white">
            会員資格の更新
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center py-2 bg-white">
            {{ corp_info.会員資格更新種別名 }}
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-black btn-sm" value="会員資格更新方法・変更" :disabled="isProcessing" hidden>
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-4 border d-flex align-items-center py-2 bg-white">
            支払方法
        </div>
        <div class="p2 col-md-6 border d-flex align-items-center py-2 bg-white">
            {{ corp_info.請求方法区分名 }}
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-black btn-sm" value="クレジット決済に切り替え" :disabled="isProcessing" v-if="corp_info.請求方法区分 === 10050003 || corp_info.請求方法区分 === 10050103" @click="showChangePayment">
            <input type="button" class="btn btn-download btn-sm ms-3" value="クレジット切替方法" :disabled="isProcessing" v-if="corp_info.請求方法区分 === 10050003 || corp_info.請求方法区分 === 10050103" @click="downloadPDF">
            <input type="button" class="btn btn-black btn-sm" value="クレジットカード変更" :disabled="isProcessing" v-if="corp_info.請求方法区分 === 10050001" @click="showChangePayment">
        </div>
    </div>

    <div class="row mt-5 mb-3">
        <div class="col-md-1 text-end">◉</div>
        <div class="col-md-11">次年度の自動更新情報</div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border bg-white d-flex align-items-center border-top-0 py-2">
            {{ annual_charge.year }}年度
        </div>
        <div class="p2 col-md-6 border bg-white d-flex align-items-center border-start-0 border-top-0 py-2">
            会員種別｜従量課金の内容
        </div>
        <div class="p2 col-md-3 border bg-white border-start-0 border-top-0 py-2">
            ご請求金額（税込）
        </div>
    </div>
    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border bg-white d-flex align-items-center border-top-0 py-2">
            年会費
        </div>
        <div class="p2 col-md-6 border bg-white d-flex align-items-center border-start-0 border-top-0 py-2">
            {{ corp_info.会員種別名 }}
        </div>
        <div class="p2 col-md-2 border bg-white border-start-0 border-top-0 py-2" :class="{'fs-mini' : !annual_charge, 'text-end' : annual_charge}">
            {{ annual_charge ? 年会費_表示 : '次年度の年会費は9月以降表示されます' }}
        </div>
        <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
            円
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border bg-white d-flex align-items-center border-top-0 border-bottom-0 py-2">
            従量課金
        </div>
        <div class="p2 col-md-4 border bg-white d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2">
            登録している利用者（役員・従業員）の人数
        </div>
        <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-end border-top-0 border-end-0 py-2">
            {{ pay_per_use_info.利用者数 }}
        </div>
        <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
            人
        </div>
        <div class="p2 col-md-2 border bg-white text-end border-start-0 border-top-0 py-2">
            {{ 従量課金_利用者数 }}
        </div>
        <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
            円
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border bg-white d-flex align-items-center justify-content-center border-top-0 border-bottom-0 py-2">
        </div>
        <div class="p2 col-md-4 border bg-white d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2">
            登録する拠点・支店の数
        </div>
        <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-end border-top-0 border-end-0 py-2">
            {{ pay_per_use_info.拠点数 }}
        </div>
        <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
            ヶ所
        </div>
        <div class="p2 col-md-2 border bg-white text-end border-start-0 border-top-0 py-2">
            {{ 従量課金_拠点数 }}
        </div>
        <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
            円
        </div>
    </div>

    <div class="row ms-5 mx-5">
        <div class="p2 col-md-2 border bg-white d-flex align-items-center border-top-0 py-2">
        </div>
        <div class="p2 col-md-4 border bg-white d-flex align-items-center border-start-0 border-end-0 border-top-0 py-2">
            登録する利用者家族の人数
        </div>
        <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-end border-top-0 border-end-0 py-2">
            {{ pay_per_use_info.利用家族数 }}
        </div>
        <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
            人
        </div>
        <div class="p2 col-md-2 border bg-white text-end border-start-0 border-top-0 py-2">
            {{ 従量課金_家族数 }}
        </div>
        <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
            円
        </div>
    </div>

    <div class="row ms-5 mx-5" v-if="showLINE === 'true'">
        <div class="p2 col-md-2 border bg-white d-flex align-items-center border-top-0 py-2">
        </div>
        <div class="p2 col-md-4 border bg-white d-flex align-items-center border-start-0 border-end-0 border-top-0 py-2">
            LINE連携オプション
        </div>
        <div class="p2 col-md-2 border bg-white d-flex align-items-center justify-content-center border-top-0 py-2">
            {{ corp_info.次年度LINE連携 == 1 ? "あり" : "なし" }}
        </div>
        <div class="p2 col-md-2 border bg-white text-end border-start-0 border-top-0 py-2">
            {{ 従量課金_LINE }}
        </div>
        <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
            円
        </div>
    </div>

    <div class="row ms-5 mx-5 mt-5">
        <div class="p2 col-md-10 d-flex justify-content-between">
            <input type="button" class="btn btn-red px-3" value="更新" @click="updateCorpInfo" :disabled="isProcessing" >
            <input type="button" class="btn btn-gray px-3" value="管理者ページへ戻る" @click="backHome" :disabled="isProcessing" >
        </div>
    </div>
    <div class="row ms-5 flash col-5 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage" role="alert">
        {{ flashMessage }}
    </div>

</template>

<script>
import { computed, inject, onMounted, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import { CommonNumberFormat } from '../../../function/CommonNumberFormat.js';
import axios from 'axios';
import { useEnv } from '../../../function/useEnv';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const dayjs = inject('dayjs');
        
        const { VITE_ENABLE_LINE } = useEnv();
        const showLINE = ref(VITE_ENABLE_LINE);

        onMounted(() => {
            select事業形態.value = sessionStorage.getItem('manage_member_info_事業形態') ?? props.corp_info.事業形態;
            input法人番号.value = sessionStorage.getItem('manage_member_info_法人番号') ??  props.corp_info.法人番号;
            input組織名.value = sessionStorage.getItem('manage_member_info_組織名') ??  props.corp_info.組織名;
            input組織名_フリガナ.value = sessionStorage.getItem('manage_member_info_組織名_フリガナ') ??  props.corp_info.組織名_フリガナ;
            label郵便番号.value = sessionStorage.getItem('manage_member_info_郵便番号') ??  props.corp_info.郵便番号;
            label都道府県.value = sessionStorage.getItem('manage_member_info_都道府県') ??  props.corp_info.都道府県;
            label市区町村.value = sessionStorage.getItem('manage_member_info_市区町村') ??  props.corp_info.市区町村;
            label番地.value = sessionStorage.getItem('manage_member_info_番地') ??  props.corp_info.番地;
            input電話番号.value = sessionStorage.getItem('manage_member_info_電話番号') ??  props.corp_info.電話番号;
            input代表者名_姓.value = sessionStorage.getItem('manage_member_info_代表者名_姓') ??  props.corp_info.代表者名_姓;
            input代表者名_名.value = sessionStorage.getItem('manage_member_info_代表者名_名') ??  props.corp_info.代表者名_名;
            input代表者名_カナ_姓.value = sessionStorage.getItem('manage_member_info_代表者名_カナ_姓') ??  props.corp_info.代表者名_カナ_姓;
            input代表者名_カナ_名.value = sessionStorage.getItem('manage_member_info_代表者名_カナ_名') ??  props.corp_info.代表者名_カナ_名;
            input代表者_役職.value = sessionStorage.getItem('manage_member_info_代表者_役職') ??  props.corp_info.代表者_役職;
            input管理者名_姓.value = sessionStorage.getItem('manage_member_info_管理者名_姓') ??  props.corp_info.管理者名_姓;
            input管理者名_名.value = sessionStorage.getItem('manage_member_info_管理者名_名') ??  props.corp_info.管理者名_名;
            input管理者名_カナ_姓.value = sessionStorage.getItem('manage_member_info_管理者名_カナ_姓') ??  props.corp_info.管理者名_カナ_姓;
            input管理者名_カナ_名.value = sessionStorage.getItem('manage_member_info_管理者名_カナ_名') ??  props.corp_info.管理者名_カナ_名;
            input管理者_所属部署.value = sessionStorage.getItem('manage_member_info_管理者_所属部署') ??  props.corp_info.管理者_所属部署;
            input管理者_役職.value = sessionStorage.getItem('manage_member_info_管理者_役職') ??  props.corp_info.管理者_役職;
            input管理者_電話番号.value = sessionStorage.getItem('manage_member_info_管理者_電話番号') ??  props.corp_info.管理者_電話番号;
            input管理者_メールアドレス.value = sessionStorage.getItem('manage_member_info_管理者_メールアドレス') ??  props.corp_info.管理者_メールアドレス;
            // input管理者_パスワード.value = sessionStorage.getItem('manage_member_info_管理者_パスワード') ?  props.corp_info.管理者_メールアドレス : '***********';
            selectメルマガ登録.value = sessionStorage.getItem('manage_member_info_メルマガ登録') ??  props.corp_info.メルマガ登録;
            inputURL.value = sessionStorage.getItem('manage_member_info_url') ??  props.corp_info.url;
            if(inputURL.value === "null"){
                inputURL.value = "";
            }
            label業種コード.value = sessionStorage.getItem('manage_member_info_業種コード') ??  props.corp_info.業種;
            label業種名.value = sessionStorage.getItem('manage_member_info_業種名') ??  props.corp_info.業種名;

            set年会費();
            set合計額();

        });

        const isProcessing = ref(false);

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        const setLevel = ref('111111');

        const modalIndustrialVisible = ref(false);

        const closeModal = () => {
            modalIndustrialVisible.value = false;
        }

        const changeIndustrial = () => {
            getLargeClassList();
            modalIndustrialVisible.value = true;
        }

        const modalChangePaymentVisible = ref(false);

        const closeChangePaymentModal = () => {
            modalChangePaymentVisible.value = false;
        }

        const select事業形態 = ref('');
        const edit事業形態 = ref(true);
        const input法人番号 = ref('');
        const edit法人番号 = ref(true);
        const input組織名 = ref('');
        const edit組織名 = ref(true);
        const input組織名_フリガナ = ref('');
        const edit組織名_フリガナ = ref(true);
        const label郵便番号 = ref('');
        const label住所 = computed(() => {
            return label都道府県.value + label市区町村.value + label番地.value;
        });
        const label都道府県 = ref('');
        const label市区町村 = ref('');
        const label番地 = ref('');
        const input電話番号 = ref('');
        const edit電話番号 = ref(true);
        const input代表者名_姓 = ref('');
        const input代表者名_名 = ref('');
        const edit代表者名 = ref(true);
        const input代表者名_カナ_姓 = ref('');
        const input代表者名_カナ_名 = ref('');
        const edit代表者名_カナ = ref(true);
        const input代表者_役職 = ref("");
        const edit代表者_役職 = ref(true);
        const input管理者名_姓 = ref('');
        const input管理者名_名 = ref('');
        const edit管理者名 = ref(true);
        const input管理者名_カナ_姓 = ref('');
        const input管理者名_カナ_名 = ref('');
        const edit管理者名_カナ = ref(true);
        const input管理者_所属部署 = ref('');
        const input管理者_役職 = ref('');
        const edit管理者_部署役職 = ref(true);
        const input管理者_電話番号 = ref('');
        const edit管理者_電話番号 = ref(true);
        const input管理者_メールアドレス = ref('');
        const edit管理者_メールアドレス = ref(true);
        const selectメルマガ登録 = ref(1);
        const editメルマガ登録 = ref(true);
        const input管理者_パスワード = ref('');
        const edit管理者_パスワード = ref(true);
        const inputURL = ref('');
        const editURL = ref(true);
        const label業種コード = ref('');
        const label業種名 = ref('');

        const 法人番号_error = ref(false);
        const 法人番号_message = ref('');
        const 組織名_error = ref(false);
        const 組織名_message = ref('');
        const 組織名_フリガナ_message = ref('');
        const test = ref("");
        const url_error = ref(false);
        const url_message = ref('');
        const mail_error = ref(false);
        const mail_message = ref('');
        const 代表者名_姓_error = ref(false);
        const 代表者名_姓_message = ref('');
        const 代表者名_名_error = ref(false);
        const 代表者名_名_message = ref('');
        const 代表者名_カナ_姓_error = ref(false);
        const 代表者名_カナ_姓_message = ref('');
        const 代表者名_カナ_名_error = ref(false);
        const 代表者名_カナ_名_message = ref('');
        const 代表者_役職_message = ref("");
        const 管理者名_姓_error = ref(false);
        const 管理者名_姓_message = ref('');
        const 管理者名_名_error = ref(false);
        const 管理者名_名_message = ref('');
        const 管理者_所属部署_error = ref(false);
        const 管理者_所属部署_message = ref('');
        const 管理者_役職_error = ref(false);
        const 管理者_役職_message = ref('');
        const 管理者名_カナ_姓_error = ref(false);
        const 管理者名_カナ_姓_message = ref('');
        const 管理者名_カナ_名_error = ref(false);
        const 管理者名_カナ_名_message = ref('');
        const 電話番号_error = ref(false);
        const 電話番号_message = ref('');
        const 管理者_電話番号_error = ref(false);
        const 管理者_電話番号_message = ref('');
        const 管理者_パスワード_error = ref(false);
        const 管理者_パスワード_message = ref('');

        const backHome = () => {
            const url = '/al/administrator/home';

            location.href = url;
        }

        const changeColumn = (item) => {
            switch(item){
                case '事業形態':
                    edit事業形態.value = !edit事業形態.value;
                    break;
                case '法人番号':
                    edit法人番号.value = !edit法人番号.value;
                    break;
                case '組織名':
                    edit組織名.value = !edit組織名.value;
                    break;
                case '組織名_フリガナ':
                    edit組織名_フリガナ.value = !edit組織名_フリガナ.value;
                    break;
                case '電話番号':
                    edit電話番号.value = !edit電話番号.value;
                    break;
                case '代表者名':
                    edit代表者名.value = !edit代表者名.value;
                    break;
                case '代表者名_カナ':
                    edit代表者名_カナ.value = !edit代表者名_カナ.value;
                    break;
                case '代表者_役職':
                    edit代表者_役職.value = !edit代表者_役職.value;
                    break;
                case '管理者名':
                    edit管理者名.value = !edit管理者名.value;
                    break;
                case '管理者名_カナ':
                    edit管理者名_カナ.value = !edit管理者名_カナ.value;
                    break;
                case '管理者_部署役職':
                    edit管理者_部署役職.value = !edit管理者_部署役職.value;
                    break;
                case '管理者_電話番号':
                    edit管理者_電話番号.value = !edit管理者_電話番号.value;
                    break;
                case '管理者_メールアドレス':
                    edit管理者_メールアドレス.value = !edit管理者_メールアドレス.value;
                    break;
                case '管理者_パスワード':
                    edit管理者_パスワード.value = !edit管理者_パスワード.value;
                    if(!edit管理者_パスワード.value && input管理者_パスワード.value === '***********'){
                        input管理者_パスワード.value = '';
                    }
                    break;
                case 'メルマガ登録':
                    editメルマガ登録.value = !editメルマガ登録.value;
                    break;
                case 'URL':
                    editURL.value = !editURL.value;
                    break;
            }
        }

        const getCorporationInfo = () => {
            const url = '/api/getNTACorporateInfo?corporate_number=' + input法人番号.value;

            axios.get(url)
            .then(res => {
                input組織名.value = res.data.name;
                input組織名_フリガナ.value = res.data.furigana;
                label郵便番号.value = res.data.postCode;
                label都道府県.value = res.data.prefectureName;
                label市区町村.value = res.data.cityName;
                label番地.value = res.data.streetNumber;
            });
        }

        const updateCorpInfo = () => {
            法人番号_error.value = false;
            法人番号_message.value = '';
            組織名_error.value = false;
            組織名_message.value = '';
            組織名_フリガナ_message.value = '';
            url_error.value = false;
            url_message.value = '';
            mail_error.value = false;
            mail_message.value = '';
            代表者名_姓_error.value = false;
            代表者名_姓_message.value = '';
            代表者名_名_error.value = false;
            代表者名_名_message.value = '';
            代表者名_カナ_姓_error.value = false;
            代表者名_カナ_姓_message.value = '';
            代表者名_カナ_名_error.value = false;
            代表者名_カナ_名_message.value = '';
            代表者_役職_message.value = "";
            管理者名_姓_error.value = false;
            管理者名_姓_message.value = '';
            管理者名_名_error.value = false;
            管理者名_名_message.value = '';
            管理者_所属部署_error.value = false;
            管理者_所属部署_message.value = '';
            管理者_役職_error.value = false;
            管理者_役職_message.value = '';
            管理者名_カナ_姓_error.value = false;
            管理者名_カナ_姓_message.value = '';
            管理者名_カナ_名_error.value = false;
            管理者名_カナ_名_message.value = '';
            電話番号_error.value = false;
            電話番号_message.value = '';
            管理者_電話番号_error.value = false;
            管理者_電話番号_message.value = '';
            管理者_パスワード_error.value = false;
            管理者_パスワード_message.value = '';

            isProcessing.value = true;

            const url = '/api/updateCorpInfoFromAdministrator';

            axios.post(url, {
                事業形態: select事業形態.value,
                法人番号: input法人番号.value,
                組織名: input組織名.value,
                組織名_フリガナ: input組織名_フリガナ.value,
                郵便番号: label郵便番号.value,
                都道府県: label都道府県.value,
                市区町村: label市区町村.value,
                番地: label番地.value,
                電話番号: input電話番号.value,
                url: inputURL.value,
                mail: input管理者_メールアドレス.value,
                代表者名_姓: input代表者名_姓.value,
                代表者名_名: input代表者名_名.value,
                代表者名_カナ_姓: input代表者名_カナ_姓.value,
                代表者名_カナ_名: input代表者名_カナ_名.value,
                代表者_役職: input代表者_役職.value,
                管理者名_姓: input管理者名_姓.value,
                管理者名_名: input管理者名_名.value,
                管理者名_カナ_姓: input管理者名_カナ_姓.value,
                管理者名_カナ_名: input管理者名_カナ_名.value,
                管理者_所属部署: input管理者_所属部署.value,
                管理者_役職: input管理者_役職.value,
                管理者_電話番号: input管理者_電話番号.value,
                管理者_パスワード: input管理者_パスワード.value === '***********' ? '' : input管理者_パスワード.value,
                メルマガ登録: selectメルマガ登録.value,
                業種: label業種コード.value,
                corp_id: props.corp_info.id,
            }).then(res => {
                edit事業形態.value = true;
                edit法人番号.value = true;
                edit組織名.value = true;
                edit組織名_フリガナ.value = true;
                edit電話番号.value = true;
                edit代表者名.value = true;
                edit代表者名_カナ.value = true;
                edit代表者_役職.value = true;
                edit管理者名.value = true;
                edit管理者名_カナ.value = true;
                edit管理者_部署役職.value = true;
                edit管理者_電話番号.value = true;
                edit管理者_メールアドレス.value = true;
                edit管理者_パスワード.value = true;
                editメルマガ登録.value = true;
                editURL.value = true;

                visibleFlashMessage('正常に更新されました', 'success');
            }).catch(err => {
                if(err.response.status === 422){
                    console.log(err.response.data);
                    if(err.response.data.errors.代表者名_姓){
                        代表者名_姓_error.value = true;
                        代表者名_姓_message.value = err.response.data.errors.代表者名_姓[0];
                    }
                    if(err.response.data.errors.代表者名_名){
                        代表者名_名_error.value = true;
                        代表者名_名_message.value = err.response.data.errors.代表者名_名[0];
                    }
                    if(err.response.data.errors.代表者名_カナ_姓){
                        代表者名_カナ_姓_error.value = true;
                        代表者名_カナ_姓_message.value = err.response.data.errors.代表者名_カナ_姓[0];
                    }
                    if(err.response.data.errors.代表者名_カナ_名){
                        代表者名_カナ_名_error.value = true;
                        代表者名_カナ_名_message.value = err.response.data.errors.代表者名_カナ_名[0];
                    }
                    if(err.response.data.errors.代表者_役職){
                        代表者_役職_message.value = err.response.data.errors.代表者_役職[0];
                    }
                    if(err.response.data.errors.管理者名_姓){
                        管理者名_姓_error.value = true;
                        管理者名_姓_message.value = err.response.data.errors.管理者名_姓[0];
                    }
                    if(err.response.data.errors.管理者名_名){
                        管理者名_名_error.value = true;
                        管理者名_名_message.value = err.response.data.errors.管理者名_名[0];
                    }
                    if(err.response.data.errors.管理者名_カナ_姓){
                        管理者名_カナ_姓_error.value = true;
                        管理者名_カナ_姓_message.value = err.response.data.errors.管理者名_カナ_姓[0];
                    }
                    if(err.response.data.errors.管理者名_カナ_名){
                        管理者名_カナ_名_error.value = true;
                        管理者名_カナ_名_message.value = err.response.data.errors.管理者名_カナ_名[0];
                    }
                    if(err.response.data.errors.管理者_所属部署){
                        管理者_所属部署_error.value = true;
                        管理者_所属部署_message.value = err.response.data.errors.管理者_所属部署[0];
                    }
                    if(err.response.data.errors.管理者_役職){
                        管理者_役職_error.value = true;
                        管理者_役職_message.value = err.response.data.errors.管理者_役職[0];
                    }
                    if(err.response.data.errors.電話番号){
                        電話番号_error.value = true;
                        電話番号_message.value = err.response.data.errors.電話番号[0];
                    }
                    if(err.response.data.errors.管理者_電話番号){
                        管理者_電話番号_error.value = true;
                        管理者_電話番号_message.value = err.response.data.errors.管理者_電話番号[0];
                    }
                    if(err.response.data.errors.管理者_パスワード){
                        管理者_パスワード_error.value = true;
                        管理者_パスワード_message.value = err.response.data.errors.管理者_パスワード[0];
                    }
                    if(err.response.data.errors.法人番号){
                        法人番号_error.value = true;
                        法人番号_message.value = err.response.data.errors.法人番号[0];
                    }
                    if(err.response.data.errors.組織名){
                        組織名_error.value = true;
                        組織名_message.value = err.response.data.errors.組織名[0];
                    }
                    if(err.response.data.errors.組織名_フリガナ){
                        組織名_フリガナ_message.value = err.response.data.errors.組織名_フリガナ[0];
                    }
                    if(err.response.data.errors.url){
                        url_error.value = true;
                        url_message.value = err.response.data.errors.url[0];
                    }
                    if(err.response.data.errors.mail){
                        mail_error.value = true;
                        mail_message.value = err.response.data.errors.mail[0];
                    }
                }else{
                    console.log(err.response.data);
                }
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const 業種_大分類 = ref('');
        const 業種_中分類 = ref('');
        const 業種_小分類 = ref('');
        const 業種_細分類 = ref('');
        const 業種_error = ref(false);
        const 業種_message = ref('');

        const large_class_list = ref([]);
        const middle_class_list = ref([]);
        const minor_class_list = ref([]);
        const sub_class_list = ref([]);

        const getLargeClassList = () => {
            large_class_list.value = [];
            業種_大分類.value = '';
            middle_class_list.value = [];
            業種_中分類.value = '';
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getLargeClassList';
            axios.get(url).then(res => {
                large_class_list.value = res.data;
            });
        }

        const changeLargeClass = () => {
            middle_class_list.value = [];
            業種_中分類.value = '';
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getMiddleClassList?largeClass=' + 業種_大分類.value;

            axios.get(url).then(res => {
                middle_class_list.value = res.data;
            });
        }

        const changeMiddleClass = () => {
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getMinorClassList?largeClass=' + 業種_大分類.value + '&middleClass=' + 業種_中分類.value;

            axios.get(url).then(res => {
                minor_class_list.value = res.data;
            });
        }

        const changeMinorClass = () => {
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getSubClassList?largeClass=' + 業種_大分類.value + '&middleClass=' + 業種_中分類.value + '&minorClass=' + 業種_小分類.value;

            axios.get(url).then(res => {
                sub_class_list.value = res.data;
            });
        }

        const select細分類 = () => {
            const selected = sub_class_list.value.filter((item) => {
                return item.細分類 === 業種_細分類.value;
            });

            label業種コード.value = 業種_細分類.value;
            label業種名.value = selected[0].項目名;

            closeModal();
        }

        const showChangePayPerUse = () => {
            setSession();

            const url = '/al/administrator/register_pay_per_use?back=ManageMemberInfo';

            location.href = url;
        }

        const setSession = () => {
            sessionStorage.setItem('manage_member_info_事業形態', select事業形態.value);
            sessionStorage.setItem('manage_member_info_法人番号', input法人番号.value);
            sessionStorage.setItem('manage_member_info_組織名', input組織名.value);
            sessionStorage.setItem('manage_member_info_組織名_フリガナ', input組織名_フリガナ.value);
            sessionStorage.setItem('manage_member_info_郵便番号', label郵便番号.value);
            sessionStorage.setItem('manage_member_info_都道府県', label都道府県.value);
            sessionStorage.setItem('manage_member_info_市区町村', label市区町村.value);
            sessionStorage.setItem('manage_member_info_番地', label番地.value);
            sessionStorage.setItem('manage_member_info_電話番号', input電話番号.value);
            sessionStorage.setItem('manage_member_info_url', inputURL.value);
            sessionStorage.setItem('manage_member_info_管理者_メールアドレス', input管理者_メールアドレス.value);
            sessionStorage.setItem('manage_member_info_代表者名_姓', input代表者名_姓.value);
            sessionStorage.setItem('manage_member_info_代表者名_名', input代表者名_名.value);
            sessionStorage.setItem('manage_member_info_代表者名_カナ_姓', input代表者名_カナ_姓.value);
            sessionStorage.setItem('manage_member_info_代表者名_カナ_名', input代表者名_カナ_名.value);
            sessionStorage.setItem('manage_member_info_代表者_役職', input代表者_役職.value);
            sessionStorage.setItem('manage_member_info_管理者名_姓', input管理者名_姓.value);
            sessionStorage.setItem('manage_member_info_管理者名_名', input管理者名_名.value);
            sessionStorage.setItem('manage_member_info_管理者名_カナ_姓', input管理者名_カナ_姓.value);
            sessionStorage.setItem('manage_member_info_管理者名_カナ_名', input管理者名_カナ_名.value);
            sessionStorage.setItem('manage_member_info_管理者_所属部署', input管理者_所属部署.value);
            sessionStorage.setItem('manage_member_info_管理者_役職', input管理者_役職.value);
            sessionStorage.setItem('manage_member_info_管理者_電話番号', input管理者_電話番号.value);
            sessionStorage.setItem('manage_member_info_管理者_パスワード', input管理者_パスワード.value);
            sessionStorage.setItem('manage_member_info_メルマガ登録', selectメルマガ登録.value);
            sessionStorage.setItem('manage_member_info_業種コード', label業種コード.value);
            sessionStorage.setItem('manage_member_info_業種名', label業種名.value);
        }

        const showChangeBranch = () => {
            const url = '/al/administrator/register_branch';

            location.href = url;
        }

        const showUserList = () => {
            const url = '/al/administrator/show_user_list';

            location.href = url;
        }

        const formatNumber = (num) => {
            return CommonNumberFormat(num);
        }

        const set年会費 = () => {
            switch(props.corp_info.会員種別){
                case 10010002:
                    //企業会員
                    年会費.value = props.annual_charge.年会費_企業会員;
                case 10010003:
                    //団体会員
                    年会費.value = props.annual_charge.年会費_団体会員;
                case 10010004:
                    //公共会員
                    年会費.value = props.annual_charge.年会費_公共会員;
                default:
                    //その他
                    年会費.value = props.annual_charge.年会費_企業会員;
            }
        }

        const 払込開始日 = computed(() => {
            return dayjs(props.annual_charge.制定日).add(1, 'd').format('YYYY年MM月DD日');
        });

        const 払込終了日 = computed(() => {
            return dayjs(props.annual_charge.year + '-09-30').format('YYYY年MM月DD日');
        });

        const 年会費 = ref(0);

        const 年会費_表示 = computed(() => {
            return CommonNumberFormat(年会費.value);
        });

        const 従量課金_利用者数 = computed(() => {
            return CommonNumberFormat(props.charge.従量_従業員総額);
        });

        const 従量課金_拠点数 = computed(() => {
            return CommonNumberFormat(props.charge.従量_支店総額);
        });

        const 従量課金_家族数 = computed(() => {
            return CommonNumberFormat(props.charge.従量_家族総額);
        });

        const 従量課金_LINE = computed(() => {
            return CommonNumberFormat(props.charge.従量_LINE);
        })

        const 合計額 = ref(0);

        const set合計額 = () => {
            合計額.value = 年会費.value + props.charge.従量_従業員総額 + props.charge.従量_支店総額 + props.charge.従量_家族総額
        }

        const 合計額_表示 = computed(() => {
            return CommonNumberFormat(合計額.value);
        });

        const changePayedMember = () => {
            const url = '/al/administrator/switchPaidMember';

            location.href = url;
        }

        const showChangePayment = () => {
            if(props.first_bill_info.未収flg == 1){
                modalChangePaymentVisible.value = true
            }else{
                //支払方法切替画面へ遷移
                showRegisterCredit();
            }
        }

        const showSwitchPayMethodBeforeDeposit = () => {
            const url = '/al/switch/payMethodBeforeDeposit';

            location.href = url;
        }

        const showRegisterCredit = () => {
            const url = '/al/administrator/register_credit';

            location.href = url;
        }

        const downloadPDF = () => {
            const url_print = '/api/downloadDoc';

            isProcessing.value = true;

            axios.post(url_print, {
                'kbn': "changeCredit",
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = '年会費をクレジット決済に切替.pdf';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }).finally(fin=>{
                isProcessing.value = false;
            });

        }


        return {
            isProcessing,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,

            setLevel,

            modalIndustrialVisible,
            closeModal,
            changeIndustrial,

            modalChangePaymentVisible,
            closeChangePaymentModal,

            select事業形態,
            edit事業形態,
            input法人番号,
            edit法人番号,
            input組織名,
            edit組織名,
            input組織名_フリガナ,
            edit組織名_フリガナ,
            label郵便番号,
            label住所,
            label都道府県,
            label市区町村,
            label番地,
            input電話番号,
            edit電話番号,
            input代表者名_姓,
            input代表者名_名,
            edit代表者名,
            input代表者名_カナ_姓,
            input代表者名_カナ_名,
            edit代表者名_カナ,
            input代表者_役職,
            edit代表者_役職,
            input管理者名_姓,
            input管理者名_名,
            edit管理者名,
            input管理者名_カナ_姓,
            input管理者名_カナ_名,
            edit管理者名_カナ,
            input管理者_所属部署,
            input管理者_役職,
            edit管理者_部署役職,
            input管理者_電話番号,
            edit管理者_電話番号,
            input管理者_メールアドレス,
            edit管理者_メールアドレス,
            selectメルマガ登録,
            editメルマガ登録,
            input管理者_パスワード,
            edit管理者_パスワード,
            inputURL,
            editURL,
            label業種コード,
            label業種名,

            法人番号_error,
            法人番号_message,
            組織名_error,
            組織名_message,
            組織名_フリガナ_message,
            test,
            url_error,
            url_message,
            mail_error,
            mail_message,
            代表者名_姓_error,
            代表者名_姓_message,
            代表者名_名_error,
            代表者名_名_message,
            代表者名_カナ_姓_error,
            代表者名_カナ_姓_message,
            代表者名_カナ_名_error,
            代表者名_カナ_名_message,
            代表者_役職_message,
            管理者名_姓_error,
            管理者名_姓_message,
            管理者名_名_error,
            管理者名_名_message,
            管理者名_カナ_姓_error,
            管理者名_カナ_姓_message,
            管理者名_カナ_名_error,
            管理者名_カナ_名_message,
            管理者_所属部署_error,
            管理者_所属部署_message,
            管理者_役職_error,
            管理者_役職_message,
            電話番号_error,
            電話番号_message,
            管理者_電話番号_error,
            管理者_電話番号_message,
            管理者_パスワード_error,
            管理者_パスワード_message,

            backHome,

            changeColumn,
            getCorporationInfo,
            updateCorpInfo,

            業種_大分類,
            業種_中分類,
            業種_小分類,
            業種_細分類,
            業種_error,
            業種_message,

            large_class_list,
            middle_class_list,
            minor_class_list,
            sub_class_list,
            getLargeClassList,
            changeLargeClass,
            changeMiddleClass,
            changeMinorClass,

            select細分類,

            showChangeBranch,
            showUserList,
            showChangePayPerUse,

            setSession,

            formatNumber,

            set年会費,

            払込開始日,
            払込終了日,

            年会費,
            年会費_表示,
            従量課金_利用者数,
            従量課金_拠点数,
            従量課金_家族数,
            従量課金_LINE,
            合計額,
            set合計額,
            合計額_表示,

            changePayedMember,

            showChangePayment,

            showSwitchPayMethodBeforeDeposit,
            showRegisterCredit,

            downloadPDF,

            showLINE,
        }
        
    },
    props: {
        corp_info: {
            type: Object,
        },
        business_form_list: {
            type: Array,
        },
        pay_per_use_info: {
            type: Object,
        },
        branch_cnt_actual: {
            type: Number,
        },
        user_cnt_actual: {
            type: Number,
        },
        family_cnt_actual: {
            type: Number,
        },
        annual_charge: {
            type: Object,
        },
        charge: {
            type: Object,
        },
        first_bill_info: {
            type: Object,
        },
    }
}
</script>

<style lang="scss" scoped>
    #industrial-modal{
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #change-payment-modal{
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #modal-content {
        z-index: 2;
        width: 50%;
        padding: 5em;
        background: #fff;
    }

    .btn-download {
        border: 1px solid;
        color: white;
        border-color: #a5a5a5;
        background-color: #a5a5a5;
    }

    .btn-download:hover {
        border: 1px solid;
        color: #a5a5a5;
        background-color: white;
    }

</style>