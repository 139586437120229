<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3" style="font-size:14pt">
            2.　BCPの運用体制｜緊急事態の人員体制
        </div>
        <div class="p2 mt-3 col-md-4 d-flex align-items-start justify-content-end">
            <input type="button" class="btn btn-bcp-basic btn-sm" style="font-size:12pt" value="基本｜2.BCP運用体制 へ進む" v-show="is_completed_introduction" :disabled="isProcessing" @click="showBasic">
        </div>
        <div class="p2 mt-3 col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
            :set_level="setLevel"
        />

    <div class="row ms-3 mt-5">
        <div class="p2 col-md  d-flex align-items-center justify-content-start py-2">
            実際に災害等が発生した際でも、あなたの{{ callReplaceWord('組織') }}が事業継続のために適切な行動ができるよう、緊急時の対応とその責任者を整理します。
        </div>
    </div>

    <div class="row mx-3 mt-3">
        <div class="col-md-10">
            総括責任者の役割として、多いものを例示しました。あなたの{{ callReplaceWord("組織") }}の総括責任者の役割として合致するものがあれば、それをすべて選択してください。<br>
            あなたの{{ callReplaceWord("組織") }}として総括責任者の役割があれば、それをご入力ください。
        </div>
        <div class="col-md-2">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>緊急時の対応には、初動対応、復旧のための活動等、様々なものがありますが、最低限そうした全社の対応に関する重要な意思決定及びその指揮命令を行う統括責任者を取り決めておくことが重要となります。［統括責任者の役割の例：全社の対応（初動対応、復旧のための活動等）に関する重要な意思決定及びその指揮命令］</label>
                    </div>
                </template>
            </VDropdown>
        </div>

    </div>
    <div class="row mx-3 mt-5">
        <div class="col">
            <div v-for="(role, index) in roleList" :key="index">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" :id="'check_' + index" :value="role.title" v-model="checkRole">
                    <label class="form-check-label" :for="'check_' + index">{{ role.title }}</label>
                </div>
                <div class="ms-5 mb-4">
                    <label class="form-check-label" :for="'check_' + index">{{ role.body }}</label>
                </div>
            </div>
            <div>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="check_other" value="その他" v-model="checkRole">
                    <label class="form-check-label" for="check_other">その他</label>
                </div>
                <div class="ms-5 mb-4">
                    <textarea class="form-control" v-model="textOther" rows="2" :disabled="checkRole.indexOf('その他') === -1" @change="addRole"></textarea>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md  d-flex align-items-center justify-content-start py-2">
            統括責任者の役割
        </div>
    </div>

    <div class="row ms-3">
        <textarea type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="textForms[0].value" :rows="checkRole.length < 2 ? 2 : checkRole.length + 1" readonly></textarea>
    </div>


    <div v-for="(form, index) in valueForms" :key="index">
        <div class="row ms-3 mt-3">
            <div class="p2 mt-3 col-md-5 d-flex align-items-center justify-content-start">
                <span v-if="index == 0">統括責任者 氏名</span>
                <span v-else-if="index == 1">代理責任者 氏名</span>
            </div>
            <div class="p2 mt-3 col-md-1 d-flex align-items-center justify-content-start">
            </div>
            <div class="p2 mt-3 col-md-5 d-flex align-items-center justify-content-start">
                <span v-if="index <= 1">役職</span>
            </div>
        </div>

        <div class="row ms-3">
            <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                <select name="setJobTitleCandidate" class="form-select" v-model="form.values[0].value" @change="setJobTitle(form.values[0].value, index)">
                    <option type="text" v-for="adminCandidate in admin_candidate_list" :key="adminCandidate" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                </select>
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
            </div>
            <div class="p2 col-md-4 d-flex align-items-center justify-content-start">
                <input type="text" class="form-control d-flex align-items-center justify-content-start py-2" placeholder="代表取締役" v-if="index == 0" v-model="form.values[1].value">
                <input type="text" class="form-control d-flex align-items-center justify-content-start py-2" placeholder="" v-else v-model="form.values[1].value">
            </div>
            <div class="col-md-1 ms-4 ms-md-0">
                <input type="button" class="btn btn-gray btn-sm" value="クリア" @click="clearCandidate(index)">
            </div>
            <div class="p2 col-md-1 d-flex align-items-start justify-content-start ms-4 ms-md-0" v-if="index == 1">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                    <template #popper>
                        <div class="row">
                            <label>統括責任者が不在の場合や被災する場合もありますので、代理責任者を2名決めておきましょう。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 mt-5 col-md-4 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="入門｜3.被害想定と事前対策 へ進む" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 mt-5 ms-3 col-md-2 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="目次構成 へ戻る" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted, watch } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if(props.bcp_introduction_texts_info.length > 0){
                props.bcp_introduction_texts_info.forEach(data => {
                    if (data.column_name == 'N00012') {
                        textForms.splice(0);
                        textForms.push({
                            'columnName': data.column_name,
                            'value': data.text_value,
                        });

                        checkRole.value = [];
                        let wkText = data.text_value.toString();

                        roleList.forEach(item => {
                            if(data.text_value.toString().indexOf(item.title) > -1){
                                checkRole.value.push(item.title);
                                wkText = wkText.replace(item.title, "");
                            }
                        });

                        wkText = wkText.replace(/(\r\n|\n|\r)/g, "");

                        if(wkText.length > 0){
                            checkRole.value.push("その他");
                            textOther.value = wkText;
                        }
                    }
                });
             }

             if(props.bcp_introduction_values_info.length > 0){
                props.bcp_introduction_values_info.forEach(fetchData => {
                    valueForms.forEach(initData => {
                        if(fetchData.column_name == initData.columnName){
                            initData.values.splice(fetchData.seq - 1, 1, {value: fetchData.value});
                        }
                    });
                });
             }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('100000');
        const isProcessing = ref(false);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const textForms = reactive([
            {
                columnName: 'N00012',
                value: '',
            },
        ]);

        const valueForms = reactive([
            {
                columnName: 'N00013',
                values:[
                    {value: ''},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00014',
                values:[
                    {value: ''},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00015',
                values:[
                    {value: ''},
                    {value: ''},
                ]
            },

        ]);

        const roleList = reactive([
            {
                title: "緊急事態の宣言と対応方針の決定",
                body: "緊急事態の発生時にその状況を迅速に評価し、組織としての緊急事態を公式に宣言する。同時に、初動対応の方針や優先順位を設定し、全" + callReplaceWord("従業員") + "に周知する。",
            },
            {
                title: "計画の策定と実施",
                body: callReplaceWord("自社") + "の経営理念やビジョンを踏まえ、BCPの策定プロセスを統括し、全体的な方針を決定する。また、BCP等の計画策定、事前対策等の実施、見直し・改善などについて、的確に判断し、実行し、必要に応じて修正する。",
            },
            {
                title: "指揮命令系統の構築と管理",
                body: "各部門や対応チームの役割と責任を明確にし、指揮命令系統を構築する。また、指示が確実に伝達され、適切に実行されるよう監督する。",
            },
            {
                title: "社内外のコミュニケーションの統括",
                body: callReplaceWord("社内") + "外の関係者への情報提供を統括し、" + callReplaceWord("従業員") + "、" + callReplaceWord("顧客") + "、" + callReplaceWord("取引先") + "、メディア、行政機関などに対して、正確でタイムリーな情報を発信する。また、情報の流れを管理し、誤情報や混乱を防ぐ。",
            },
            {
                title: "必要資源の最適な配分と調整",
                body: "ヒト、モノ、カネ、情報などの必要な資源を適切に配分し、緊急事態においても組織全体の業務が継続できるよう調整を行う。必要資源が不足する場合には、その確保手段を迅速に検討し、実行する。",
            },
            {
                title: "リスク評価と対策の策定",
                body: "被害想定を行いリスクを特定し、リスク発生の影響を評価する。また、リスクを軽減するための対策（予防策）、リスクが発生した場合の対応策（対応策）を計画する。",
            },
            {
                title: "復旧計画の策定と実施の指導",
                body: "災害や事故後の復旧プロセスを指導し、組織全体の速やかな事業再開を目指して計画を策定・実行する。復旧活動の進捗状況をモニタリングし、必要に応じて計画の見直しを行う。",
            },
            {
                title: "後方支援の調整と支援者の安全確保",
                body: "後方支援体制の整備と、支援にあたる人々の安全確保を行う。これには、緊急時対応に関わるスタッフの健康状態の管理や、心理的支援も含まれる。",
            },
        ]);

        const checkRole = ref([]);
        const textOther = ref("");


        // 役職の自動設定
        const setJobTitle = (name, index) => {
            valueForms[index].values[1].value = props.admin_candidate_list.filter(v => v.氏名 == name)[0].役職;
        }

        const showBasic = async () => {
            // 人員体制を登録
            const result = await registerIntroduction3();

            if (result == '200') {
                // 基本コースページ遷移
                const url = '/bcp/bcp_first/2';
                location.href = url;
            }
        }

        const showBack = async () => {
            // 人員体制を登録
            const result = await registerIntroduction3();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 人員体制を登録
            const result = await registerIntroduction3();

            if (result == '200') {
                // 次のページへ遷移
                const url = '/bcp/bcp_introduction/4';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 人員体制を登録
            const result = await registerIntroduction3();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 人員体制登録
        const registerIntroduction3 = async () => {
            const reqValueForms = [];
            valueForms.forEach(data => {
                data.values.forEach((val, index) => {
                    //if(val.value.length !== 0){
                        reqValueForms.push({
                            columnName: data.columnName,
                            seq: index + 1,
                            value: val.value,
                        });
                    //}
                })
            })

            const postUrl = '/api/bcp/registerIntroduction3';
            isProcessing.value = true;

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'text_form_list': textForms,
                'value_form_list': reqValueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const clearCandidate = (idx) => {
            let message = "";
            switch(idx){
                case 0:
                    message = "統括責任者をクリアします。よろしいですか？";
                    break;
                case 1:
                    message = "代理責任者（１人目）をクリアします。よろしいですか？";
                    break;
                case 2:
                    message = "代理責任者（２人目）をクリアします。よろしいですか？";
                    break;
                default:
                    break;
            }

            if(!confirm(message)){
                return false;
            }
            
            valueForms[idx].values[0].value = "";
            valueForms[idx].values[1].value = "";
        }

        let oldTextOther = "";
        const addRole = () => {
            let wkArray = []
            wkArray = textForms[0].value.split("\r\n");
            if(oldTextOther.length > 0){
                wkArray = wkArray.filter(item => item !== oldTextOther);
            }
            wkArray.push(textOther.value);
            textForms[0].value = wkArray.join('\r\n');
            oldTextOther = textOther.value;
        }

        watch(checkRole, (newVal) => {
            if(checkRole.value.indexOf("その他") > -1){
                const filteredArray = checkRole.value.filter(item => item !== "その他");

                filteredArray.push(textOther.value);

                textForms[0].value = filteredArray.join('\r\n');
            }else{
                textForms[0].value = checkRole.value.join("\r\n");
            }
        })

        return {
            setLevel,
            textForms,
            valueForms,
            isProcessing,
            roleList,
            callReplaceWord,
            setJobTitle,
            showBasic,
            showBack,
            showNext,
            showContents,
            clearCandidate,

            checkRole,
            textOther,
            addRole,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        is_completed_introduction: {
            type: Boolean,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_introduction_texts_info: {
            type: Object,
        },
        bcp_introduction_values_info: {
            type: Object,
        },
        admin_candidate_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
