<template>
    <div class="row">
        <div class="h5 heading-orange mt-3 col-md-8">
            システム基本設定
        </div>
        <div class="col-md-4 mt-3">
            <button class="btn btn-bcp-restrictions py-2 me-3" @click="backMenu"><i class="bi bi-gear"></i><span class="ms-3">マイページ</span></button>
            <input type="button" class="btn btn-black px-4 py-2" value="前ページへ戻る" @click="backAdminHome">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row d-none d-md-flex mt-3">
        <!-- 表のタイトル行 -->
        <div class="row header">
            <div class="col-md-2 cell bg-light"><strong>{{ titles[0] }}</strong></div>
            <div class="col-md-3 cell bg-light"><strong>{{ titles[1] }}</strong></div>
            <div class="col-md-7 cell bg-light"><strong>{{ titles[2] }}</strong></div>
        </div>
        <!-- 表のボディー行 -->
        <div v-for="(row, rowIndex) in rows" :key="'row-' + rowIndex" class="row w-100">
            <div class="col-md-2 custom-col-width cell">{{ row.function }}</div>
            <div class="col-md-3 custom-col-width cell">{{ row.content }}</div>
            <div class="col-md-7 custom-col-width cell">
                <template v-if="row.type === 'button'">
                    <div class="d-flex flex-row">
                        <button type="button" class="btn btn-none w-25 me-5" @click="movePage(row.page)">
                            <div class="d-flex fill align-middle">
                                <i :id="row.image_id"></i>
                                <span class="ps-3 flex-fill text-start">{{ row.title }}</span>
                            </div>
                        </button>
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <span>{{ row.hint_text }}</span>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </template>
                <template v-if="row.type === 'select'">
                    <template v-if="rowIndex === 1">
                        <div class="d-flex flex-row">
                            <div class="w-25 me-5">
                                <select v-model="viewing_range_for_safety" class="form-select">
                                    <option v-for="option in viewing_range_for_safety_list" :key="option.code" :value="option.code">
                                        {{ option.name }}
                                    </option>
                                </select>
                            </div>

                            <button class="btn btn-black" @click="updateViewingRangeForSafety(rowIndex)">登録</button>
                        </div>
                        <div class="flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                            {{ flashMessage }}
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </div>
    <!-- md以下の画面サイズ -->
    <div class="d-md-none">
        <div v-for="(row, rowIndex) in rows" :key="'row-sm-' + rowIndex" class="table-bordered p-2 mb-3">
            <div class="row">
                <div class="col custom-col-width cell bg-light">{{ titles[0] }}</div>
            </div>
            <div class="row">
                <div class="col custom-col-width cell">{{ row.function }}</div>
            </div>
            <div class="row">
                <div class="col custom-col-width cell bg-light">{{ titles[1] }}</div>
            </div>
            <div class="row">
                <div class="col custom-col-width cell">{{ row.content }}</div>
            </div>
            <div class="row">
                <div class="col custom-col-width cell bg-light">{{ titles[2] }}</div>
            </div>
            <div class="row">
                <div class="col-md-7 custom-col-width cell">
                    <template v-if="row.type === 'button'">
                        <div class="d-flex flex-column">
                            <button type="button" class="btn btn-none" @click="movePage(row.page)">
                                <div class="d-flex fill align-middle">
                                    <i :id="row.image_id"></i>
                                    <span class="ps-3 flex-fill text-start">{{ row.title }}</span>
                                </div>
                            </button>
                            <VDropdown
                                theme="hint-theme"
                            >
                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                <template #popper>
                                    <div class="row">
                                        <span>{{ row.hint_text }}</span>
                                    </div>
                                </template>
                            </VDropdown>
                        </div>
                    </template>
                    <template v-if="row.type === 'select'">
                        <template v-if="rowIndex === 1">
                            <div class="d-flex flex-column">
                                <div class="">
                                    <select v-model="viewing_range_for_safety" class="form-select">
                                        <option v-for="option in viewing_range_for_safety_list" :key="option.code" :value="option.code">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <button class="btn btn-black" @click="updateViewingRangeForSafety(rowIndex)">登録</button>
                                </div>
                            </div>
                            <div class="flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                                {{ flashMessage }}
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import levelIcon from '../../common/UserLevelIconsComponent.vue';
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            if(props.corp_settings.length > 0){
                const filtered = props.corp_settings.filter(item=>item.setting_kbn === 9200);
                if(filtered.length > 0){
                    viewing_range_for_safety.value = filtered[0].setting_val;
                }else{
                    viewing_range_for_safety.value = 92000001;
                }
            }else{
                viewing_range_for_safety.value = 92000001;
            }
        });

        const setLevel = ref('111111');

        const titles = ref([
            "機能",
            "項目・設定内容",
            "設定",
        ]);

        const rows = ref([
            {
                function: "読替登録",
                content: "既定用語の読み替え",
                type: "button",
                image_id: "admin_yomikae",
                title: "読替え登録",
                page: '/al/administrator/replacement',
                hint_text: "サイト内の規定の呼称を、自己・自社の呼称に合わせて読み替えます。（例：「代表」という呼称を、「店主」「校長」「理事長」等任意の呼称に読み替え）",
            },
            {
                function: "安否確認",
                content: "みんなの安否の閲覧範囲",
                type: "select",
            },
        ]);

        const viewing_range_for_safety = ref("");
        const isProcessing = ref(false);

        const backMenu = () => {
            const url = '/al/myPage/home';

            location.href = url;
        }

        const backAdminHome = () => {
            const url = '/al/administrator/home';

            location.href = url;
        }

        const updateViewingRangeForSafety = (idx) => {
            const url = "/api/administrator/updateSetting";

            isProcessing.value = true;

            let setting_kubun;
            switch(idx){
                case 1:
                    setting_kubun = 9200;
                    break;
                default:
                    setting_kubun = 0;
            }

            axios.post(url, {
                corp_id: props.corp_info.id,
                setting_kbn: setting_kubun,
                setting_val: viewing_range_for_safety.value,
            }).then(res=> {
                if(idx === 1){
                    visibleFlashMessage("登録しました。", "success");
                }
            }).catch(err=>{

            }).finally(fin=>{
                isProcessing.value = false;
            });
        }

        const movePage = (url) => {
            location.href = url;
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }


        return {
            setLevel,

            titles,
            rows,

            viewing_range_for_safety,
            isProcessing,

            backMenu,
            backAdminHome,
            updateViewingRangeForSafety,
            movePage,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        viewing_range_for_safety_list: {
            type: Array,
        },
        corp_settings: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
    .cell {
        padding: 8px;
        border: 1px solid #ddd;
        box-sizing: border-box;
    }

    .btn-none {
        color: #3f3f3f;
        background-color: snow;
        border: thin solid #3f3f3f;
        height: 45px;
    }

    .btn-none:hover {
        color: snow;
        background-color: #3f3f3f;
        border: thin solid #3f3f3f;
        height: 45px;
    }

    #admin_yomikae {
        background-image: url('../../../../svg/administrator_button/admin_yomikae.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

</style>