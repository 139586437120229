<template>
    <div>
        <div class="row">
            <div class="col-md-8">
                <div class="heading-orange mt-3">
                    団体会員専用ページ
                </div>
                <levelIcon
                    :set_level="setLevel"
                />
            </div>
            <div class="col-md-4 mt-4">
                <button class="btn btn-bcp-restrictions py-2 me-3" @click="backMenu"><i class="bi bi-gear"></i><span class="ms-3">マイページ</span></button>
                <input type="button" class="btn btn-black px-4 py-2" value="前ページへ戻る" @click="backList">
            </div>
        </div>
        <div class="row mt-3 my-5">
            <table class="table table-bordered bg-light">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>請求日</th>
                        <th>請求額</th>
                        <th>年会費</th>
                        <th>拠点数</th>
                        <th>従量課金</th>
                        <th>従業員数</th>
                        <th>従量課金</th>
                        <th>家族数</th>
                        <th>従量課金</th>
                        <th>LINE</th>
                        <th>従量課金</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="bill in bill_list" :key="bill.id">
                        <td>{{  bill.id }}</td>
                        <td>{{  bill.請求日 }}</td>
                        <td class="text-end">{{  number_format(bill.請求額) }}</td>
                        <td class="text-end">{{  number_format(bill.請求年会費) }}</td>
                        <td class="text-end">{{  number_format(bill.請求支店数) }}</td>
                        <td class="text-end">{{  number_format(bill.請求支店従量課金) }}</td>
                        <td class="text-end">{{  number_format(bill.請求従業員数) }}</td>
                        <td class="text-end">{{  number_format(bill.請求従業員従量課金) }}</td>
                        <td class="text-end">{{  number_format(bill.請求家族数) }}</td>
                        <td class="text-end">{{  number_format(bill.請求家族従量課金) }}</td>
                        <td class="text-end">{{  number_format(bill.請求LINE連携) }}</td>
                        <td class="text-end">{{  number_format(bill.請求LINE連携従量課金) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import levelIcon from '../../common/UserLevelIconsComponent.vue';
import { inject, ref } from 'vue';
import { CommonNumberFormat } from '../../../function/CommonNumberFormat.js';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('111111');

        const backMenu = () => {
            const url = '/al/myPage/home';

            location.href = url;
        }

        const backList = () => {
            const url = "/al/administrator/show_group_member";

            location.href = url;
        }

        const number_format = (amount) => {
            return CommonNumberFormat(amount);
        }

        return {
            setLevel,

            backMenu,
            backList,
            number_format,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        bill_list: {
            type: Array,
        },
    }
}
</script>
