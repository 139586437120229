<template>
    <div id="change-paid-modal" v-show="modalVisible" @click.self="closeModal">
        <div id="content">
            <div class="row">
                <label class="col-form-label">これ以降のページを利用される場合は、<br />有償会員に切り替える必要がございます。</label>
            </div>
            <div class="row mt-3">
                <a href="#" @click.prevent.stop="changePaid" class="link-orange">➡ 有償会員切替ページへ</a>
            </div>
        </div> 
    </div>
    <div class="row">
        <div class="h5 heading-orange mt-3 col-md-8">
            管理者専用
        </div>
        <div class="col-md-4 mt-3">
            <button class="btn btn-bcp-restrictions py-2 me-3" @click="backMenu"><i class="bi bi-gear"></i><span class="ms-3">マイページ</span></button>
            <input type="button" class="btn btn-black px-4 py-2" value="前ページへ戻る" @click="backMenu">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row mt-5">
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showManageMemberInfo">
                        <div class="d-flex fill align-middle">
                            <i id="admin_kaiin"></i>
                            <span class="ps-3 flex-fill text-start">会員情報の管理</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>会員情報、従量課金情報の確認・変更を行います。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showPaymentStatus">
                        <div class="d-flex fill">
                            <i id="admin_seikyu"></i>
                            <span class="ps-3 flex-fill text-start">請求書・領収書</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>TOTONO-Lの利用に係る請求書及び領収書をダウンロードすることができます。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div>
            <!-- <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showMatchingWord">
                        <div class="d-flex fill">
                            <i id="admin_word"></i>
                            <span class="ps-3 flex-fill text-start">マッチングワード登録</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>B&B災害応援協定ビジネスマッチング用の検索キーワードを登録・変更します。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div> -->
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showCorpInfoSettings">
                        <div class="d-flex fill align-middle">
                            <i id="admin_soshiki-toroku"></i>
                            <span class="ps-3 flex-fill text-start">{{ callReplaceWord('組織') }}情報登録</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>利用者、拠点・支店、マッチングキーワードなどの登録を行います。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div>
            <!-- <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showUserList">
                        <div class="d-flex fill align-middle">
                            <i id="admin_riyosya"></i>
                            <span class="ps-3 flex-fill text-start">利用者登録</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>役員・従業員の登録、利用者IDの発行、利用者ごとの権限を登録します。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div> -->
        </div>
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100">
                        <div class="d-flex fill">
                            <i id="admin_chat"></i>
                            <span class="ps-3 flex-fill text-start">有人チャット予約</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>ヒントやチャットボットではわかりづらい内容は、直接TOTONO-Lスタッフがお応えします。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showSystemSettings">
                        <div class="d-flex fill align-middle">
                            <i id="admin_system-setting"></i>
                            <span class="ps-3 flex-fill text-start">システム基本設定</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>読替登録や閲覧範囲などシステムの基本設定を行います。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div>
            <!-- <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showRegisterBranch">
                        <div class="d-flex fill align-middle">
                            <i id="admin_kyoten"></i>
                            <span class="ps-3 flex-fill text-start">拠点・支店登録</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>BCP策定の対象となる事業所を登録します。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div> -->
        </div>
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showWithdrawal">
                        <div class="d-flex fill">
                            <i id="admin_taikai"></i>
                            <span class="ps-3 flex-fill text-start">退会予告手続</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>TOTONO-Lを退会し本サービスの利用を終了します。毎年9月末が退会月となります。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row mt-5">
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showReplacement">
                        <div class="d-flex fill align-middle">
                            <i id="admin_yomikae"></i>
                            <span class="ps-3 flex-fill text-start">読替え登録</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>サイト内の規定の呼称を、自己・自社の呼称に合わせて読み替えます。（例：「代表」という呼称を、「店主」「校長」「理事長」等任意の呼称に読み替え）</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div>
        </div>
        <div class="col-md-5">
        </div>
    </div> -->
    <div class="row mt-5">
        <div class="col-md-5">
            <div class="row">
            </div>
        </div>
        <div class="col-md-5">
            <div class="row" v-show="corp_info.会員種別 === '団体会員'">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showForGroupMembersOnly">
                        <div class="d-flex fill">
                            <i id="admin_group"></i>
                            <span class="ps-3 flex-fill text-start">団体会員専用ページ</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row mt-5">
        <div class="col-md-4 cursor-pointer" @click="showManageMemberInfo">
            <i id="k_kaiin" />
        </div>
        <div class="col-md-4 cursor-pointer" @click="showRegisterBranch">
            <i id="k_kyoten" />
        </div>
        <div class="col-md-4 cursor-pointer" @click="showUserList">
            <i id="k_riyosya" />
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-4 cursor-pointer" @click="showReplacement">
            <i id="k_yomikae" />
        </div>
        <div class="col-md-4 cursor-pointer" @click="showMatchingWord">
            <i id="k_matchingword" />
        </div>
        <div class="col-md-4 cursor-pointer">
            <i id="k_chatyoyaku" />
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-4 cursor-pointer" @click="showPaymentStatus">
            <i id="k_cyohyo" />
        </div> -->
        <!-- <div class="col-md-4 cursor-pointer" @click="showRenewMembership">
            <i id="k_koushin" />
        </div> -->
        <!-- <div class="col-md-4 cursor-pointer" @click="showWithdrawal">
            <i id="k_taikaiyokoku" />
        </div>
    </div> -->
    <div class="row mt-md-5 pb-md-5">
        <div class="col-sm-4" v-show="judgeFreeMember()">
            <div class="card text-center cursor-pointer" @click="changePaid">
                <div class="card-header bg-resilience-orange">
                    <i id="paid_member" class="paid_member_image" />
                    <div class="h3 text-light">有償会員切替</div>
                </div>
                <div class="card-footer bg-light border-orange">
                    <div class="h4 color-resilience-orange">
                        登録する
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('111111');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list);
        }

        const showReplacement = () => {
            const url = '/al/administrator/replacement';

            location.href = url;
        }

        const showManageMemberInfo = () => {
            const url = '/al/administrator/manage_member_info';

            location.href = url;
        }

        const showSubmissionUsageContract = () => {
            const url = '/al/administrator/submission_usage_contract';

            location.href = url;
        }

        const showRegisterBranch = () => {
            const url = '/al/administrator/register_branch';

            location.href = url;
        }

        const showUserList = () => {
            const url = '/al/administrator/show_user_list';

            location.href = url;
        }

        const showForGroupMembersOnly = () => {
            const url = '/al/administrator/show_group_member';

            location.href = url;
        }

        const showSystemSettings = () => {
            const url = "/al/administrator/show_system_settings";

            location.href = url;
        }

        const showCorpInfoSettings = () => {
            const url = "/al/administrator/show_corp_info_settings";
            location.href = url;
        }

        const judgeFreeMember = () => {
            if(props.corp_info.会員種別.indexOf('無償') != -1){
                return true;
            }
            return false;
        }

        const showMatchingWord = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }

            const url = '/al/administrator/register_matching_word';

            location.href = url;
        }

        // const showRenewMembership = () => {
        //     if(judgeFreeMember()){
        //         modalVisible.value = true;
        //         return false;
        //     }
            
        //     const url = '/al/administrator/renew_membership';

        //     location.href = url;
        // }

        const showWithdrawal = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }

            const url = '/al/administrator/withdrawal';

            location.href = url;
        }

        const changePaid = () => {
            const url = '/al/administrator/switchPaidMember';

            location.href = url;
        }
        
        const showPaymentStatus = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/al/administrator/showPaymentStatus';

            location.href = url;
        }

        const modalVisible = ref(false);

        const closeModal = () => {
            modalVisible.value = false;
        }

        const backMenu = () => {
            const url = '/al/myPage/home';

            location.href = url;
        }

        return {
            setLevel,
            showReplacement,
            showManageMemberInfo,
            showSubmissionUsageContract,
            showRegisterBranch,
            showUserList,
            showMatchingWord,
            // showRenewMembership,
            showWithdrawal,
            changePaid,
            showPaymentStatus,
            showForGroupMembersOnly,
            showSystemSettings,
            showCorpInfoSettings,

            judgeFreeMember,

            modalVisible,
            closeModal,

            backMenu,

            callReplaceWord,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
    #change-paid-modal{
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #content {
        z-index: 2;
        width: 30%;
        padding: 5em;
        background: #fff;
    }

    .btn-none {
        color: #3f3f3f;
        background-color: snow;
        border: thin solid #3f3f3f;
        height: 45px;
    }

    .btn-none:hover {
        color: snow;
        background-color: #3f3f3f;
        border: thin solid #3f3f3f;
        height: 45px;
    }

    #admin_kaiin {
        background-image: url('../../../../svg/administrator_button/admin_kaiin.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

    #admin_word {
        background-image: url('../../../../svg/administrator_button/admin_word.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

    #admin_chat {
        background-image: url('../../../../svg/administrator_button/admin_chat.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

    #admin_kyoten {
        background-image: url('../../../../svg/administrator_button/admin_kyoten.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

    #admin_riyosya {
        background-image: url('../../../../svg/administrator_button/admin_riyosya.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

    #admin_seikyu {
        background-image: url('../../../../svg/administrator_button/admin_seikyu-ryosyu.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

    #admin_taikai {
        background-image: url('../../../../svg/administrator_button/admin_taikai.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

    #admin_yomikae {
        background-image: url('../../../../svg/administrator_button/admin_yomikae.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

    #admin_group {
        background-image: url('../../../../svg/administrator_button/admin_people.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

    #admin_soshiki-toroku {
        background-image: url('../../../../svg/administrator_button/admin_soshiki-toroku.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

    #admin_system-setting {
        background-image: url('../../../../svg/administrator_button/admin_system-setting.svg');
        display: inline-flex;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: no-repeat;
    }

#k_kaiin {
    background-image: url('../../../../svg/administrator_button/k_kaiin.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#k_kyoten {
    background-image: url('../../../../svg/administrator_button/k_kyoten.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#k_riyosya {
    background-image: url('../../../../svg/administrator_button/k_riyosya.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#k_yomikae {
    background-image: url('../../../../svg/administrator_button/k_yomikae.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#k_matchingword {
    background-image: url('../../../../svg/administrator_button/k_matchingword.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#k_chatyoyaku {
    background-image: url('../../../../svg/administrator_button/k_chatyoyaku.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

/**
#k_koushin {
    background-image: url('../../../../svg/administrator_button/k_koushin.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}
*/

#k_taikaiyokoku {
    background-image: url('../../../../svg/administrator_button/k_taikaiyokoku.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#k_cyohyo {
    background-image: url('../../../../svg/administrator_button/k_seikyusho.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

  .link-orange,
  .link-orange:link,
  .link-orange:visited,
  .link-orange:hover,
  .link-orange:active{
    color: #Ff6600;
  }

</style>