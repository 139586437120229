<template>
    <div id="top">
    </div>
    <div class="container" v-if="showMode === 3">
        <div class="heading mt-3">有償会員登録</div>
        <div class="ms-3 my-3 d-flex flex-row border-bottom border-dark">
            <div class="col-form-label color-resilience-gray">1.仮登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">2.仮登録完了・メール送信</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-orange-80 fw-bold">3.有償会員登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">4.利用規約・有償会員規約への同意</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">5.入力内容の確認</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">6.年会費・従量課金支払い</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">7.本登録完了</div>
        </div>
        <div class="row my-5">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">組織・企業の情報</div>
        </div>
        <div class="row mt-1">
            <div class="offset-md-1 col-md-8">
                <table class="table table-bordered bg-light">
                    <tbody>
                        <tr>
                            <td colspan="2">事業形態</td>
                            <td>{{ temp_corp_info.事業形態名 }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">法人名／屋号</td>
                            <td>{{ temp_corp_info.組織名 }}</td>
                        </tr>
                        <tr>
                            <td rowspan="4" class="align-middle">所在地</td>
                            <td>郵便番号</td>
                            <td>{{ label郵便番号 }}</td>
                        </tr>
                        <tr>
                            <td>都道府県</td>
                            <td>{{ temp_corp_info.都道府県 }}</td>
                        </tr>
                        <tr>
                            <td>市区町村</td>
                            <td>{{ temp_corp_info.市区町村 }}</td>
                        </tr>
                        <tr>
                            <td>町名番地、ビル名等</td>
                            <td>{{ temp_corp_info.町名番地 }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <label class="col-md-2 col-form-label">代表電話番号<span class="badge bg-resilience-red ms-3">必須</span></label>
                    
                    <div class="col-md-6 me-5">
                        <div class="row">
                            <div class="col-2">
                                <input type="text" class="form-control" v-model="input電話番号1" required maxlength="10" :class="{'is-invalid': has電話番号_Error}" />
                                <div class="invalid-feedback">{{ 電話番号_エラーメッセージ }}</div>
                                <div class="form-text">半角数字</div>
                            </div>
                            <label class="col-1 col-form-label">-</label>
                            <div class="col-2">
                                <input type="text" class="form-control" v-model="input電話番号2" required maxlength="10" :class="{'is-invalid': has電話番号_Error}" />
                            </div>
                            <label class="col-1 col-form-label">-</label>
                            <div class="col-2">
                                <input type="text" class="form-control" v-model="input電話番号3" required maxlength="10" :class="{'is-invalid': has電話番号_Error}" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <label class="col-md-1 col-form-label">代表者</label>
                    <label class="col-md-2 col-form-label">氏名<span class="badge bg-resilience-red ms-3">必須</span></label>
                    <div class="col-md-8 me-5">
                        <div class="row">
                            <label class="col-form-label col-1">（姓）</label>
                            <div class="col-4">
                                <input type="text" class="form-control" id='representative_family_name' v-model="input代表者名_姓" required @input="handleRepresentativeFamilyNameInput" maxlength="255" :class="{'is-invalid': has代表者名_姓_Error}">
                                <div class="invalid-feedback">{{ 代表者名_姓_エラーメッセージ }}</div>
                            </div>
                            <label class="col-form-label col-1">（名）</label>
                            <div class="col-4">
                                <input type="text" class="form-control" id='representative_first_name' v-model="input代表者名_名" required @input="handleRepresentativeFirstNameInput" maxlength="255" :class="{'is-invalid': has代表者名_名_Error}">
                                <div class="invalid-feedback">{{ 代表者名_名_エラーメッセージ }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <label class="col-md-1 col-form-label"></label>
                    <label class="col-md-2 col-form-label">フリガナ<span class="badge bg-resilience-red ms-3">必須</span></label>
                    <div class="col-md-8 me-5">
                        <div class="row">
                            <label class="col-form-label col-1">（姓）</label>
                            <div class="col-4">
                                <input type="text" class="form-control" id='representative_family_name_kana' v-model="input代表者名_姓_カナ" required maxlength="255" :class="{'is-invalid': has代表者名_姓_カナ_Error}" />
                                <div class="invalid-feedback">{{ 代表者名_姓_カナ_エラーメッセージ }}</div>
                            </div>
                            <label class="col-form-label col-1">（名）</label>
                            <div class="col-4">
                                <input type="text" class="form-control" id='representative_first_name_kana' v-model="input代表者名_名_カナ" required maxlength="255" :class="{'is-invalid': has代表者名_名_カナ_Error}" />
                                <div class="invalid-feedback">{{ 代表者名_名_カナ_エラーメッセージ }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <label class="col-md-1 col-form-label"></label>
                    <label class="col-md-2 col-form-label">役職<span class="badge bg-resilience-red ms-3">必須</span></label>
                    <div class="col-md-8 me-5">
                        <div class="row">
                            <div class="col-4 offset-1">
                                <input type="text" class="form-control" id='representative_post' v-model="input代表者_役職" required maxlength="255" :class="{'is-invalid': has代表者_役職_Error}" />
                                <div class="invalid-feedback">{{ 代表者_役職_エラーメッセージ }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1 col-md-8">
                <table class="table table-bordered bg-light">
                    <tbody>
                        <tr>
                            <td rowspan="6" class="align-middle text-nowrap">管理者</td>
                            <td >氏名</td>
                            <td>{{ temp_corp_info.管理者名_姓 }}</td>
                            <td>{{ temp_corp_info.管理者名_名 }}</td>
                        </tr>
                        <tr>
                            <td class="col-form-label text-nowrap">フリガナ</td>
                            <td colspan="2">
                                <div class="row">
                                    <label class="col-1 col-form-label">姓</label>
                                    <div class="col-5">
                                        <input type="text" class="form-control" v-model="input管理者名_姓_カナ" required maxlength="255" :class="{'is-invalid': has管理者名_姓_カナ_Error}" />
                                        <div class="invalid-feedback">{{ 管理者名_姓_カナ_エラーメッセージ }}</div>
                                    </div>
                                    <label class="col-1 col-form-label">名</label>
                                    <div class="col-5">
                                        <input type="text" class="form-control" v-model="input管理者名_名_カナ" required maxlength="255" :class="{'is-invalid': has管理者名_名_カナ_Error}" />
                                        <div class="invalid-feedback">{{ 管理者名_名_カナ_エラーメッセージ }}</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-nowrap">部署・役職</td>
                            <td>{{ temp_corp_info.管理者_所属部署 }}</td>
                            <td>{{ temp_corp_info.管理者_役職 }}</td>
                        </tr>
                        <tr>
                            <td class="col-form-label text-nowrap">電話番号</td>
                            <td colspan="2">
                                <div class="row">
                                    <div class="col-2">
                                        <input type="text" class="form-control" v-model="input管理者_電話番号1" required maxlength="10" :class="{'is-invalid': has管理者電話番号_Error}" />
                                        <div class="invalid-feedback">{{ 管理者電話番号_エラーメッセージ }}</div>
                                    </div>
                                    <label class="col-1 col-form-label">-</label>
                                    <div class="col-2">
                                        <input type="text" class="form-control" v-model="input管理者_電話番号2" required maxlength="10" :class="{'is-invalid': has管理者電話番号_Error}" />
                                    </div>
                                    <label class="col-1 col-form-label">-</label>
                                    <div class="col-2">
                                        <input type="text" class="form-control" v-model="input管理者_電話番号3" required maxlength="10" :class="{'is-invalid': has管理者電話番号_Error}" />
                                    </div>
                                    <div class="col-2">
                                        <input type="button" value="代表電話番号と同じ" class="btn btn-black" @click="sameTel">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-nowrap">メールアドレス</td>
                            <td colspan="2">{{ temp_corp_info.管理者_メールアドレス }}</td>
                        </tr>
                        <tr>
                            <td class="text-nowrap">
                                <div class="row">
                                    メルマガ登録<div class="ms-1"><span class="badge bg-resilience-red">必須</span>
                                </div>
                            </div></td>
                            <td colspan="2">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" class="form-check-input" v-model="inputメルマガ登録" id='メルマガ登録あり' value="あり" />
                                            <label for="メルマガ登録あり" class="form-check-label">登録する</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" class="form-check-input" v-model="inputメルマガ登録" id='メルマガ登録なし' value="しない" />
                                            <label for="メルマガ登録なし" class="form-check-label">登録しない</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <VDropdown
                                            theme="hint-theme"
                                        >
                                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                            <template #popper>
                                                <div class="row">
                                                    <label>{{ msg_1 }}</label>
                                                </div>
                                            </template>
                                        </VDropdown>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <label class="col-md-2 col-form-label">業種<br />（日本標準産業分類）</label>
                    <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                    <label class="col-md-2 col-form-label">大分類（A～T）</label>
                    <div class="col-md-3">
                        <select name="業種_大分類" class="form-select" v-model="業種_大分類" required @change="changeLargeClass" :class="{'is-invalid': has業種_Error}">
                            <option type="text" v-for="large in japan_standard_industrial_large_list" :key="large.id" :value="large.大分類">{{ large.大分類 + ':' + large.項目名 }}</option>
                        </select>
                    </div>
                    <div class="col-md-1">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>総務省日本標準産業分類（平成26年4月1日施行）</label>
                                </div>
                                <div class="row">
                                    <a href="https://www.soumu.go.jp/toukei_toukatsu/index/seido/sangyo/02toukatsu01_03000023.html" target="_blank">https://www.soumu.go.jp/toukei_toukatsu/index/seido/sangyo/02toukatsu01_03000023.html</a>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                    <label class="col-md-2 col-form-label">中分類（2桁）</label>
                    <div class="col-md-3">
                        <select name="業種_中分類" class="form-select" v-model="業種_中分類" required @change="changeMiddleClass" :class="{'is-invalid': has業種_Error}">
                            <option type="text" v-for="middle in middle_class_list" :key="middle.id" :value="middle.中分類">{{ middle.中分類 + ':' + middle.項目名 }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                    <label class="col-md-2 col-form-label">小分類（3桁）</label>
                    <div class="col-md-3">
                        <select name="業種_中分類" class="form-select" v-model="業種_小分類" required @change="changeMinorClass" :class="{'is-invalid': has業種_Error}">
                            <option type="text" v-for="minor in minor_class_list" :key="minor.id" :value="minor.小分類">{{ minor.小分類 + ':' + minor.項目名 }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                    <label class="col-md-2 col-form-label">細分類（4桁）</label>
                    <div class="col-md-3">
                        <select name="業種_中分類" class="form-select" v-model="業種_細分類" required @change="changeSubClass" :class="{'is-invalid': has業種_Error}">
                            <option type="text" v-for="sub in sub_class_list" :key="sub.id" :value="sub.細分類">{{ sub.細分類 + ':' + sub.項目名 }}</option>
                        </select>
                        <div class="invalid-feedback">{{ 業種_エラーメッセージ }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-2"><i class="bi bi-caret-right-fill"></i><label class="col-form-label">従課金情報登録</label></div>
                    <div class="col-md-1 text-end"><label class="col-form-label">1</label></div>
                    <label class="col-md-3 col-form-label">登録する拠点・支店の数</label>
                    <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                    <div class="col-md-2">
                        <div class="row">
                            <div class="col-8">
                                <input type="number" class="form-control text-end" v-model="input支店数" required max="65535" :class="{'is-invalid': has支店数_Error}">
                                <div class="invalid-feedback">{{ 支店数_エラーメッセージ }}</div>
                            </div>
                            <label class="col-4 col-form-label">ヶ所</label>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <input type="button" class="btn btn-black" :disabled="isProcessing" value="料金プラン" @click="showPrice" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-1 text-end"><label class="col-form-label">2</label></div>
                    <label class="col-md-3 col-form-label">登録する利用者（役員・従業員）の数</label>
                    <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                    <div class="col-md-2">
                        <div class="row">
                            <div class="col-8">
                                <input type="number" class="form-control text-end" v-model="input従業員数" required max="4294967295" :class="{'is-invalid': has従業員数_Error}">
                                <div class="invalid-feedback">{{ 従業員数_エラーメッセージ }}</div>
                            </div>
                            <label class="col-4 col-form-label">人</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-1 text-end"><label class="col-form-label">3</label></div>
                    <label class="col-md-3 col-form-label">登録する利用者家族の数</label>
                    <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                    <div class="col-md-2">
                        <div class="row">
                            <div class="col-8">
                                <input type="number" class="form-control text-end" v-model="input家族数" required max="4294967295" :class="{'is-invalid': has家族数_Error}">
                                <div class="invalid-feedback">{{ 家族数_エラーメッセージ }}</div>
                            </div>
                            <label class="col-4 col-form-label">人</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3" v-if="showLINE === 'true'">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-1 text-end"><label class="col-form-label">4</label></div>
                    <label class="col-md-3 col-form-label">LINE連携オプション</label>
                    <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                    <div class="col-md-2">
                        <div class="row">
                            <div class="col-8">
                                <select class="form-select" v-model="selectLINE連携オプション">
                                    <option value="1">はい</option>
                                    <option value="0">いいえ</option>
                                </select>
                                <div class="invalid-feedback">{{ LINE連携オプション_エラーメッセージ }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-2">
                        <label class="col-form-label">有償会員の種類</label>
                    </div>
                    <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                    <div class="col-md-8 me-5">
                        <div class="form-check mt-2">
                            <input class="form-check-input" type="radio" v-model="input会員種別" id='企業会員' value="企業会員" :class="{'is-invalid': has会員種別_Error}" />
                            <label for="企業会員" class="form-check-label">企業会員<br />（団体以外の法人、個人事業主はこちらをご選択ください。）</label>
                        </div>
                        <div class="form-check mt-3">
                            <input class="form-check-input" type="radio" v-model="input会員種別" id='団体会員' value="団体会員" :class="{'is-invalid': has会員種別_Error}" />
                            <label for="団体会員" class="form-check-label">団体会員<br />（会員等を有する同業団体や組合等はこちらをご選択ください。）</label>
                        </div>
                        <div class="form-check mt-3">
                            <input class="form-check-input" type="radio" v-model="input会員種別" id='公共会員' value="公共会員" :class="{'is-invalid': has会員種別_Error}" />
                            <label for="公共会員" class="form-check-label">公共会員<br />（地方自治体や国公立学校等はこちらをご選択ください。）</label>
                        </div>
                        <div class="form-check mt-3">
                            <div class="row">
                                <div class="col-md-4">
                                    <input class="form-check-input" type="radio" v-model="input会員種別" id='団体所属会員' value="団体所属会員" :class="{'is-invalid': has会員種別_Error}" />
                                    <label for="団体所属会員" class="form-check-label">団体所属会員</label>
                                </div>
                                <div class="col-md-3"><i class="bi bi-caret-right-fill"></i><label class="col-form-label">所属する団体会員コード</label></div>
                                <div class="col-md-3">
                                    <input class="form-control" type="text" v-model="input所属する団体会員コード" maxlength="17" :class="{'is-invalid': has所属する団体会員コード_Error}" />
                                    <div class="invalid-feedback">{{ 所属する団体会員コード_エラーメッセージ }}</div>
                                </div>
                                <div class="col-md-2">
                                    <VDropdown
                                        theme="hint-theme"
                                    >
                                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                        <template #popper>
                                            <div class="row">
                                                <label>所属している団体会員のコードを入力してください。</label>
                                            </div>
                                        </template>
                                    </VDropdown>
                                </div>
                            </div>
                            <div class="row">
                                <div class="ms-2">
                                    <label for="団体所属会員" class="form-check-label">（団体会員の会員等はこちらをご選択ください。）</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-check mt-3">
                            <div class="row">
                                <div class="col-md-4">
                                    <input class="form-check-input" type="radio" v-model="input会員種別" id='賛助会員' value="賛助会員" :class="{'is-invalid': has会員種別_Error}" />
                                    <label for="賛助会員" class="form-check-label">賛助会員</label>
                                    <div class="invalid-feedback">{{ 会員種別_エラーメッセージ }}</div>
                                </div>
                                <div class="col-md-3"><i class="bi bi-caret-right-fill"></i><label class="col-form-label">賛助会員コード</label></div>
                                <div class="col-md-3">
                                    <input class="form-control" type="text" v-model="input賛助会員コード" maxlength="17" :class="{'is-invalid': has賛助会員コード_Error}" />
                                    <div class="invalid-feedback">{{ 賛助会員コード_エラーメッセージ }}</div>
                                </div>
                                <div class="col-md-2">
                                    <VDropdown
                                        theme="hint-theme"
                                    >
                                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                        <template #popper>
                                            <div class="row">
                                                <label>当会からお知らせした賛助会員コードを入力してください。</label>
                                            </div>
                                        </template>
                                    </VDropdown>
                                </div>
                            </div>
                            <div class="row">
                                <div class="ms-2">
                                    <label for="賛助会員" class="form-check-label">（当会の事業を支援する個人・団体等はこちらをご選択ください。）</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-5">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="row mx-5 px-5">
                                    <input type="button" class="btn btn-black" value="利用規約、有償規約を確認する" @click="showTermsForPaid" />
                                </div>
                                <div class="row mx-5 px-5 mt-3 justify-content-md-center">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" v-model="isAgree" id="agree" :disabled="!isShowTerms" />
                                        <label class="form-check-label" for="agree">
                                            利用規約、有償会員規約に同意します。
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-5">
                        <label class="col-form-label pe-5">※ 今月から年会費（月割を含む）が発生いたします。</label>
                    </div>
                    <div class="col-md-2">
                        <input type="button" class="btn btn-black" :disabled="isProcessing" value="料金プラン" @click="showPrice" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-5">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-3">
                        <input type="button" class="btn btn-black" value="入力内容を確認する" v-scroll-to="'#top'" @click="postForm" :disabled="isProcessing || isShowTerms === false || isAgree === false" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" v-else-if="showMode === 4">
        <div class="heading mt-3">有償会員登録（入力内容の確認）</div>
        <div class="ms-3 my-3 d-flex flex-row border-bottom border-dark">
            <div class="col-form-label color-resilience-gray">1.仮登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">2.仮登録完了・メール送信</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">3.有償会員登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">4.利用規約・有償会員規約への同意</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-orange-80 fw-bold">5.入力内容の確認</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">6.年会費・従量課金支払い</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">7.本登録完了</div>
        </div>
        <div class="row my-5">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">組織・企業の情報</div>
        </div>
        <div class="row pb-5 border-bottom-red">
            <div class="col-md-8 offset-md-1">
                <table class="table table-bordered bg-light">
                    <tbody>
                        <tr>
                            <td colspan="2">法人名／屋号</td>
                            <td colspan="4">{{ temp_corp_info.組織名 }}</td>
                        </tr>
                        <tr>
                            <td colspan="2" rowspan="2" class="align-middle">所在地</td>
                            <td colspan="4">{{ label郵便番号 }}</td>
                        </tr>
                        <tr>
                            <td colspan="4">{{ temp_corp_info.都道府県 + temp_corp_info.市区町村 + temp_corp_info.町名番地 }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">代表電話番号</td>
                            <td colspan="4">{{ input電話番号1 + '-' + input電話番号2 + '-' + input電話番号3 }}</td>
                        </tr>
                        <tr>
                            <td rowspan="3" class="align-middle">代表者</td>
                            <td>氏名</td>
                            <td colspan="4">{{ input代表者名_姓 + '　' + input代表者名_名 }}</td>
                        </tr>
                        <tr>
                            <td>フリガナ</td>
                            <td colspan="4">{{ input代表者名_姓_カナ + '　' + input代表者名_名_カナ }}</td>
                        </tr>
                        <tr>
                            <td>役職</td>
                            <td colspan="4">{{ input代表者_役職 }}</td>
                        </tr>
                        <tr>
                            <td rowspan="7" class="align-middle">管理者</td>
                            <td>利用者ID</td>
                            <td colspan="4">{{ 利用者ID }}</td>
                        </tr>
                        <tr>
                            <td>氏名</td>
                            <td colspan="4">{{ temp_corp_info.管理者名_姓 + '　' + temp_corp_info.管理者名_名 }}</td>
                        </tr>
                        <tr>
                            <td>フリガナ</td>
                            <td colspan="4">{{ input管理者名_姓_カナ + '　' + input管理者名_名_カナ }}</td>
                        </tr>
                        <tr>
                            <td>部署・役職</td>
                            <td colspan="4">{{ temp_corp_info.管理者_所属部署 + '　' + temp_corp_info.管理者_役職 }}</td>
                        </tr>
                        <tr>
                            <td>電話番号</td>
                            <td colspan="4">{{ input管理者_電話番号1 + '-' + input管理者_電話番号2 + '-' + input管理者_電話番号3 }}</td>
                        </tr>
                        <tr>
                            <td class="text-nowrap">メールアドレス</td>
                            <td colspan="4">{{ temp_corp_info.管理者_メールアドレス }}</td>
                        </tr>
                        <tr>
                            <td>メルマガ登録</td>
                            <td colspan="4">{{ inputメルマガ登録 === 'あり' ? '登録する' : '登録しない' }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">業種（日本標準産業分類）</td>
                            <td colspan="4">{{ 業種_細分類 + '　' + label細分類名 }}</td>
                        </tr>
                        <tr>
                            <td colspan="2" :rowspan="showLINE === 'true' ? 4 : 3">従量課金情報登録</td>
                            <td class="text-center">1</td>
                            <td>登録する拠点・支店の数</td>
                            <td class="text-end">{{ input支店数 }}</td>
                            <td>ヶ所</td>
                        </tr>
                        <tr>
                            <td class="text-center">2</td>
                            <td>登録する利用者（役員・従業員）の人数</td>
                            <td class="text-end">{{ input従業員数 }}</td>
                            <td>人</td>
                        </tr>
                        <tr>
                            <td class="text-center">3</td>
                            <td>登録する利用者家族の人数</td>
                            <td class="text-end">{{ input家族数 }}</td>
                            <td>人</td>
                        </tr>
                        <tr v-if="showLINE === 'true'">
                            <td class="text-center">4</td>
                            <td>LINE連携オプション</td>
                            <td class="text-end">{{ selectLINE連携オプション == "1" ? "はい" : "いいえ" }}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="2">サイトの利用開始日（年会費発生月）</td>
                            <td colspan="4">
                                <div class="row">
                                    <div class="col-3">
                                        {{ this.$root.dayjs().format('YYYY年M月D日') }}
                                    </div>
                                    <div class="col-9">
                                        （{{ this.$root.dayjs().format('YYYY年M月') }}）
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">有償会員の種類</td>
                            <td colspan="4">{{ input会員種別 }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">利用規約、有償会員規約</td>
                            <td colspan="4">{{ isAgree ? '同意する' : '同意しない' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-8 offset-md-1">
                <label>上記の内容でよろしければ、次の手続へお進みください。</label>
            </div>
        </div>
        <div class="row mb-5 mt-3">
            <div class="col-md-10 offset-md-1">
                <input type="button" class="btn btn-inquiry-button" :value="registerButtonMsg" @click="confirm" :disabled="isProcessing" v-scroll-to="'#top'">
                <input type="button" class="btn btn-inquiry-button ms-3" value="前ページに戻って訂正する" @click="backInput" :disabled="isProcessing">
            </div>
        </div>
    </div>
    <div class="container" v-else-if="showMode === 5">
        <div class="heading mt-3">有償会員登録（年会費・従量課金支払い）</div>
        <div class="ms-3 my-3 d-flex flex-row border-bottom border-dark">
            <div class="col-form-label color-resilience-gray">1.仮登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">2.仮登録完了・メール送信</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">3.有償会員登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">4.利用規約・有償会員規約への同意</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">5.入力内容の確認</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-orange-80 fw-bold">6.年会費・従量課金支払い</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">7.本登録完了</div>
        </div>
        <div class="row my-5">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">年会費・従量課金</div>
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-1">
                <table class="table table-bordered bg-light" id="amountTable">
                    <tbody>
                        <tr>
                            <th>{{ 年度 }}年度</th>
                            <td colspan="3">会員種別｜従量課金の内容</td>
                            <td class="text-center">ご請求金額（税込）</td>
                        </tr>
                        <tr>
                            <td>年会費</td>
                            <td colspan="3">
                                <div class="row">
                                    <div class="col-2">
                                        {{ input会員種別 }}
                                    </div>
                                    <div class="col-10">
                                        <label>※期の途中でご加入された場合は、月割りの年会費が適用されます。</label>
                                    </div>
                                </div>
                            </td>
                            <td class="text-end">{{ number_format(年会費) }}円</td>
                        </tr>
                        <tr>
                            <td rowspan="4">従量課金</td>
                            <td>登録する利用者（役員・従業員）の人数</td>
                            <td class="text-end">{{ number_format(input従業員数) }}</td>
                            <td class="text-end">人</td>
                            <td class="text-end">{{ number_format(従量_従業員数) }}円</td>
                        </tr>
                        <tr>
                            <td>登録する拠点・支店の数</td>
                            <td class="text-end">{{ number_format(input支店数) }}</td>
                            <td class="text-end">ヶ所</td>
                            <td class="text-end">{{ number_format(従量_支店数) }}円</td>
                        </tr>
                        <tr>
                            <td>登録する利用者家族の人数</td>
                            <td class="text-end">{{ number_format(input家族数) }}</td>
                            <td class="text-end">人</td>
                            <td class="text-end">{{ number_format(従量_家族数) }}円</td>
                        </tr>
                        <tr v-if="showLINE === 'true'">
                            <td>LINE連携オプション</td>
                            <td class="text-center" colspan="2">{{ selectLINE連携オプション == "1" ? "あり" : "なし" }}</td>
                            <td class="text-end">{{ number_format(従量_LINE) }}円</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-3">
                <input type="button" class="btn btn-black" value="料金プラン" @click="showPrice" />
            </div>
        </div>
        <div class="row pb-5">
            <div class="col-md-3 offset-md-1">
                <table class="table table-bordered bg-light">
                    <tbody>
                        <tr>
                            <td class="text-center">合計</td>
                            <td class="text-end">{{ number_format(合計額) }}円（税込）</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row my-3" v-show="input会員種別 != '団体所属会員'">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">お支払方法の選択</div>
        </div>
        <div class="row" v-show="input会員種別 != '団体所属会員'">
            <div class="col-md-8 offset-md-1">
                <table class="table table-bordered bg-light">
                    <tbody>
                        <tr>
                            <td>
                                <input type="radio" class="form-check-input" v-model="支払方法" value="10050001" id="クレジット決済" />
                            </td>
                            <td>
                                <label class="form-check-label" for="クレジット決済">クレジット決済</label>
                            </td>
                            <td>
                                <label class="form-check-label" for="クレジット決済">※ クレジット決済専用ページに移動します。</label>
                            </td>
                        </tr>
                        <tr v-if="showConvenientPayFlg">
                            <td>
                                <input type="radio" class="form-check-input" v-model="支払方法" value="10050002" id="コンビニ払い" />
                            </td>
                            <td>
                                <label class="form-check-label" for="コンビニ払い">コンビニ払い</label>
                            </td>
                            <td>
                                <label class="form-check-label" for="コンビニ払い">※ コンビニ決済専用ページに移動します。</label>
                            </td>
                        </tr>
                        <tr v-show="支払方法=='10050002'">
                            <td colspan="3">
                                <p>・以下のコンビニエンスストアでお支払いできます。</p>
                                <p>・コンビニ決済専用ページに移動後、氏名、メールアドレス等必要な情報を入力してください。<br />※お客様情報の氏名は必ずご登録者ご本人のお名前をご記入ください。</p>
                                <p>・指定コンビニでの支払い番号が完了画面、もしくは確認メールに表示されます。</p>
                                <p>・ご入金の確認後、TOTONO-L事務局よりメールにて連絡いたします。</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="radio" class="form-check-input" v-model="支払方法" value="10050003" id="口座振替" />
                            </td>
                            <td>
                                <label class="form-check-label" for="口座振替">口座振替</label>
                            </td>
                            <td>
                                <label class="form-check-label" for="口座振替"></label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- <label class="form-label font-s-075">※コンビニ払い、銀行振込は支払確認後に利用可能となります。</label>
                <label class="form-label font-s-075">※金融機関営業時間外にお振込された場合、入金確認まで時間がかかりますので、ご注意ください。</label> -->
            </div>
            <div class="col-md-3 d-flex align-items-end">
            </div>
        </div>
        <div class="row my-3" v-show="input会員種別 != '団体所属会員'">
            <div class="col-md-8 offset-md-1">
                <div class="d-flex justify-content-center" v-if="支払方法=='10050001'">
                    <form id="form_credit" :action="densan_url" method="post" @submit.prevent="getOrderID('payCredit')" ref="submitCredit" accept-charset='shift_jis'>
                        <input type="hidden" name="pay_method" :value="sb_pay_method" />
                        <input type="hidden" name="merchant_id" :value="sb_merchant_id" />
                        <input type="hidden" name="service_id" :value="sb_service_id">
                        <input type="hidden" name="cust_code" :value="sb_cust_code">
                        <input type="hidden" name="order_id" :value="sb_order_id">
                        <input type="hidden" name="item_id" :value="sb_item_id">
                        <input type="hidden" name="item_name" :value="sb_item_name">
                        <input type="hidden" name="amount" :value="sb_amount">
                        <input type="hidden" name="pay_type" :value="sb_pay_type">
                        <input type="hidden" name="service_type" :value="sb_service_type">
                        <input type="hidden" name="terminal_type" :value="sb_terminal_type">
                        <!--商品明細行繰り返し start -->
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_1">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_1">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_1">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_1">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_1">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_2">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_2">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_2">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_2">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_2">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_3">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_3">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_3">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_3">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_3">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_4">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_4">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_4">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_4">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_4">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_5">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_5">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_5">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_5">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_5">
                        <!--商品明細行繰り返し end -->

                        <input type="hidden" name="success_url" :value="sb_success_url">
                        <input type="hidden" name="cancel_url" :value="sb_cancel_url">
                        <input type="hidden" name="error_url" :value="sb_error_url">
                        <input type="hidden" name="pagecon_url" :value="sb_pagecon_url">
                        <!-- <input type="hidden" name="free_csv" value="TEFTVF9OQU1FPemItOacqCxGSVJTVF9OQU1FPeWkqumDjixMQVNUX05BTUVfS0FOQT3jgrnjgrrjgq0sRklSU1RfTkFNRV9LQU5BPeOCv+ODreOCpixGSVJTVF9aSVA9MjEwLFNFQ09ORF9aSVA9MDAwMSxBREQxPeWykOmYnOecjCxBREQyPeOBguOBguOBguW4guOBguOBguOBgueUuixBREQzPSxURUw9MTIzNDU2Nzk4MDEsTUFJTD1hYWFhQGJiLmpwLElURU1fTkFNRT1URVNUIElURU0="> -->
                        <input type="hidden" name="request_date" :value="sb_request_date">
                        <input type="hidden" name="hashkey" :value="sb_hashkey">
                        <input type="hidden" name="sps_hashcode" :value="sb_sps_hashcode">
                        <!-- <input type="hidden" name="dtl_rowno" value="1"> -->
                        <!-- <input type="hidden" name="dtl_item_id" value="dtlItem_1"> -->
                        <!-- <input type="hidden" name="dtl_item_name" value="商品詳細１"> -->
                        <!-- <input type="hidden" name="dtl_item_count" value="1"> -->
                        <!-- <input type="hidden" name="dtl_tax" value="1"> -->
                        <!-- <input type="hidden" name="dtl_amount" value="1"> -->
                        <input type="submit" class="btn btn-black px-5" value="クレジット払い" :disabled="isProcessing" >
                    </form>
                    <!-- <button type="button" class="btn btn-black py-3 px-5"><i class="bi bi-piggy-bank-fill"></i>上記金額を支払う</button> -->
                </div>
                <div class="d-flex justify-content-center" v-else-if="支払方法=='10050002'">
                    <form id="form_convenience" :action="densan_url" method="post" @submit.prevent="getOrderID('payConvenience')" ref="submitConvenience" accept-charset='shift_jis'>
                        <input type="hidden" name="pay_method" :value="sb_pay_method" />
                        <input type="hidden" name="merchant_id" :value="sb_merchant_id" />
                        <input type="hidden" name="service_id" :value="sb_service_id">
                        <input type="hidden" name="cust_code" :value="sb_cust_code">
                        <input type="hidden" name="order_id" :value="sb_order_id">
                        <input type="hidden" name="item_id" :value="sb_item_id">
                        <input type="hidden" name="item_name" :value="sb_item_name">
                        <input type="hidden" name="amount" :value="sb_amount">
                        <input type="hidden" name="pay_type" :value="sb_pay_type">
                        <input type="hidden" name="service_type" :value="sb_service_type">
                        <input type="hidden" name="terminal_type" :value="sb_terminal_type">
                        <!--商品明細行繰り返し start -->
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_1">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_1">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_1">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_1">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_1">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_2">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_2">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_2">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_2">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_2">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_3">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_3">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_3">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_3">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_3">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_4">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_4">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_4">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_4">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_4">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_5">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_5">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_5">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_5">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_4">
                        <!--商品明細行繰り返し end -->

                        <input type="hidden" name="success_url" :value="sb_success_url">
                        <input type="hidden" name="cancel_url" :value="sb_cancel_url">
                        <input type="hidden" name="error_url" :value="sb_error_url">
                        <input type="hidden" name="pagecon_url" :value="sb_pagecon_url">
                        <!-- <input type="hidden" name="free_csv" value="TEFTVF9OQU1FPemItOacqCxGSVJTVF9OQU1FPeWkqumDjixMQVNUX05BTUVfS0FOQT3jgrnjgrrjgq0sRklSU1RfTkFNRV9LQU5BPeOCv+ODreOCpixGSVJTVF9aSVA9MjEwLFNFQ09ORF9aSVA9MDAwMSxBREQxPeWykOmYnOecjCxBREQyPeOBguOBguOBguW4guOBguOBguOBgueUuixBREQzPSxURUw9MTIzNDU2Nzk4MDEsTUFJTD1hYWFhQGJiLmpwLElURU1fTkFNRT1URVNUIElURU0="> -->
                        <input type="hidden" name="request_date" :value="sb_request_date">
                        <input type="hidden" name="hashkey" :value="sb_hashkey">
                        <input type="hidden" name="sps_hashcode" :value="sb_sps_hashcode">
                        <!-- <input type="hidden" name="dtl_rowno" value="1"> -->
                        <!-- <input type="hidden" name="dtl_item_id" value="dtlItem_1"> -->
                        <!-- <input type="hidden" name="dtl_item_name" value="商品詳細１"> -->
                        <!-- <input type="hidden" name="dtl_item_count" value="1"> -->
                        <!-- <input type="hidden" name="dtl_tax" value="1"> -->
                        <!-- <input type="hidden" name="dtl_amount" value="1"> -->
                        <input type="submit" class="btn btn-black px-5" value="コンビニ払い" :disabled="isProcessing" >
                    </form>
                    <!-- <button type="button" class="btn btn-black py-3 px-5"><i class="bi bi-piggy-bank-fill"></i>上記金額を支払う</button> -->
                </div>
                <div class="d-flex justify-content-center" v-else>
                    <input type="button" class="btn btn-black px-5" value="次へ" @click="pay" :disabled="isProcessing" >
                    <!-- <button type="button" class="btn btn-black py-3 px-5"><i class="bi bi-piggy-bank-fill"></i>上記金額を支払う</button> -->
                </div>
            </div>
        </div>
        <div class="row pb-5" v-show="input会員種別 != '団体所属会員'">
            <div class="col-md-1 offset-md-1">
                <i id="ssl_icon" />
            </div>
            <div class="col-md-7 d-flex align-items-center">
                <div class="">
                    <label class="form-label font-s-075">当サイトはSSL暗号化通信に対応しております。お申込内容やクレジットカード番号など、お客様の大切な情報は暗号化して送信され第三者から解読できないようになっております。</label>
                </div>
            </div>
        </div>
        <div class="row" v-show="input会員種別 == '団体所属会員'">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">お支払</div>
        </div>
        <div class="row my-3" v-show="input会員種別 == '団体所属会員'">
            <div class="col-md offset-md-1">
                <label class="col-form-label">
                    年会費及び従量課金は、所属する団体から請求されます。お支払いについては、所属する団体に直接ご確認ください。
                </label>
            </div>
        </div>
        <div class="row pb-5"  v-show="input会員種別 == '団体所属会員'">
            <div class="col-md-8 offset-md-1">
                <input type="button" class="btn btn-black px-5" value="手続きを行う" @click="registerPaidCorp" :disabled="isProcessing" >
            </div>
        </div>
    </div>
    <div class="container" v-else-if="showMode === 6">
        <div class="heading mt-3">有償会員登録（年会費・従量課金支払い）</div>
        <div class="ms-3 my-3 d-flex flex-row border-bottom border-dark">
            <div class="col-form-label color-resilience-gray">1.仮登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">2.仮登録完了・メール送信</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">3.有償会員登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">4.利用規約・有償会員規約への同意</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">5.入力内容の確認</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">6.年会費・従量課金支払い</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-orange-80 fw-bold">7.本登録完了</div>
        </div>
        <div class="row mt-3">
            <label>登録が完了いたしました。ログインに必要な専用URL、ID・パスワードは、登録メールアドレスに送信しました。</label>
        </div>
        <div class="row mt-3">
            <div class="col-md-8">
                <table class="table table-bordered bg-light" id="result_table">
                    <tbody>
                        <tr>
                            <td>企業ID（会員番号）</td>
                            <td>{{ 企業ID }}</td>
                        </tr>
                        <tr>
                            <td>開始日（開始月）</td>
                            <td>
                                <div class="row">
                                    <div class="col-3">
                                        {{ this.$root.dayjs(corp_info_create).format('YYYY年M月D日') }}
                                    </div>
                                    <div class="col-9">
                                        （{{ this.$root.dayjs(corp_info_create).format('YYYY年M月') }}）
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>終了日</td>
                            <td>{{ this.$root.dayjs(service_end_day).format('YYYY年M月D日') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col-md-8">
                <label>終了・更新日前に、ご登録のメールアドレスへ更新案内をお知らせし、その後自動更新を行います。</label>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-8">
                <div class="d-flex justify-content-start">
                    <input type="button" class="btn btn-orange-80" value="請求書PDFをダウンロード" @click="makeInvoice" :disabled="isProcessing" v-show="input会員種別 != '団体所属会員'" />
                    <!-- <input type="button" class="btn btn-orange-80 ms-5" value="年会費・従量課金の領収書PDFをダウンロード" @click="makeReceipt" :disabled="isProcessing" /> -->
                    <input type="button" class="btn btn-orange-80 ms-5" value="登録内容PDFをダウンロード" @click="makeUserInfo" :disabled="isProcessing" />
                </div>
            </div>
        </div>
    </div>
    <div class="container" v-else-if="showMode === 7">
        <div class="heading mt-3">有償会員登録（年会費・従量課金支払い）</div>
        <div class="ms-3 my-3 d-flex flex-row border-bottom border-dark">
            <div class="col-form-label color-resilience-gray">1.仮登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">2.仮登録完了・メール送信</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">3.有償会員登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">4.利用規約・有償会員規約への同意</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">5.入力内容の確認</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-orange-80 fw-bold">6.年会費・従量課金支払い</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">7.本登録完了</div>
        </div>
        <div class="row my-5">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">お支払</div>
        </div>
        <div class="row my-5">
            <div class="offset-md-1 col-md-11">
                <label>口座振替でのお支払い</label>
            </div>
        </div>
        <div class="row my-5">
            <div class="offset-md-1 col-md-11 pb-5">
                <div>
                    <label>「口座振替手続きのお知らせ」を別途メールにてお送りいたします。メールをご確認のうえ、ご対応をお願いします。</label>
                </div>
                <div>
                    <label>口座振替のお手続きが完了するまで2ヶ月程お時間を頂戴いたしますので、お早めにお手続きくださいますようお願いします。</label>
                </div>
                <div class="mt-5">
                    <label>※毎年10月が更新月の為、8月にご登録の会員様は口座振替手続き完了後、初年度の年会費（2か月分）と10月から更新の年会費が同月に口座振替となります。</label>
                </div>
                <div>
                    <label>また9月にご登録の会員様は口座振替手続き完了後、初年度の年会費（1か月分）と初回のみ10月から更新の年会費は11月に口座振替となります。</label>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1 col-md-11 d-flex justify-content-start">
                <div>
                    <input type="button" class="btn btn-black px-5" value="支払方法選択画面へ戻る" @click="returnPaySelect" :disabled="isProcessing">
                </div>
                <div class="ms-5">
                    <input type="button" class="btn btn-black px-5" value="手続きを行う" @click="registerPaidCorp" :disabled="isProcessing">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, inject, nextTick, onBeforeMount, onMounted, ref } from 'vue';
import CommonCheck from '../../function/CommonCheck';
import { CommonNumberFormat } from '../../function/CommonNumberFormat.js';
import * as AutoKana from 'vanilla-autokana';
import CommonCrypt from '../../function/CommonCrypto';
import { useEnv } from '../../function/useEnv';
import axios from 'axios';

const { isZenKatakana, isTelNumber } = CommonCheck();
const { SbHash } = CommonCrypt();

let autokana_representative_family;
let autokana_representative_first;

export default {
    setup(props) {
        const { VITE_BANK_NAME, VITE_BANK_CODE, VITE_BRANCH_NAME, VITE_BRANCH_CODE, VITE_ACCOUNT_TYPE, VITE_ACCOUNT_NO, VITE_ACCOUNT_HOLDER, VITE_SB_DENSAN_URL, VITE_SB_MERCHANT_ID, VITE_SB_SERVICE_ID, VITE_SB_HASH_KEY, VITE_SB_GET_TOKEN_URL, VITE_SHOW_CONVENIENT_PAY_FLG, VITE_ENABLE_LINE } = useEnv();

        const dayjs = inject('dayjs');

        onBeforeMount(() => {
            showMode.value = props.mode;
        });

        onMounted(() => {
            autokana_representative_family = AutoKana.bind('#representative_family_name');
            autokana_representative_first = AutoKana.bind('#representative_first_name');

            msg_1.value = '企業防災やBCP策定に役立つ情報を、お知らせいたします。';

            loadLocalStorage();
        });

        const bank_name = ref(VITE_BANK_NAME);
        const bank_code = ref(VITE_BANK_CODE);
        const branch_name = ref(VITE_BRANCH_NAME);
        const branch_code = ref(VITE_BRANCH_CODE);
        const account_type = ref(VITE_ACCOUNT_TYPE);
        const account_no = ref(VITE_ACCOUNT_NO);
        const account_holder = ref(VITE_ACCOUNT_HOLDER);

        const showConvenientPayFlg = ref(VITE_SHOW_CONVENIENT_PAY_FLG);

        const handleRepresentativeFamilyNameInput = () => {
            input代表者名_姓_カナ.value = convertKatakana(autokana_representative_family.getFurigana());
        }

        const handleRepresentativeFirstNameInput = () => {
            input代表者名_名_カナ.value = convertKatakana(autokana_representative_first.getFurigana());
        }

        const convertKatakana = (str) => {
            return str.replace(/[\u3041-\u3096]/g, function(match) {
                const chr = match.charCodeAt(0) + 0x60;
                return String.fromCharCode(chr);
            });
        }

        const showMode = ref(0);
        const isProcessing = ref(false);

        const label郵便番号 = computed(() => {
            const 郵便番号 = props.temp_corp_info.郵便番号.toString();

            return 郵便番号.substring(0, 3) + '-' + 郵便番号.substring(3);
        });

        const input電話番号1 = ref('');
        const input電話番号2 = ref('');
        const input電話番号3 = ref('');
        const has電話番号_Error = ref(false);
        const 電話番号_エラーメッセージ = ref('');

        const input管理者_電話番号1 = ref('');
        const input管理者_電話番号2 = ref('');
        const input管理者_電話番号3 = ref('');
        const has管理者電話番号_Error = ref(false);
        const 管理者電話番号_エラーメッセージ = ref('');

        const input代表者名_姓 = ref('');
        const has代表者名_姓_Error = ref(false);
        const 代表者名_姓_エラーメッセージ = ref('');
        const input代表者名_名 = ref('');
        const has代表者名_名_Error = ref(false);
        const 代表者名_名_エラーメッセージ = ref('');
        const input代表者名_姓_カナ = ref('');
        const has代表者名_姓_カナ_Error = ref(false);
        const 代表者名_姓_カナ_エラーメッセージ = ref('');
        const input代表者名_名_カナ = ref('');
        const has代表者名_名_カナ_Error = ref(false);
        const 代表者名_名_カナ_エラーメッセージ = ref('');
        const input代表者_役職 = ref('');
        const has代表者_役職_Error = ref(false);
        const 代表者_役職_エラーメッセージ = ref('');

        const 利用者ID = ref('000001');

        const input管理者名_姓_カナ = ref('');
        const has管理者名_姓_カナ_Error = ref(false);
        const 管理者名_姓_カナ_エラーメッセージ = ref('');
        const input管理者名_名_カナ = ref('');
        const has管理者名_名_カナ_Error = ref(false);
        const 管理者名_名_カナ_エラーメッセージ = ref('');

        const sameTel = () => {
            input管理者_電話番号1.value = input電話番号1.value;
            input管理者_電話番号2.value = input電話番号2.value;
            input管理者_電話番号3.value = input電話番号3.value;
        }

        const 業種_大分類 = ref('');
        const 業種_中分類 = ref('');
        const 業種_小分類 = ref('');
        const 業種_細分類 = ref('');
        const has業種_Error = ref(false);
        const 業種_エラーメッセージ = ref('');

        const middle_class_list = ref([]);
        const minor_class_list = ref([]);
        const sub_class_list = ref([]);

        const changeLargeClass = () => {
            middle_class_list.value = [];
            業種_中分類.value = '';
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getMiddleClassList?largeClass=' + 業種_大分類.value;

            axios.get(url).then(res => {
                middle_class_list.value = res.data;
            });
        }

        const changeMiddleClass = () => {
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getMinorClassList?largeClass=' + 業種_大分類.value + '&middleClass=' + 業種_中分類.value;

            axios.get(url).then(res => {
                minor_class_list.value = res.data;
            });
        }

        const changeMinorClass = () => {
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getSubClassList?largeClass=' + 業種_大分類.value + '&middleClass=' + 業種_中分類.value + '&minorClass=' + 業種_小分類.value;

            axios.get(url).then(res => {
                sub_class_list.value = res.data;
            });
        }

        const postForm = () => {
            has代表者名_姓_Error.value = false;
            代表者名_姓_エラーメッセージ.value = '';
            has代表者名_名_Error.value = false;
            代表者名_名_エラーメッセージ.value = '';
            has代表者名_姓_カナ_Error.value = false;
            代表者名_姓_カナ_エラーメッセージ.value = '';
            has代表者名_名_カナ_Error.value = false;
            代表者名_名_カナ_エラーメッセージ.value = '';
            has代表者_役職_Error.value = false;
            代表者_役職_エラーメッセージ.value = '';
            has管理者名_姓_カナ_Error.value = false;
            管理者名_姓_カナ_エラーメッセージ.value = '';
            has管理者名_名_カナ_Error.value = false;
            管理者名_名_カナ_エラーメッセージ.value = '';
            has電話番号_Error.value = false;
            電話番号_エラーメッセージ.value = '';
            has管理者電話番号_Error.value = false;
            管理者電話番号_エラーメッセージ.value = '';
            has業種_Error.value = false;
            業種_エラーメッセージ.value = '';
            has支店数_Error.value = false;
            支店数_エラーメッセージ.value = '';
            従業員数_エラーメッセージ.value = '';
            has従業員数_Error.value = false;
            家族数_エラーメッセージ.value = '';
            has家族数_Error.value = false;
            has会員種別_Error.value = false;
            会員種別_エラーメッセージ.value = '';
            所属する団体会員コード_エラーメッセージ.value = '';
            has所属する団体会員コード_Error.value = false;
            賛助会員コード_エラーメッセージ.value = '';
            has賛助会員コード_Error.value = false;
            LINE連携オプション_エラーメッセージ.value = "";

            const url = '/api/register/checkPaidMember';

            axios.post(url, {
                組織名: props.temp_corp_info.組織名,
                組織名_フリガナ: props.temp_corp_info.組織名_フリガナ,
                代表者名_姓: input代表者名_姓.value,
                代表者名_名: input代表者名_名.value,
                代表者名_姓_カナ: input代表者名_姓_カナ.value,
                代表者名_名_カナ: input代表者名_名_カナ.value,
                代表者_役職: input代表者_役職.value,
                管理者名_姓_カナ: input管理者名_姓_カナ.value,
                管理者名_名_カナ: input管理者名_名_カナ.value,
                電話番号: input電話番号1.value + '-' + input電話番号2.value + '-' + input電話番号3.value,
                管理者電話番号: input管理者_電話番号1.value  + '-' + input管理者_電話番号2.value + '-' + input管理者_電話番号3.value,
                業種: 業種_細分類.value,
                支店数: input支店数.value,
                従業員数: input従業員数.value,
                家族数: input家族数.value,
                会員種別: input会員種別.value,
                所属する団体会員コード: input所属する団体会員コード.value,
                賛助会員コード: input賛助会員コード.value,

            }).then(res => {
                showMode.value = 4;
                location.hash = "#top_4";
            })
            .catch(err => {
                if(err.response.status === 422){
                    if(err.response.data.errors.代表者名_姓){
                        has代表者名_姓_Error.value = true;
                        代表者名_姓_エラーメッセージ.value = err.response.data.errors.代表者名_姓[0];
                    }
                    if(err.response.data.errors.代表者名_名){
                        has代表者名_名_Error.value = true;
                        代表者名_名_エラーメッセージ.value = err.response.data.errors.代表者名_名[0];
                    }
                    if(err.response.data.errors.代表者名_姓_カナ){
                        has代表者名_姓_カナ_Error.value = true;
                        代表者名_姓_カナ_エラーメッセージ.value = err.response.data.errors.代表者名_姓_カナ[0];
                    }
                    if(err.response.data.errors.代表者名_名_カナ){
                        has代表者名_名_カナ_Error.value = true;
                        代表者名_名_カナ_エラーメッセージ.value = err.response.data.errors.代表者名_名_カナ[0];
                    }
                    if(err.response.data.errors.代表者_役職){
                        has代表者_役職_Error.value = true;
                        代表者_役職_エラーメッセージ.value = err.response.data.errors.代表者_役職[0];
                    }
                    if(err.response.data.errors.管理者名_姓_カナ){
                        has管理者名_姓_カナ_Error.value = true;
                        管理者名_姓_カナ_エラーメッセージ.value = err.response.data.errors.管理者名_姓_カナ[0];
                    }
                    if(err.response.data.errors.管理者名_名_カナ){
                        has管理者名_名_カナ_Error.value = true;
                        管理者名_名_カナ_エラーメッセージ.value = err.response.data.errors.管理者名_名_カナ[0];
                    }
                    if(err.response.data.errors.電話番号){
                        has電話番号_Error.value = true;
                        電話番号_エラーメッセージ.value = err.response.data.errors.電話番号[0];
                    }
                    if(err.response.data.errors.管理者電話番号){
                        has管理者電話番号_Error.value = true;
                        管理者電話番号_エラーメッセージ.value = err.response.data.errors.管理者電話番号[0];
                    }
                    if(err.response.data.errors.業種){
                        has業種_Error.value = true;
                        業種_エラーメッセージ.value = err.response.data.errors.業種[0];
                    }
                    if(err.response.data.errors.支店数){
                        has支店数_Error.value = true;
                        支店数_エラーメッセージ.value = err.response.data.errors.支店数[0];
                    }
                    if(err.response.data.errors.従業員数){
                        has従業員数_Error.value = true;
                        従業員数_エラーメッセージ.value = err.response.data.errors.従業員数[0];
                    }
                    if(err.response.data.errors.家族数){
                        has家族数_Error.value = true;
                        家族数_エラーメッセージ.value = err.response.data.errors.家族数[0];
                    }
                    if(err.response.data.errors.会員種別){
                        has会員種別_Error.value = true;
                        会員種別_エラーメッセージ.value = err.response.data.errors.会員種別[0];
                    }
                    if(err.response.data.errors.所属する団体会員コード){
                        has所属する団体会員コード_Error.value = true;
                        所属する団体会員コード_エラーメッセージ.value = err.response.data.errors.所属する団体会員コード[0];
                    }
                    if(err.response.data.errors.賛助会員コード){
                        has賛助会員コード_Error.value = true;
                        賛助会員コード_エラーメッセージ.value = err.response.data.errors.賛助会員コード[0];
                    }
                }else{
                    console.log(err.response.data);
                }
            })
        }

        const input支店数 = ref(1);
        const input従業員数 = ref(1);
        const input家族数 = ref(0);
        const has支店数_Error = ref('');
        const 支店数_エラーメッセージ = ref('');
        const 従業員数_エラーメッセージ = ref('');
        const has従業員数_Error = ref('');
        const 家族数_エラーメッセージ = ref('');
        const has家族数_Error = ref('');

        const inputメルマガ登録 = ref('あり');

        const selectLINE連携オプション = ref("0");

        const input所属する団体会員コード = ref('');
        const input賛助会員コード = ref('');
        const input会員種別 = ref('');
        const has所属する団体会員コード_Error = ref('');
        const 所属する団体会員コード_エラーメッセージ = ref('');
        const 賛助会員コード_エラーメッセージ = ref('');
        const has賛助会員コード_Error = ref('');
        const 会員種別_エラーメッセージ = ref('');
        const has会員種別_Error = ref('');
        const LINE連携オプション_エラーメッセージ = ref("");

        const showPrice = () => {
            const url = '/price'

            let newWindow = window.open(url, '_blank');
        }

        const backInput = () => {
            window.scroll(0,0);
            showMode.value = 3;
        }

        const label細分類名 = computed(() => {
            const wkArray = sub_class_list.value.filter(ele => ele.細分類 === 業種_細分類.value);
            return wkArray[0].項目名;
        });

        const 企業ID = ref('');
        const corp_info_create = ref('');
        const local_code = ref('');

        const 年度 = ref(0);
        const 年会費 = ref(0);
        const 従量_支店数 = ref(0);
        const 従量_従業員数 = ref(0);
        const 従量_家族数 = ref(0);
        const 従量_LINE = ref(0);
        const number_format = (amount) => {
            return CommonNumberFormat(amount);
        }
        const 合計額 = computed(() => {
            return 年会費.value + 従量_支店数.value + 従量_従業員数.value + 従量_家族数.value + 従量_LINE.value;
        });

        /**
         * 本登録処理
         * 
         * 団体所属会員、賛助会員は請求なし→パスワード払い出し
         * 
         * 他→請求画面
         */
        const confirm = () => {

            isProcessing.value = true;

            //会員種別により遷移先が異なる
            // if(input会員種別.value === '団体所属会員' || input会員種別.value === '賛助会員'){
            //     //請求は別途行われるため、パスワード等の払い出し
            //     registerPaidCorp();
            // }else{
                //金額の取得
                const url_calc_fee = '/api/register/calcFee';

                axios.post(url_calc_fee, {
                    会員種別: input会員種別.value,
                    支店数: input支店数.value,
                    従業員数: input従業員数.value,
                    家族数: input家族数.value,
                    LINE連携: selectLINE連携オプション.value,
                }).then(res => {
                    年度.value = res.data.年度;
                    年会費.value = res.data.年会費;
                    従量_支店数.value = res.data.従量_支店総額;
                    従量_従業員数.value = res.data.従量_従業員総額;
                    従量_家族数.value = res.data.従量_家族総額;
                    従量_LINE.value = res.data.従量_LINE総額;

                    //支払画面へ
                    showMode.value = 5;
                    location.hash = "#top";
                }).finally(fin => {
                    isProcessing.value = false;
                });
            // }

        }

        const bill_id = ref(0);
        const corp_id = ref(0);
        const attach_file = ref('');

        const registerPaidCorp = () => {
            const url = '/api/register/corpPaid';

            isProcessing.value = true;

            if(input会員種別.value === '団体所属会員'){
                支払方法.value = 10051001;
            }

            if(input会員種別.value === '賛助会員'){
                支払方法.value = 10051002;
            }

            axios.post(url, {
                会員種別: get会員種別コード(input会員種別.value),
                事業形態: props.temp_corp_info.事業形態,
                法人番号: props.temp_corp_info.法人番号,
                組織名: props.temp_corp_info.組織名,
                組織名_フリガナ: props.temp_corp_info.組織名_フリガナ,
                郵便番号: props.temp_corp_info.郵便番号,
                都道府県: props.temp_corp_info.都道府県,
                市区町村: props.temp_corp_info.市区町村,
                町名番地: props.temp_corp_info.町名番地,
                電話番号: input電話番号1.value + '-' + input電話番号2.value + '-' + input電話番号3.value,
                代表者名_姓: input代表者名_姓.value,
                代表者名_名: input代表者名_名.value,
                代表者名_カナ_姓: input代表者名_姓_カナ.value,
                代表者名_カナ_名: input代表者名_名_カナ.value,
                代表者_役職: input代表者_役職.value,
                管理者名_姓: props.temp_corp_info.管理者名_姓,
                管理者名_名: props.temp_corp_info.管理者名_名,
                管理者名_カナ_姓: input管理者名_姓_カナ.value,
                管理者名_カナ_名: input管理者名_名_カナ.value,
                管理者_所属部署: props.temp_corp_info.管理者_所属部署,
                管理者_役職: props.temp_corp_info.管理者_役職,
                管理者_電話番号: input管理者_電話番号1.value  + '-' + input管理者_電話番号2.value + '-' + input管理者_電話番号3.value,
                email: props.temp_corp_info.管理者_メールアドレス,
                メルマガ登録: inputメルマガ登録.value === 'あり' ? 1 : 0,
                業種: 業種_細分類.value,
                会員資格更新区分: 10060001,
                請求方法区分: 支払方法.value,

                利用者ID: 利用者ID.value,

                仮登録ID: props.temp_corp_info.id,

                支店数: input支店数.value,
                従量_支店: 従量_支店数.value,
                従業員数: input従業員数.value,
                従量_従業員: 従量_従業員数.value,
                家族数: input家族数.value,
                従量_家族: 従量_家族数.value,
                LINE連携: Number(selectLINE連携オプション.value),
                従量_LINE: 従量_LINE.value,

                年会費: 年会費.value,

                所属する団体会員コード: input所属する団体会員コード.value,
                賛助会員コード: input賛助会員コード.value,

                contractAgree: isAgree.value,

                合計額: 合計額.value,

                uuid: props.temp_corp_info.uuid,
                アンケート: props.temp_corp_info.アンケート,
                アンケート_その他: props.temp_corp_info.アンケート_その他,
            }).then(res => {
                企業ID.value = res.data.corp.企業ID;
                corp_info_create.value = res.data.corp.created_at;
                service_end_day.value = res.data.corp.有効期限日;
                local_code.value = res.data.local_code;
                bill_id.value = res.data.bill_info.id;
                corp_id.value = res.data.corp.id;
                attach_file.value = res.data.attach_file;

                showMode.value = 6;
                location.hash = "#top";
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const makeInvoice = () => {
            const url = '/api/makeReports';

            isProcessing.value = true;

            axios.post(url, {
                'ReportName': 'invoice_from_payment_status',
                'bill_id': bill_id.value,
                'corp_id': corp_id.value,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = '請求書.pdf';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }).finally(fin => {
                isProcessing.value = false;
            });
            // if(支払方法.value === ''){
            //     alert('お支払方法を選択してください');

            //     return false;
            // }
            // const url = '/api/registerInvoice';

            // axios.post(url, {
            //     宛名: props.temp_corp_info.組織名,
            //     請求方法区分: 支払方法.value,
            //     請求金額: 合計額.value,
            //     請求年度: 年度.value,
            //     請求年会費: 年会費.value,
            //     請求従業員数: input従業員数.value,
            //     請求従業員従量課金: 従量_従業員数.value,
            //     請求支店数: input支店数.value,
            //     請求支店従量課金: 従量_支店数.value,
            //     請求家族数: input家族数.value,
            //     請求家族従量課金: 従量_家族数.value,
            //     kbn: '新規',
            // }).then(res => {
            //     const url_print = '/api/makeReports';

            //     axios.post(url_print, {
            //         'ReportName': 'invoice',
            //         'year': res.data.year,
            //         'seq': res.data.seq,
            //         '発行日' : res.data.発行日,
            //         '宛名': res.data.宛名,
            //         '請求方法区分': res.data.請求方法区分,
            //         '請求金額': res.data.請求金額,
            //         '請求年度': res.data.請求年度,
            //         '請求年会費': res.data.請求年会費,
            //         '請求従業員数': res.data.請求従業員数,
            //         '請求従業員従量課金': res.data.請求従業員従量課金,
            //         '請求支店数': res.data.請求支店数,
            //         '請求支店従量課金': res.data.請求支店従量課金,
            //         '請求家族数': res.data.請求家族数,
            //         '請求家族従量課金': res.data.請求家族従量課金,
            //         'kbn': '新規',
            //         '請求方法名': 支払方法名.value,
            //     },{
            //         responseType: 'arraybuffer',
            //     }).then(res => {
            //         const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
            //         let link = document.createElement('a');
            //         link.href = data;
            //         link.download = '請求書.pdf';
            //         link.click();

            //         setTimeout(function(){
            //             window.URL.revokeObjectURL(data);
            //         }, 100);
            //     });
            // })
        }

        const makeReceipt = () => {
            const url = '/api/makeReports';

            isProcessing.value = true;

            axios.post(url, {
                'ReportName': 'receipt',
                'bill_id': bill_id.value,
                'corp_id': corp_id.value,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = '領収書.pdf';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const service_end_day = ref('');

        const showTerms = () => {
            const url = '/terms'

            let newWindow = window.open(url, '_blank');
        }

        const showPaidMembershipTerms = () => {
            const url = '/paid_membership_terms'

            let newWindow = window.open(url, '_blank');
        }

        const msg_1 = ref('');

        const get会員種別コード = (会員種別名) => {
            switch(会員種別名){
                case '企業会員':
                    return 10010002;

                case '団体会員':
                    return 10010003;

                case '公共会員':
                    return 10010004;

                case '団体所属会員':
                    return 10010005;
                
                case '賛助会員':
                    return 10010006;
                
                default:
                    return '';
            }
        }

        const registerButtonMsg = computed(() => {
            switch(input会員種別.value){
                case '団体所属会員':
                case '賛助会員':
                    // return '上記内容で登録し、専用ページURLをメールで受信する';
                    return '上記内容で登録し、次の画面に進む';
                default:
                    return '上記内容で登録し、支払い画面に進む';
            }
        });

        const isShowTerms = ref(false);
        const isAgree = ref(false);

        const showTermsForPaid = () => {
            const url = '/termsForPaid';
            let newWindow = window.open(url, '_blank');
            // let self = this;

            // let loop = setInterval(function() {
            //     if(newWindow.closed){
            //         clearInterval(loop);
            //         self.searchCustomerList(self.current_page);
            //     }
            // });
            isShowTerms.value = true;
        }

        const 支払方法 = ref('');

        const pay = () => {
            switch(支払方法.value){
                case "":
                    alert('お支払方法を選択してください');

                    return false;

                case "10050001":
                    //クレジット払い
                    //SBペイメント画面表示
                    payCredit();

                    break;
                case "10050002":
                    //コンビニ払い
                    //SBペイメント画面表示

                    break;
                case "10050003":
                case "10050103":
                    //口座振替
                    //初年度は銀行振込のため、振込確認画面へ遷移
                    confirmTransfer();

                    // registerPaidCorp();

                    break;

            }

        }

        const confirmTransfer = () => {
            showMode.value = 7;
        }

        const returnPaySelect = () => {
            showMode.value = 5;
        }

        const submitCredit = ref();
        const submitConvenience = ref();

        const sb_pay_method = ref('');
        const sb_merchant_id = ref(VITE_SB_MERCHANT_ID);
        const sb_service_id = ref(VITE_SB_SERVICE_ID);
        const sb_cust_code = ref('');
        const sb_order_id = ref('');
        const sb_item_id = ref('');
        const sb_item_name = ref('');
        const sb_amount = ref('');
        const sb_pay_type = ref('');
        const sb_service_type = ref('');
        const sb_terminal_type = ref('');

        const sb_dtl_rowno_1 = ref('');
        const sb_dtl_item_id_1 = ref('');
        const sb_dtl_item_name_1 = ref('');
        const sb_dtl_item_count_1 = ref('');
        const sb_dtl_amount_1 = ref('');

        const sb_dtl_rowno_2 = ref('');
        const sb_dtl_item_id_2 = ref('');
        const sb_dtl_item_name_2 = ref('');
        const sb_dtl_item_count_2 = ref('');
        const sb_dtl_amount_2 = ref('');

        const sb_dtl_rowno_3 = ref('');
        const sb_dtl_item_id_3 = ref('');
        const sb_dtl_item_name_3 = ref('');
        const sb_dtl_item_count_3 = ref('');
        const sb_dtl_amount_3 = ref('');

        const sb_dtl_rowno_4 = ref('');
        const sb_dtl_item_id_4 = ref('');
        const sb_dtl_item_name_4 = ref('');
        const sb_dtl_item_count_4 = ref('');
        const sb_dtl_amount_4 = ref('');

        const sb_dtl_rowno_5 = ref('');
        const sb_dtl_item_id_5 = ref('');
        const sb_dtl_item_name_5 = ref('');
        const sb_dtl_item_count_5 = ref('');
        const sb_dtl_amount_5 = ref('');

        const sb_success_url = ref('');
        const sb_cancel_url = ref('');
        const sb_error_url = ref('');
        const sb_pagecon_url = ref('');
        const sb_request_date = ref('');
        const sb_hashkey = ref(VITE_SB_HASH_KEY);
        const sb_sps_hashcode = ref('');

        const getOrderID = (next_method) => {
            const url = '/api/getOrderSequence';

            axios.get(url).then(res => {
                switch(next_method){
                    case 'payCredit':
                        payCredit(res.data);
                        break;
                    case 'payConvenience':
                        payConvenience(res.data);
                        break;
                }
            });
        }

        const payCredit = async (orderID) => {
            //入力値をlocal storageに保管
            setLocalStorage();

            //クレジット払い
            const time_string = dayjs().format('YYYYMMDDHHmmss')

            const baseUrl = window.location.origin;

            const creditParams = {
                'pay_method': 'credit3d2',
                'merchant_id': sb_merchant_id.value,
                'service_id': sb_service_id.value,
                'cust_code': props.temp_corp_info.uuid,
                'order_id': orderID,
                'item_id': 98000001,
                'item_name': 'TOTONO-L年会費',
                'amount': 合計額.value,
                'pay_type': 0,
                'service_type': 0,
                'terminal_type': 0,

                'success_url': baseUrl + '/hon_success',
                'cancel_url': baseUrl + '/hon_cancel',
                'error_url': baseUrl + '/hon_error',
                // 'pagecon_url': baseUrl + '/api/hon_pagecon',
                'pagecon_url': 'https://totono-l.org/api/hon_pagecon',

                'dtl_rowno_1': 1,
                'dtl_item_id_1': 年度.value,
                'dtl_item_name_1': '年会費',
                'dtl_item_count_1': 1,
                'dtl_amount_1': 年会費.value,

                'dtl_rowno_2': 2,
                'dtl_item_id_2': 10030001,
                'dtl_item_name_2': '従業員',
                'dtl_item_count_2': input従業員数.value,
                'dtl_amount_2': 従量_従業員数.value,

                'dtl_rowno_3': 3,
                'dtl_item_id_3': 10030002,
                'dtl_item_name_3': '支店',
                'dtl_item_count_3': input支店数.value,
                'dtl_amount_3': 従量_支店数.value,

                'dtl_rowno_4': 4,
                'dtl_item_id_4': 10030003,
                'dtl_item_name_4': '家族数',
                'dtl_item_count_4': input家族数.value,
                'dtl_amount_4': 従量_家族数.value,

                'dtl_rowno_5': 5,
                'dtl_item_id_5': 10030004,
                'dtl_item_name_5': 'LINEオプション',
                'dtl_item_count_5': Number(selectLINE連携オプション.value),
                'dtl_amount_5': 従量_LINE.value,

                'request_date': time_string,

            }

            const url = '/api/postPurchaseResult';

            await axios.post(url, {
                params: creditParams,
                '電話番号': input電話番号1.value + '-' + input電話番号2.value + '-' + input電話番号3.value,
                '代表者名_姓': input代表者名_姓.value,
                '代表者名_名': input代表者名_名.value,
                '代表者名_カナ_姓': input代表者名_姓_カナ.value,
                '代表者名_カナ_名': input代表者名_名_カナ.value,
                '代表者_役職': input代表者_役職.value,
                '管理者名_カナ_姓': input管理者名_姓_カナ.value,
                '管理者名_カナ_名': input管理者名_名_カナ.value,
                '管理者_電話番号': input管理者_電話番号1.value  + '-' + input管理者_電話番号2.value + '-' + input管理者_電話番号3.value,
                'メルマガ登録': inputメルマガ登録.value === 'あり' ? 1 : 0,
                '業種': 業種_細分類.value,
                '会員種別': get会員種別コード(input会員種別.value),
            });

            sb_pay_method.value = creditParams.pay_method;
            sb_merchant_id.value = creditParams.merchant_id;
            sb_service_id.value = creditParams.service_id;
            sb_cust_code.value = creditParams.cust_code;
            sb_order_id.value = creditParams.order_id;
            sb_item_id.value = creditParams.item_id;
            sb_item_name.value = creditParams.item_name;
            sb_amount.value = creditParams.amount;
            sb_pay_type.value = creditParams.pay_type;
            sb_service_type.value = creditParams.service_type;
            sb_terminal_type.value = creditParams.terminal_type;

            sb_dtl_rowno_1.value = creditParams.dtl_rowno_1;
            sb_dtl_item_id_1.value = creditParams.dtl_item_id_1;
            sb_dtl_item_name_1.value = creditParams.dtl_item_name_1;
            sb_dtl_item_count_1.value = creditParams.dtl_item_count_1;
            sb_dtl_amount_1.value = creditParams.dtl_amount_1;

            sb_dtl_rowno_2.value = creditParams.dtl_rowno_2;
            sb_dtl_item_id_2.value = creditParams.dtl_item_id_2;
            sb_dtl_item_name_2.value = creditParams.dtl_item_name_2;
            sb_dtl_item_count_2.value = creditParams.dtl_item_count_2;
            sb_dtl_amount_2.value = creditParams.dtl_amount_2;

            sb_dtl_rowno_3.value = creditParams.dtl_rowno_3;
            sb_dtl_item_id_3.value = creditParams.dtl_item_id_3;
            sb_dtl_item_name_3.value = creditParams.dtl_item_name_3;
            sb_dtl_item_count_3.value = creditParams.dtl_item_count_3;
            sb_dtl_amount_3.value = creditParams.dtl_amount_3;

            sb_dtl_rowno_4.value = creditParams.dtl_rowno_4;
            sb_dtl_item_id_4.value = creditParams.dtl_item_id_4;
            sb_dtl_item_name_4.value = creditParams.dtl_item_name_4;
            sb_dtl_item_count_4.value = creditParams.dtl_item_count_4;
            sb_dtl_amount_4.value = creditParams.dtl_amount_4;

            sb_dtl_rowno_5.value = creditParams.dtl_rowno_5;
            sb_dtl_item_id_5.value = creditParams.dtl_item_id_5;
            sb_dtl_item_name_5.value = creditParams.dtl_item_name_5;
            sb_dtl_item_count_5.value = creditParams.dtl_item_count_5;
            sb_dtl_amount_5.value = creditParams.dtl_amount_5;

            sb_success_url.value = creditParams.success_url;
            sb_cancel_url.value = creditParams.cancel_url;
            sb_error_url.value = creditParams.error_url;
            sb_pagecon_url.value = creditParams.pagecon_url;
            sb_request_date.value = creditParams.request_date;

            // sb_hashkey.value = ref(VITE_SB_HASH_KEY);

            const vals = Object.values(creditParams);

            const str = vals.reduce((temp, item) => {
                return temp + item;
            });

            const hashData = SbHash(str + sb_hashkey.value);

            sb_sps_hashcode.value = hashData;

            await nextTick();
            submitCredit.value.submit();

        }

        const payConvenience = async (orderID) => {
            //クレジット払い
            const time_string = dayjs().format('YYYYMMDDHHmmss')

            const baseUrl = window.location.origin;

            const creditParams = {
                'pay_method': 'webcvs',
                'merchant_id': sb_merchant_id.value,
                'service_id': sb_service_id.value,
                'cust_code': props.temp_corp_info.uuid,
                'order_id': orderID,
                'item_id': 98000001,
                'item_name': 'TOTONO-L年会費',
                'amount': 合計額.value,
                'pay_type': 0,
                'service_type': 0,
                'terminal_type': 0,

                'success_url': baseUrl + '/hon_success',
                'cancel_url': baseUrl + '/hon_cancel',
                'error_url': baseUrl + '/hon_error',
                // 'pagecon_url': baseUrl + '/api/hon_pagecon',
                'pagecon_url': 'https://totono-l.org/api/hon_web_cvs_pagecon',

                'dtl_rowno_1': 1,
                'dtl_item_id_1': 年度.value,
                'dtl_item_name_1': '年会費',
                'dtl_item_count_1': 1,
                'dtl_amount_1': 年会費.value,

                'dtl_rowno_2': 2,
                'dtl_item_id_2': 10030001,
                'dtl_item_name_2': '従業員',
                'dtl_item_count_2': input従業員数.value,
                'dtl_amount_2': 従量_従業員数.value,

                'dtl_rowno_3': 3,
                'dtl_item_id_3': 10030002,
                'dtl_item_name_3': '支店',
                'dtl_item_count_3': input支店数.value,
                'dtl_amount_3': 従量_支店数.value,

                'dtl_rowno_4': 4,
                'dtl_item_id_4': 10030003,
                'dtl_item_name_4': '家族数',
                'dtl_item_count_4': input家族数.value,
                'dtl_amount_4': 従量_家族数.value,

                'dtl_rowno_5': 5,
                'dtl_item_id_5': 10030004,
                'dtl_item_name_5': 'LINE連携',
                'dtl_item_count_5': Number(selectLINE連携オプション.value),
                'dtl_amount_5': 従量_LINE.value,

                'request_date': time_string,
            }

            const url = '/api/postPurchaseResult';

            await axios.post(url, {
                params: creditParams,

                '電話番号': input電話番号1.value + '-' + input電話番号2.value + '-' + input電話番号3.value,
                '代表者名_姓': input代表者名_姓.value,
                '代表者名_名': input代表者名_名.value,
                '代表者名_カナ_姓': input代表者名_姓_カナ.value,
                '代表者名_カナ_名': input代表者名_名_カナ.value,
                '代表者_役職': input代表者_役職.value,
                '管理者名_カナ_姓': input管理者名_姓_カナ.value,
                '管理者名_カナ_名': input管理者名_名_カナ.value,
                '管理者_電話番号': input管理者_電話番号1.value  + '-' + input管理者_電話番号2.value + '-' + input管理者_電話番号3.value,
                'メルマガ登録': inputメルマガ登録.value === 'あり' ? 1 : 0,
                '業種': 業種_細分類.value,
                '会員種別': get会員種別コード(input会員種別.value),
            });

            sb_pay_method.value = creditParams.pay_method;
            sb_merchant_id.value = creditParams.merchant_id;
            sb_service_id.value = creditParams.service_id;
            sb_cust_code.value = creditParams.cust_code;
            sb_order_id.value = creditParams.order_id;
            sb_item_id.value = creditParams.item_id;
            sb_item_name.value = creditParams.item_name;
            sb_amount.value = creditParams.amount;
            sb_pay_type.value = creditParams.pay_type;
            sb_service_type.value = creditParams.service_type;
            sb_terminal_type.value = creditParams.terminal_type;

            sb_dtl_rowno_1.value = creditParams.dtl_rowno_1;
            sb_dtl_item_id_1.value = creditParams.dtl_item_id_1;
            sb_dtl_item_name_1.value = creditParams.dtl_item_name_1;
            sb_dtl_item_count_1.value = creditParams.dtl_item_count_1;
            sb_dtl_amount_1.value = creditParams.dtl_amount_1;

            sb_dtl_rowno_2.value = creditParams.dtl_rowno_2;
            sb_dtl_item_id_2.value = creditParams.dtl_item_id_2;
            sb_dtl_item_name_2.value = creditParams.dtl_item_name_2;
            sb_dtl_item_count_2.value = creditParams.dtl_item_count_2;
            sb_dtl_amount_2.value = creditParams.dtl_amount_2;

            sb_dtl_rowno_3.value = creditParams.dtl_rowno_3;
            sb_dtl_item_id_3.value = creditParams.dtl_item_id_3;
            sb_dtl_item_name_3.value = creditParams.dtl_item_name_3;
            sb_dtl_item_count_3.value = creditParams.dtl_item_count_3;
            sb_dtl_amount_3.value = creditParams.dtl_amount_3;

            sb_dtl_rowno_4.value = creditParams.dtl_rowno_4;
            sb_dtl_item_id_4.value = creditParams.dtl_item_id_4;
            sb_dtl_item_name_4.value = creditParams.dtl_item_name_4;
            sb_dtl_item_count_4.value = creditParams.dtl_item_count_4;
            sb_dtl_amount_4.value = creditParams.dtl_amount_4;

            sb_dtl_rowno_5.value = creditParams.dtl_rowno_5;
            sb_dtl_item_id_5.value = creditParams.dtl_item_id_5;
            sb_dtl_item_name_5.value = creditParams.dtl_item_name_5;
            sb_dtl_item_count_5.value = creditParams.dtl_item_count_5;
            sb_dtl_amount_5.value = creditParams.dtl_amount_5;

            sb_success_url.value = creditParams.success_url;
            sb_cancel_url.value = creditParams.cancel_url;
            sb_error_url.value = creditParams.error_url;
            sb_pagecon_url.value = creditParams.pagecon_url;
            sb_request_date.value = creditParams.request_date;

            sb_hashkey.value = ref(VITE_SB_HASH_KEY);

            const vals = Object.values(creditParams);

            const str = vals.reduce((temp, item) => {
                return temp + item;
            });

            const hashData = SbHash(str + sb_hashkey.value);

            sb_sps_hashcode.value = hashData;

            await nextTick();
            submitConvenience.value.submit();

        }

        const 支払方法名 = computed(() => {
            switch(支払方法.value){
                case 10050001:
                    return 'クレジット決済';
                case 10050002:
                    return 'コンビニ払い';
                default:
                    return '口座振替';
            }
        });

        const densan_url = ref(VITE_SB_DENSAN_URL);

        const makeUserInfo = () => {
            const url_print = '/api/downloadUserInfo';

            isProcessing.value = true;

            axios.post(url_print, {
                filePath: attach_file.value,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = '有償会員の登録内容.pdf';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }).finally(fin => {
                isProcessing.value = false;
            });

        }

        const showLINE = ref(VITE_ENABLE_LINE);

        const LOCAL_STORAGE_KEY_TEL_1 = "input電話番号1";
        const LOCAL_STORAGE_KEY_TEL_2 = "input電話番号2";
        const LOCAL_STORAGE_KEY_TEL_3 = "input電話番号3";
        const LOCAL_STORAGE_KEY_FAMILY_NAME = "input代表者名_姓";
        const LOCAL_STORAGE_KEY_LAST_NAME = "input代表者名_名";
        const LOCAL_STORAGE_KEY_FAMILY_KANA = "input代表者名_姓_カナ";
        const LOCAL_STORAGE_KEY_LAST_KANA = "input代表者名_名_カナ";
        const LOCAL_STORAGE_KEY_POST = "input代表者_役職";
        const LOCAL_STORAGE_KEY_ADMIN_FAMILY_KANA = "input管理者名_姓_カナ";
        const LOCAL_STORAGE_KEY_ADMIN_LAST_KANA = "input管理者名_名_カナ";
        const LOCAL_STORAGE_KEY_ADMIN_TEL_1 = "input管理者_電話番号1";
        const LOCAL_STORAGE_KEY_ADMIN_TEL_2 = "input管理者_電話番号2";
        const LOCAL_STORAGE_KEY_ADMIN_TEL_3 = "input管理者_電話番号3";
        const LOCAL_STORAGE_KEY_MAIL_MAGAZINE = "inputメルマガ登録";
        const LOCAL_STORAGE_KEY_LARGE_CLASS = "業種_大分類";
        const LOCAL_STORAGE_KEY_MIDDLE_CLASS = "業種_中分類";
        const LOCAL_STORAGE_KEY_SMALL_CLASS = "業種_小分類";
        const LOCAL_STORAGE_KEY_DETAIL_CLASS = "業種_細分類";
        const LOCAL_STORAGE_KEY_BRANCH = "input支店数";
        const LOCAL_STORAGE_KEY_EMPLOYEE = "input従業員数";
        const LOCAL_STORAGE_KEY_FAMILY = "input家族数";
        const LOCAL_STORAGE_KEY_LINE = "selectLINE連携オプション";
        const LOCAL_STORAGE_KEY_MEMBER_KIND = "input会員種別";
        const LOCAL_STORAGE_KEY_GROUP_CODE = "input所属する団体会員コード";
        const LOCAL_STORAGE_KEY_SUPPORT_CODE = "input賛助会員コード";
        const LOCAL_STORAGE_KEY_AGREE = "isAgree";
        const LOCAL_STORAGE_KEY_PAY_METHOD = "支払方法";

        const setLocalStorage = () => {
            sessionStorage.setItem(LOCAL_STORAGE_KEY_TEL_1, input電話番号1.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_TEL_2, input電話番号2.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_TEL_3, input電話番号3.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_FAMILY_NAME, input代表者名_姓.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_LAST_NAME, input代表者名_名.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_FAMILY_KANA, input代表者名_姓_カナ.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_LAST_KANA, input代表者名_名_カナ.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_POST, input代表者_役職.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_ADMIN_FAMILY_KANA, input管理者名_姓_カナ.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_ADMIN_LAST_KANA, input管理者名_名_カナ.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_ADMIN_TEL_1, input管理者_電話番号1.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_ADMIN_TEL_2, input管理者_電話番号2.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_ADMIN_TEL_3, input管理者_電話番号3.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_MAIL_MAGAZINE, inputメルマガ登録.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_LARGE_CLASS, 業種_大分類.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_MIDDLE_CLASS, 業種_中分類.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_SMALL_CLASS, 業種_小分類.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_DETAIL_CLASS, 業種_細分類.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_BRANCH, input支店数.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_EMPLOYEE, input従業員数.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_FAMILY, input家族数.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_LINE, selectLINE連携オプション.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_MEMBER_KIND, input会員種別.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_GROUP_CODE, input所属する団体会員コード.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_SUPPORT_CODE, input賛助会員コード.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_AGREE, isAgree.value);
            sessionStorage.setItem(LOCAL_STORAGE_KEY_PAY_METHOD, 支払方法.value);
        }

        const loadLocalStorage = () => {
            const tel_1 = sessionStorage.getItem(LOCAL_STORAGE_KEY_TEL_1);
            if(tel_1) input電話番号1.value = tel_1;
            const tel_2 = sessionStorage.getItem(LOCAL_STORAGE_KEY_TEL_2);
            if(tel_2) input電話番号2.value = tel_2;
            const tel_3 = sessionStorage.getItem(LOCAL_STORAGE_KEY_TEL_3);
            if(tel_3) input電話番号3.value = tel_3;
            const family_name = sessionStorage.getItem(LOCAL_STORAGE_KEY_FAMILY_NAME);
            if(family_name) input代表者名_姓.value = family_name;
            const last_name = sessionStorage.getItem(LOCAL_STORAGE_KEY_LAST_NAME);
            if(last_name) input代表者名_名.value = last_name;
            const family_kana = sessionStorage.getItem(LOCAL_STORAGE_KEY_FAMILY_KANA);
            if(family_kana) input代表者名_姓_カナ.value = family_kana;
            const last_kana = sessionStorage.getItem(LOCAL_STORAGE_KEY_LAST_KANA);
            if(last_kana) input代表者名_名_カナ.value = last_kana;
            const post = sessionStorage.getItem(LOCAL_STORAGE_KEY_POST);
            if(post) input代表者_役職.value = post;
            const admin_family_kana = sessionStorage.getItem(LOCAL_STORAGE_KEY_ADMIN_FAMILY_KANA);
            if(admin_family_kana) input管理者名_姓_カナ.value = admin_family_kana;
            const last_family_kana = sessionStorage.getItem(LOCAL_STORAGE_KEY_ADMIN_LAST_KANA);
            if(last_family_kana) input管理者名_名_カナ.value = last_family_kana;
            const admin_tel_1 = sessionStorage.getItem(LOCAL_STORAGE_KEY_ADMIN_TEL_1);
            if(admin_tel_1) input管理者_電話番号1.value = admin_tel_1;
            const admin_tel_2 = sessionStorage.getItem(LOCAL_STORAGE_KEY_ADMIN_TEL_2);
            if(admin_tel_2) input管理者_電話番号2.value = admin_tel_2;
            const admin_tel_3 = sessionStorage.getItem(LOCAL_STORAGE_KEY_ADMIN_TEL_3);
            if(admin_tel_3) input管理者_電話番号3.value = admin_tel_3;
            const mail_magazine = sessionStorage.getItem(LOCAL_STORAGE_KEY_MAIL_MAGAZINE);
            if(mail_magazine) inputメルマガ登録.value = mail_magazine;
            const large_class = sessionStorage.getItem(LOCAL_STORAGE_KEY_LARGE_CLASS);
            if(large_class) 業種_大分類.value = large_class;
            changeLargeClass();
            const middle_class = sessionStorage.getItem(LOCAL_STORAGE_KEY_MIDDLE_CLASS);
            if(middle_class) 業種_中分類.value = middle_class;
            changeMiddleClass();
            const small_class = sessionStorage.getItem(LOCAL_STORAGE_KEY_SMALL_CLASS);
            if(small_class) 業種_小分類.value = small_class;
            changeMinorClass();
            const detail_class = sessionStorage.getItem(LOCAL_STORAGE_KEY_DETAIL_CLASS);
            if(detail_class) 業種_細分類.value = detail_class;
            const branch = sessionStorage.getItem(LOCAL_STORAGE_KEY_BRANCH);
            if(branch) input支店数.value = branch;
            const employee = sessionStorage.getItem(LOCAL_STORAGE_KEY_EMPLOYEE);
            if(employee) input従業員数.value = employee;
            const family = sessionStorage.getItem(LOCAL_STORAGE_KEY_FAMILY);
            if(family) input家族数.value = family;
            const line = sessionStorage.getItem(LOCAL_STORAGE_KEY_LINE);
            if(line) selectLINE連携オプション.value = line;
            const member_kind = sessionStorage.getItem(LOCAL_STORAGE_KEY_MEMBER_KIND);
            if(member_kind) input会員種別.value = member_kind;
            const group_code = sessionStorage.getItem(LOCAL_STORAGE_KEY_GROUP_CODE);
            if(group_code) input所属する団体会員コード.value = group_code;
            const support_code = sessionStorage.getItem(LOCAL_STORAGE_KEY_SUPPORT_CODE);
            if(support_code) input賛助会員コード.value = support_code;
            const agree = sessionStorage.getItem(LOCAL_STORAGE_KEY_AGREE);
            if(agree) {
                isShowTerms.value = true;
                isAgree.value = agree;
            }
            const pay_method = sessionStorage.getItem(LOCAL_STORAGE_KEY_PAY_METHOD);
            if(pay_method) 支払方法.value = pay_method;
        }

        return {
            handleRepresentativeFamilyNameInput,
            handleRepresentativeFirstNameInput,

            convertKatakana,

            showMode,
            isProcessing,

            label郵便番号,

            input代表者名_姓,
            has代表者名_姓_Error,
            代表者名_姓_エラーメッセージ,
            input代表者名_名,
            input代表者名_姓_カナ,
            has代表者名_名_Error,
            代表者名_名_エラーメッセージ,
            has代表者名_姓_カナ_Error,
            代表者名_姓_カナ_エラーメッセージ,
            input代表者名_名_カナ,
            has代表者名_名_カナ_Error,
            代表者名_名_カナ_エラーメッセージ,
            input代表者_役職,
            has代表者_役職_Error,
            代表者_役職_エラーメッセージ,

            利用者ID,

            input管理者名_姓_カナ,
            has管理者名_姓_カナ_Error,
            管理者名_姓_カナ_エラーメッセージ,
            input管理者名_名_カナ,
            has管理者名_名_カナ_Error,
            管理者名_名_カナ_エラーメッセージ,

            input電話番号1,
            input電話番号2,
            input電話番号3,
            has電話番号_Error,
            電話番号_エラーメッセージ,

            input管理者_電話番号1,
            input管理者_電話番号2,
            input管理者_電話番号3,
            has管理者電話番号_Error,
            管理者電話番号_エラーメッセージ,

            selectLINE連携オプション,
            LINE連携オプション_エラーメッセージ,

            sameTel,

            業種_大分類,
            業種_中分類,
            業種_小分類,
            業種_細分類,
            has業種_Error,
            業種_エラーメッセージ,

            middle_class_list,
            minor_class_list,
            sub_class_list,
            changeLargeClass,
            changeMiddleClass,
            changeMinorClass,

            label細分類名,

            postForm,

            input支店数,
            input従業員数,
            input家族数,
            has支店数_Error,
            支店数_エラーメッセージ,
            従業員数_エラーメッセージ,
            has従業員数_Error,
            家族数_エラーメッセージ,
            has家族数_Error,

            inputメルマガ登録,

            input所属する団体会員コード,
            input賛助会員コード,
            input会員種別,
            has所属する団体会員コード_Error,
            所属する団体会員コード_エラーメッセージ,
            賛助会員コード_エラーメッセージ,
            has賛助会員コード_Error,
            会員種別_エラーメッセージ,
            has会員種別_Error,

            showPrice,

            makeInvoice,
            makeReceipt,

            backInput,

            企業ID,
            corp_info_create,
            local_code,

            年度,
            年会費,
            従量_支店数,
            従量_従業員数,
            従量_家族数,
            従量_LINE,
            number_format,
            合計額,

            confirm,
            registerPaidCorp,
            service_end_day,

            showTerms,
            showPaidMembershipTerms,

            msg_1,
            get会員種別コード,
            registerButtonMsg,

            isShowTerms,
            isAgree,

            showTermsForPaid,

            getOrderID,

            支払方法,
            pay,
            payCredit,
            payConvenience,

            confirmTransfer,
            returnPaySelect,

            bank_name,
            bank_code,
            branch_name,
            branch_code,
            account_type,
            account_no,
            account_holder,

            支払方法名,

            bill_id,
            corp_id,

            attach_file,

            densan_url,

            submitCredit,
            submitConvenience,

            sb_pay_method,
            sb_merchant_id,
            sb_service_id,
            sb_cust_code,
            sb_order_id,
            sb_item_id,
            sb_item_name,
            sb_amount,
            sb_pay_type,
            sb_service_type,
            sb_terminal_type,

            sb_dtl_rowno_1,
            sb_dtl_item_id_1,
            sb_dtl_item_name_1,
            sb_dtl_item_count_1,
            sb_dtl_amount_1,
            
            sb_dtl_rowno_2,
            sb_dtl_item_id_2,
            sb_dtl_item_name_2,
            sb_dtl_item_count_2,
            sb_dtl_amount_2,

            sb_dtl_rowno_3,
            sb_dtl_item_id_3,
            sb_dtl_item_name_3,
            sb_dtl_item_count_3,
            sb_dtl_amount_3,
            
            sb_dtl_rowno_4,
            sb_dtl_item_id_4,
            sb_dtl_item_name_4,
            sb_dtl_item_count_4,
            sb_dtl_amount_4,

            sb_dtl_rowno_5,
            sb_dtl_item_id_5,
            sb_dtl_item_name_5,
            sb_dtl_item_count_5,
            sb_dtl_amount_5,

            sb_success_url,
            sb_cancel_url,
            sb_error_url,
            sb_pagecon_url,
            sb_request_date,
            sb_hashkey,
            sb_sps_hashcode,

            makeUserInfo,

            showConvenientPayFlg,

            showLINE,

            setLocalStorage,
            loadLocalStorage,
        }
    },
    props: {
        temp_corp_info: {
            type: Object,
        },
        mode: {
            type: Number,
        },
        japan_standard_industrial_large_list: {
            type: Array,
        }
    },
}
</script>

<style lang="scss" scoped>
    #result_table tr td:nth-child(1){
        width: 25%;
    }

    #ssl_icon {
        background-image: url('../../../svg/ssl2.svg');
        display: inline-flex;
        width: 86px;
        height: 33px;
        background-size: 86px auto;
        background-repeat: no-repeat;
    }
</style>