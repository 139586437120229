<template>
    <div class="row mt-5">
        <div class="col">
            <label>以下のボタンをクリックすると、一発でおすすめの読替を登録できます。なお、ボタンがない業種や一部を変更したい場合は、下段のリストから直接ご選択又は修正してください。</label><br>
            <label>読み替える呼称を確認後、「読替登録をする｜確定」ボタンをクリックして、登録してください。</label>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-2 px-1">
            <div class="d-flex h-100">
                <button class="btn btn-al-inquiry-button flex-fill py-3" @click="changeValue('製造業')">製造業</button>
            </div>
        </div>
        <div class="col-md-2 px-1">
            <div class="d-flex h-100">
                <button class="btn btn-al-inquiry-button flex-fill py-3" @click="changeValue('建設業_ゼネコン')">建設業<br>［ゼネコン］</button>
            </div>
        </div>
        <div class="col-md-2 px-1">
            <div class="d-flex h-100">
                <button class="btn btn-al-inquiry-button flex-fill py-3" @click="changeValue('建設業_専門工事業')">建設業<br>［専門工事業］</button>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-2">
            <label class="col-form-label">既定の呼称</label>
        </div>
        <div class="col-md-1">

        </div>
        <div class="col-md-4">
            <label class="col-form-label">読み替える呼称をリストから選択</label>
        </div>
        <div class="col-md-2">
            <label class="col-form-label">その他の場合</label>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">組織</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select"  v-model='select組織'>
                <option v-for="organization in organization_list" :key="organization.code" :value="organization.code">{{ organization.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select組織.toString().slice(-4) !== '9999'" v-model="input組織_その他" :class="{'is-invalid': 組織_has_error}">
            <div class='invalid-feedback'>{{ 組織_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">経営者</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select経営者">
                <option v-for="executive in executive_list" :key="executive.code" :value="executive.code">{{ executive.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select経営者.toString().slice(-4) !== '9999'" v-model="input経営者_その他" :class="{'is-invalid': 経営者_has_error}">
            <div class='invalid-feedback'>{{ 経営者_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">経営</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select経営">
                <option v-for="management in management_list" :key="management.code" :value="management.code">{{ management.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select経営.toString().slice(-4) !== '9999'" v-model="input経営_その他" :class="{'is-invalid': 経営_has_error}">
            <div class='invalid-feedback'>{{ 経営_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">事業</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select事業">
                <option v-for="business in business_list" :key="business.code" :value="business.code">{{ business.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select事業.toString().slice(-4) !== '9999'" v-model="input事業_その他" :class="{'is-invalid': 事業_has_error}">
            <div class='invalid-feedback'>{{ 事業_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">顧客</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select顧客">
                <option v-for="client in client_list" :key="client.code" :value="client.code">{{ client.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select顧客.toString().slice(-4) !== '9999'" v-model="input顧客_その他" :class="{'is-invalid': 顧客_has_error}">
            <div class='invalid-feedback'>{{ 顧客_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">取引先</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select取引先">
                <option v-for="business_partner in business_partner_list" :key="business_partner.code" :value="business_partner.code">{{ business_partner.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select取引先.toString().slice(-4) !== '9999'" v-model="input取引先_その他" :class="{'is-invalid': 取引先_has_error}">
            <div class='invalid-feedback'>{{ 取引先_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">仕入先</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select仕入先">
                <option v-for="supplier in supplier_list" :key="supplier.code" :value="supplier.code">{{ supplier.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select仕入先.toString().slice(-4) !== '9999'" v-model="input仕入先_その他" :class="{'is-invalid': 仕入先_has_error}">
            <div class='invalid-feedback'>{{ 仕入先_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">協力会社</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select協力会社">
                <option v-for="cooperating_company in cooperating_company_list" :key="cooperating_company.code" :value="cooperating_company.code">{{ cooperating_company.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select協力会社.toString().slice(-4) !== '9999'" v-model="input協力会社_その他" :class="{'is-invalid': 協力会社_has_error}">
            <div class='invalid-feedback'>{{ 協力会社_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">商品</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select商品">
                <option v-for="product in product_list" :key="product.code" :value="product.code">{{ product.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select商品.toString().slice(-4) !== '9999'" v-model="input商品_その他" :class="{'is-invalid': 商品_has_error}">
            <div class='invalid-feedback'>{{ 商品_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">製造</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select製造">
                <option v-for="manufacturing in manufacturing_list" :key="manufacturing.code" :value="manufacturing.code">{{ manufacturing.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select製造.toString().slice(-4) !== '9999'" v-model="input製造_その他" :class="{'is-invalid': 製造_has_error}">
            <div class='invalid-feedback'>{{ 製造_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">生産</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select生産">
                <option v-for="production in production_list" :key="production.code" :value="production.code">{{ production.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select生産.toString().slice(-4) !== '9999'" v-model="input生産_その他" :class="{'is-invalid': 生産_has_error}">
            <div class='invalid-feedback'>{{ 生産_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">納入</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select納入">
                <option v-for="delivery in delivery_list" :key="delivery.code" :value="delivery.code">{{ delivery.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select納入.toString().slice(-4) !== '9999'" v-model="input納入_その他" :class="{'is-invalid': 納入_has_error}">
            <div class='invalid-feedback'>{{ 納入_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">原材料</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select原材料">
                <option v-for="raw_material in raw_materials_list" :key="raw_material.code" :value="raw_material.code">{{ raw_material.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select原材料.toString().slice(-4) !== '9999'" v-model="input原材料_その他" :class="{'is-invalid': 原材料_has_error}">
            <div class='invalid-feedback'>{{ 原材料_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">受注</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select受注">
                <option v-for="order_received in order_received_list" :key="order_received.code" :value="order_received.code">{{ order_received.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select受注.toString().slice(-4) !== '9999'" v-model="input受注_その他" :class="{'is-invalid': 受注_has_error}">
            <div class='invalid-feedback'>{{ 受注_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">発注</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select発注">
                <option v-for="order in order_list" :key="order.code" :value="order.code">{{ order.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select発注.toString().slice(-4) !== '9999'" v-model="input発注_その他" :class="{'is-invalid': 発注_has_error}">
            <div class='invalid-feedback'>{{ 発注_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">出荷</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select出荷">
                <option v-for="shipping in shipping_list" :key="shipping.code" :value="shipping.code">{{ shipping.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select出荷.toString().slice(-4) !== '9999'" v-model="input出荷_その他" :class="{'is-invalid': 出荷_has_error}">
            <div class='invalid-feedback'>{{ 出荷_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">当社</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select当社">
                <option v-for="our_company in our_company_list" :key="our_company.code" :value="our_company.code">{{ our_company.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select当社.toString().slice(-4) !== '9999'" v-model="input当社_その他" :class="{'is-invalid': 当社_has_error}">
            <div class='invalid-feedback'>{{ 当社_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">自社</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select自社">
                <option v-for="company in company_list" :key="company.code" :value="company.code">{{ company.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select自社.toString().slice(-4) !== '9999'" v-model="input自社_その他" :class="{'is-invalid': 自社_has_error}">
            <div class='invalid-feedback'>{{ 自社_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">社内</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select社内">
                <option v-for="in_house in in_house_list" :key="in_house.code" :value="in_house.code">{{ in_house.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select社内.toString().slice(-4) !== '9999'" v-model="input社内_その他" :class="{'is-invalid': 社内_has_error}">
            <div class='invalid-feedback'>{{ 社内_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">工場</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select工場">
                <option v-for="plant in plant_list" :key="plant.code" :value="plant.code">{{ plant.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select工場.toString().slice(-4) !== '9999'" v-model="input工場_その他" :class="{'is-invalid': 工場_has_error}">
            <div class='invalid-feedback'>{{ 工場_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">従業員</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select従業員">
                <option v-for="employee in employee_list" :key="employee.code" :value="employee.code">{{ employee.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select従業員.toString().slice(-4) !== '9999'" v-model="input従業員_その他" :class="{'is-invalid': 従業員_has_error}">
            <div class='invalid-feedback'>{{ 従業員_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">雇用</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select雇用">
                <option v-for="employment in employment_list" :key="employment.code" :value="employment.code">{{ employment.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select雇用.toString().slice(-4) !== '9999'" v-model="input雇用_その他" :class="{'is-invalid': 雇用_has_error}">
            <div class='invalid-feedback'>{{ 雇用_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">出社</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select出社">
                <option v-for="attendance in attendance_list" :key="attendance.code" :value="attendance.code">{{ attendance.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select出社.toString().slice(-4) !== '9999'" v-model="input出社_その他" :class="{'is-invalid': 出社_has_error}">
            <div class='invalid-feedback'>{{ 出社_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">売上</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select売上">
                <option v-for="earning in earnings_list" :key="earning.code" :value="earning.code">{{ earning.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select売上.toString().slice(-4) !== '9999'" v-model="input売上_その他" :class="{'is-invalid': 売上_has_error}">
            <div class='invalid-feedback'>{{ 売上_message }}</div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-2 border border-dark  bg-member_card ms-3">
            <label class="col-form-label ps-3">株主</label>
        </div>
        <div class="col-md-1 text-center col-form-label">
            <i class="bi bi-arrow-right-circle"></i>
        </div>
        <div class="col-md-4 pe-5">
            <select class="form-select" v-model="select株主">
                <option v-for="shareholder in shareholder_list" :key="shareholder.code" :value="shareholder.code">{{ shareholder.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text" class="form-control" maxlength="50" :disabled="select株主.toString().slice(-4) !== '9999'" v-model="input株主_その他" :class="{'is-invalid': 株主_has_error}">
            <div class='invalid-feedback'>{{ 株主_message }}</div>
        </div>
    </div>

</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import axios from 'axios';

const props = defineProps({
    organization_list: {
        type: Array,
    },
    executive_list: {
        type: Array,
    },
    management_list: {
        type: Array,
    },
    business_list: {
        type: Array,
    },
    client_list: {
        type: Array,
    },
    business_partner_list: {
        type: Array,
    },
    supplier_list: {
        type: Array,
    },
    cooperating_company_list: {
        type: Array,
    },
    product_list: {
        type: Array,
    },
    manufacturing_list: {
        type: Array,
    },
    production_list: {
        type: Array,
    },
    delivery_list: {
        type: Array,
    },
    raw_materials_list: {
        type: Array,
    },
    order_received_list: {
        type: Array,
    },
    order_list: {
        type: Array,
    },
    shipping_list: {
        type: Array,
    },
    our_company_list: {
        type: Array,
    },
    company_list: {
        type: Array,
    },
    in_house_list: {
        type: Array,
    },
    plant_list: {
        type: Array,
    },
    employee_list: {
        type: Array,
    },
    employment_list: {
        type: Array,
    },
    attendance_list: {
        type: Array,
    },
    earnings_list: {
        type: Array,
    },
    shareholder_list: {
        type: Array,
    },
    replacement: {
        type:Array,
    },
});

const emit = defineEmits([
    "confirmSuccess",
    "confirmFailed",
]);


onMounted(() => {
    if(props.replacement.length > 0){
        const 組織arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9901;
        });
        select組織.value = 組織arr[0].読替コード;
        input組織_その他.value = 組織arr[0].読替その他;

        const 経営者arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9902;
        });
        select経営者.value = 経営者arr[0].読替コード;
        input経営者_その他.value = 経営者arr[0].読替その他;

        const 経営arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9903;
        });
        select経営.value = 経営arr[0].読替コード;
        input経営_その他.value = 経営arr[0].読替その他;

        const 事業arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9904;
        });
        select事業.value = 事業arr[0].読替コード;
        input事業_その他.value = 事業arr[0].読替その他;

        const 顧客arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9905;
        });
        select顧客.value = 顧客arr[0].読替コード;
        input顧客_その他.value = 顧客arr[0].読替その他;

        const 取引先arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9906;
        });
        select取引先.value = 取引先arr[0].読替コード;
        input取引先_その他.value = 取引先arr[0].読替その他;

        const 仕入先arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9907;
        });
        select仕入先.value = 仕入先arr[0].読替コード;
        input仕入先_その他.value = 仕入先arr[0].読替その他;

        const 協力会社arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9908;
        });
        select協力会社.value = 協力会社arr[0].読替コード;
        input協力会社_その他.value = 協力会社arr[0].読替その他;

        const 商品arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9909;
        });
        select商品.value = 商品arr[0].読替コード;
        input商品_その他.value = 商品arr[0].読替その他;

        const 製造arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9910;
        });
        select製造.value = 製造arr[0].読替コード;
        input製造_その他.value = 製造arr[0].読替その他;

        const 生産arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9911;
        });
        select生産.value = 生産arr[0].読替コード;
        input生産_その他.value = 生産arr[0].読替その他;

        const 納入arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9912;
        });
        select納入.value = 納入arr[0].読替コード;
        input納入_その他.value = 納入arr[0].読替その他;

        const 原材料arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9913;
        });
        select原材料.value = 原材料arr[0].読替コード;
        input原材料_その他.value = 原材料arr[0].読替その他;

        const 受注arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9914;
        });
        select受注.value = 受注arr[0].読替コード;
        input受注_その他.value = 受注arr[0].読替その他;

        const 発注arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9915;
        });
        select発注.value = 発注arr[0].読替コード;
        input発注_その他.value = 発注arr[0].読替その他;

        const 出荷arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9916;
        });
        select出荷.value = 出荷arr[0].読替コード;
        input出荷_その他.value = 出荷arr[0].読替その他;

        const 当社arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9917;
        });
        select当社.value = 当社arr[0].読替コード;
        input当社_その他.value = 当社arr[0].読替その他;

        const 自社arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9918;
        });
        select自社.value = 自社arr[0].読替コード;
        input自社_その他.value = 自社arr[0].読替その他;

        const 社内arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9919;
        });
        select社内.value = 社内arr[0].読替コード;
        input社内_その他.value = 社内arr[0].読替その他;

        const 工場arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9920;
        });
        select工場.value = 工場arr[0].読替コード;
        input工場_その他.value = 工場arr[0].読替その他;

        const 従業員arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9921;
        });
        select従業員.value = 従業員arr[0].読替コード;
        input従業員_その他.value = 従業員arr[0].読替その他;

        const 雇用arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9922;
        });
        select雇用.value = 雇用arr[0].読替コード;
        input雇用_その他.value = 雇用arr[0].読替その他;

        const 出社arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9923;
        });
        select出社.value = 出社arr[0].読替コード;
        input出社_その他.value = 出社arr[0].読替その他;

        const 売上arr = props.replacement.filter(item => {
            return item.読替カテゴリコード === 9924;
        });
        select売上.value = 売上arr[0].読替コード;
        input売上_その他.value = 売上arr[0].読替その他;

        const 株主arr = props.replacement.filter(item=>{
            return item.読替カテゴリコード === 9926;
        })
        select株主.value = 株主arr[0].読替コード;
        input株主_その他.value = 株主arr[0].読替その他;
    }
});

const select組織 = ref(99010001);
watch(select組織, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input組織_その他.value = '';
    }
});
const select経営者 = ref(99020001);
watch(select経営者, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input経営者_その他.value = '';
    }
});
const select経営 = ref(99030001);
watch(select経営, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input経営_その他.value = '';
    }
});
const select事業 = ref(99040001);
watch(select事業, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input事業_その他.value = '';
    }
});
const select顧客 = ref(99050001);
watch(select顧客, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input顧客_その他.value = '';
    }
});
const select取引先 = ref(99060001);
watch(select取引先, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input取引先_その他.value = '';
    }
});
const select仕入先 = ref(99070001);
watch(select仕入先, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input仕入先_その他.value = '';
    }
});
const select協力会社 = ref(99080001);
watch(select協力会社, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input協力会社_その他.value = '';
    }
});
const select商品 = ref(99090001);
watch(select商品, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input商品_その他.value = '';
    }
});
const select製造 = ref(99100001);
watch(select製造, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input製造_その他.value = '';
    }
});
const select生産 = ref(99110001);
watch(select生産, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input生産_その他.value = '';
    }
});
const select納入 = ref(99120001);
watch(select納入, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input納入_その他.value = '';
    }
});
const select原材料 = ref(99130001);
watch(select原材料, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input原材料_その他.value = '';
    }
});
const select受注 = ref(99140001);
watch(select受注, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input受注_その他.value = '';
    }
});
const select発注 = ref(99150001);
watch(select発注, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input発注_その他.value = '';
    }
});
const select出荷 = ref(99160001);
watch(select出荷, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input出荷_その他.value = '';
    }
});
const select当社 = ref(99170001);
watch(select当社, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input当社_その他.value = '';
    }
});
const select自社 = ref(99180001);
watch(select自社, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input自社_その他.value = '';
    }
});
const select社内 = ref(99190001);
watch(select社内, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input社内_その他.value = '';
    }
});
const select工場 = ref(99200001);
watch(select工場, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input工場_その他.value = '';
    }
});
const select従業員 = ref(99210001);
watch(select従業員, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input従業員_その他.value = '';
    }
});
const select雇用 = ref(99220001);
watch(select雇用, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input雇用_その他.value = '';
    }
});
const select出社 = ref(99230001);
watch(select出社, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input出社_その他.value = '';
    }
});
const select売上 = ref(99240001);
watch(select売上, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input売上_その他.value = '';
    }
});
const select株主 = ref(99260001);
watch(select株主, (newVal, oldVal) => {
    if(oldVal.toString().slice(-4) === '9999'){
        input株主_その他.value = '';
    }
});

const input組織_その他 = ref('');
const input経営者_その他 = ref('');
const input経営_その他 = ref('');
const input事業_その他 = ref('');
const input顧客_その他 = ref('');
const input取引先_その他 = ref('');
const input仕入先_その他 = ref('');
const input協力会社_その他 = ref('');
const input商品_その他 = ref('');
const input製造_その他 = ref('');
const input生産_その他 = ref('');
const input納入_その他 = ref('');
const input原材料_その他 = ref('');
const input受注_その他 = ref('');
const input発注_その他 = ref('');
const input出荷_その他 = ref('');
const input当社_その他 = ref('');
const input自社_その他 = ref('');
const input社内_その他 = ref('');
const input工場_その他 = ref('');
const input従業員_その他 = ref('');
const input雇用_その他 = ref('');
const input出社_その他 = ref('');
const input売上_その他 = ref('');
const input株主_その他 = ref('');

const 組織_message = ref('');
const 経営者_message = ref('');
const 経営_message = ref('');
const 事業_message = ref('');
const 顧客_message = ref('');
const 取引先_message = ref('');
const 仕入先_message = ref('');
const 協力会社_message = ref('');
const 商品_message = ref('');
const 製造_message = ref('');
const 生産_message = ref('');
const 納入_message = ref('');
const 原材料_message = ref('');
const 受注_message = ref('');
const 発注_message = ref('');
const 出荷_message = ref('');
const 当社_message = ref('');
const 自社_message = ref('');
const 社内_message = ref('');
const 工場_message = ref('');
const 従業員_message = ref('');
const 雇用_message = ref('');
const 出社_message = ref('');
const 売上_message = ref('');
const 株主_message = ref('');

const 組織_has_error = ref(false);
const 経営者_has_error = ref(false);
const 経営_has_error = ref(false);
const 事業_has_error = ref(false);
const 顧客_has_error = ref(false);
const 取引先_has_error = ref(false);
const 仕入先_has_error = ref(false);
const 協力会社_has_error = ref(false);
const 商品_has_error = ref(false);
const 製造_has_error = ref(false);
const 生産_has_error = ref(false);
const 納入_has_error = ref(false);
const 原材料_has_error = ref(false);
const 受注_has_error = ref(false);
const 発注_has_error = ref(false);
const 出荷_has_error = ref(false);
const 当社_has_error = ref(false);
const 自社_has_error = ref(false);
const 社内_has_error = ref(false);
const 工場_has_error = ref(false);
const 従業員_has_error = ref(false);
const 雇用_has_error = ref(false);
const 出社_has_error = ref(false);
const 売上_has_error = ref(false);
const 株主_has_error = ref(false);

const isProcessing = ref(false);

const confirmReplacement = async() => {
    clearErrorMessage();

    isProcessing.value = true;
    const url = '/api/confirmReplacement';

    await axios.post(url, {
        組織: select組織.value,
        組織その他: input組織_その他.value,
        経営者: select経営者.value,
        経営者その他: input経営者_その他.value,
        経営: select経営.value,
        経営その他: input経営_その他.value,
        事業: select事業.value,
        事業その他: input事業_その他.value,
        顧客: select顧客.value,
        顧客その他: input顧客_その他.value,
        取引先: select取引先.value,
        取引先その他: input取引先_その他.value,
        仕入先: select仕入先.value,
        仕入先その他: input仕入先_その他.value,
        協力会社: select協力会社.value,
        協力会社その他: input協力会社_その他.value,
        商品: select商品.value,
        商品その他: input商品_その他.value,
        製造: select製造.value,
        製造その他: input製造_その他.value,
        生産: select生産.value,
        生産その他: input生産_その他.value,
        納入: select納入.value,
        納入その他: input納入_その他.value,
        原材料: select原材料.value,
        原材料その他: input原材料_その他.value,
        受注: select受注.value,
        受注その他: input受注_その他.value,
        発注: select発注.value,
        発注その他: input発注_その他.value,
        出荷: select出荷.value,
        出荷その他: input出荷_その他.value,
        当社: select当社.value,
        当社その他: input当社_その他.value,
        自社: select自社.value,
        自社その他: input自社_その他.value,
        社内: select社内.value,
        社内その他: input社内_その他.value,
        工場: select工場.value,
        工場その他: input工場_その他.value,
        従業員: select従業員.value,
        従業員その他: input従業員_その他.value,
        雇用: select雇用.value,
        雇用その他: input雇用_その他.value,
        出社: select出社.value,
        出社その他: input出社_その他.value,
        売上: select売上.value,
        売上その他: input売上_その他.value,
        株主: select株主.value,
        株主その他: input株主_その他.value,
    }).then(res => {
        const url_update_flg = '/api/updateReplacementFlg';

        axios.post(url_update_flg, {
        }).then(res =>{
            emit("confirmSuccess", res.data);
        });

    }).catch(err => {
        if(err.response.status === 422){
            if(err.response.data.errors.組織){
                組織_message.value = err.response.data.errors.組織[0];
                組織_has_error.value = true;
            }
            if(err.response.data.errors.経営者){
                経営者_message.value = err.response.data.errors.経営者[0];
                経営者_has_error.value = true;
            }
            if(err.response.data.errors.経営){
                経営_message.value = err.response.data.errors.経営[0];
                経営_has_error.value = true;
            }
            if(err.response.data.errors.事業){
                事業_message.value = err.response.data.errors.事業[0];
                事業_has_error.value = true;
            }
            if(err.response.data.errors.顧客){
                顧客_message.value = err.response.data.errors.顧客[0];
                顧客_has_error.value = true;
            }
            if(err.response.data.errors.取引先){
                取引先_message.value = err.response.data.errors.取引先[0];
                取引先_has_error.value = true;
            }
            if(err.response.data.errors.仕入先){
                仕入先_message.value = err.response.data.errors.仕入先[0];
                仕入先_has_error.value = true;
            }
            if(err.response.data.errors.協力会社){
                協力会社_message.value = err.response.data.errors.協力会社[0];
                協力会社_has_error.value = true;
            }
            if(err.response.data.errors.商品){
                商品_message.value = err.response.data.errors.商品[0];
                商品_has_error.value = true;
            }
            if(err.response.data.errors.製造){
                製造_message.value = err.response.data.errors.製造[0];
                製造_has_error.value = true;
            }
            if(err.response.data.errors.生産){
                生産_message.value = err.response.data.errors.生産[0];
                生産_has_error.value = true;
            }
            if(err.response.data.errors.納入){
                納入_message.value = err.response.data.errors.納入[0];
                納入_has_error.value = true;
            }
            if(err.response.data.errors.原材料){
                原材料_message.value = err.response.data.errors.原材料[0];
                原材料_has_error.value = true;
            }
            if(err.response.data.errors.受注){
                受注_message.value = err.response.data.errors.受注[0];
                受注_has_error.value = true;
            }
            if(err.response.data.errors.発注){
                発注_message.value = err.response.data.errors.発注[0];
                発注_has_error.value = true;
            }
            if(err.response.data.errors.出荷){
                出荷_message.value = err.response.data.errors.出荷[0];
                出荷_has_error.value = true;
            }
            if(err.response.data.errors.当社){
                当社_message.value = err.response.data.errors.当社[0];
                当社_has_error.value = true;
            }
            if(err.response.data.errors.自社){
                自社_message.value = err.response.data.errors.自社[0];
                自社_has_error.value = true;
            }
            if(err.response.data.errors.社内){
                社内_message.value = err.response.data.errors.社内[0];
                社内_has_error.value = true;
            }
            if(err.response.data.errors.工場){
                工場_message.value = err.response.data.errors.工場[0];
                工場_has_error.value = true;
            }
            if(err.response.data.errors.従業員){
                従業員_message.value = err.response.data.errors.従業員[0];
                従業員_has_error.value = true;
            }
            if(err.response.data.errors.雇用){
                雇用_message.value = err.response.data.errors.雇用[0];
                雇用_has_error.value = true;
            }
            if(err.response.data.errors.出社){
                出社_message.value = err.response.data.errors.出社[0];
                出社_has_error.value = true;
            }
            if(err.response.data.errors.売上){
                売上_message.value = err.response.data.errors.売上[0];
                売上_has_error.value = true;
            }
            if(err.response.data.errors.株主){
                株主_message.value = err.response.data.errors.株主[0];
                株主_has_error.value = true;
            }
        }else{
            emit("confirmFailed", err.response.data);
        }
    }).finally(fin => {
        isProcessing.value = false;
    });

}

const clearErrorMessage = () => {
    組織_message.value = '';
    経営者_message.value = '';
    経営_message.value = '';
    事業_message.value = '';
    顧客_message.value = '';
    取引先_message.value = '';
    仕入先_message.value = '';
    協力会社_message.value = '';
    商品_message.value = '';
    製造_message.value = '';
    生産_message.value = '';
    納入_message.value = '';
    原材料_message.value = '';
    受注_message.value = '';
    発注_message.value = '';
    出荷_message.value = '';
    当社_message.value = '';
    自社_message.value = '';
    社内_message.value = '';
    工場_message.value = '';
    従業員_message.value = '';
    雇用_message.value = '';
    出社_message.value = '';
    売上_message.value = '';
    株主_message.value = '';

    組織_has_error.value = false;
    経営者_has_error.value = false;
    経営_has_error.value = false;
    事業_has_error.value = false;
    顧客_has_error.value = false;
    取引先_has_error.value = false;
    仕入先_has_error.value = false;
    協力会社_has_error.value = false;
    商品_has_error.value = false;
    製造_has_error.value = false;
    生産_has_error.value = false;
    納入_has_error.value = false;
    原材料_has_error.value = false;
    受注_has_error.value = false;
    発注_has_error.value = false;
    出荷_has_error.value = false;
    当社_has_error.value = false;
    自社_has_error.value = false;
    社内_has_error.value = false;
    工場_has_error.value = false;
    従業員_has_error.value = false;
    雇用_has_error.value = false;
    出社_has_error.value = false;
    売上_has_error.value = false;
    株主_has_error.value = false;
}

const changeValue = (value) => {
    clearErrorMessage();

    if(props.replacement && props.replacement.length > 0){
        if(!confirm("読み替え内容を書き換えます。よろしいですか？")){
            return;
        }
    }

    switch(value){
        case "製造業":
        case "建設業_ゼネコン":
        case "建設業_専門工事業":
            select組織.value = 99010002;
            break;
        default:
            select組織.value = 99010001;
    }
    input組織_その他.value = '';

    select経営者.value = 99020001;
    input経営者_その他.value = '';

    select経営.value = 99030001;
    input経営_その他.value = '';

    select事業.value = 99040001;
    input事業_その他.value = '';

    switch(value){
        case "建設業_ゼネコン":
            select顧客.value = 99050016;
            break;
        case "建設業_専門工事業":
            select顧客.value = 99050017;
            break;
        default:
            select顧客.value = 99050001;
    }
    input顧客_その他.value = '';

    select取引先.value = 99060001;
    input取引先_その他.value = '';
    
    switch(value){
        case "建設業_ゼネコン":
            select仕入先.value = 99070008;
            break;
        default:
            select仕入先.value = 99070001;
    }
    input仕入先_その他.value = '';

    select協力会社.value = 99080001;
    input協力会社_その他.value = '';

    switch(value){
        case "製造業":
        case "建設業_専門工事業":
            select商品.value = 99090002;
            break;
        case "建設業_ゼネコン":
            select商品.value = 99090008;
            break;
        default:
            select商品.value = 99090001;
    }
    input商品_その他.value = '';

    switch(value){
        case "建設業_専門工事業":
        case "建設業_ゼネコン":
            select製造.value = 99100008;
            break;
        default:
            select製造.value = 99100001;
    }
    input製造_その他.value = '';

    switch(value){
        case "建設業_専門工事業":
        case "建設業_ゼネコン":
            select生産.value = 99110011;
            break;
        default:
            select生産.value = 99110001;
    }
    input生産_その他.value = '';

    switch(value){
        case "製造業":
            select納入.value = 99120002;
            break;
        default:
            select納入.value = 99120001;
    }
    input納入_その他.value = '';

    switch(value){
        case "建設業_専門工事業":
        case "建設業_ゼネコン":
            select原材料.value = 99130009;
            break;
        default:
            select原材料.value = 99130001;
    }
    input原材料_その他.value = '';

    select受注.value = 99140001;
    input受注_その他.value = '';

    select発注.value = 99150001;
    input発注_その他.value = '';

    switch(value){
        case "建設業_専門工事業":
        case "建設業_ゼネコン":
            select出荷.value = 99160006;
            break;
        default:
            select出荷.value = 99160001;
    }
    input出荷_その他.value = '';

    select当社.value = 99170001;
    input当社_その他.value = '';

    select自社.value = 99180001;
    input自社_その他.value = '';

    select社内.value = 99190001;
    input社内_その他.value = '';

    switch(value){
        case "建設業_専門工事業":
        case "建設業_ゼネコン":
            select工場.value = 99200012;
            break;
        default:
            select工場.value = 99200001;
    }
    input工場_その他.value = '';

    select従業員.value = 99210001;
    input従業員_その他.value = '';

    select雇用.value = 99220001;
    input雇用_その他.value = '';

    select出社.value = 99230001;
    input出社_その他.value = '';

    select売上.value = 99240001;
    input売上_その他.value = '';

    select株主.value = 99260001;
    input株主_その他.value = '';

}

defineExpose({
    isProcessing,
    confirmReplacement,
});

</script>