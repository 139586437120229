<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            表紙
        </div>
        <div class="p2 col-md-2 mt-3 ms-auto d-flex align-items-center justify-content-center">
            <input type="button" class="btn btn-new-window-button btn-sm" style="font-size:12pt" value="BCPの更新記録へ" v-show="is_completed_introduction" :disabled="isProcessing" @click="showUpdateRecords">
        </div>
        <div class="p2 col-md-2 mt-3 ms-auto d-flex align-items-center justify-content-center">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-4 mt-5">
        今から策定するBCPは、あなたの{{ callReplaceWord("組織") }}において、緊急事態に直面した場合に、事業を継続または早期復旧させるために策定する計画です。<br>
        企業の場合は「事業継続計画」、行政や福祉施設等は「業務継続計画」を選択しましょう。また、独自のタイトルを付ける場合は、その他の欄にご入力ください。
    </div>
    <div class="row ms-4 mt-5">
        （1つを選択）
    </div>
    <div class="row ms-5 mt-3">
        <div class="form-check">
            <input class="form-check-input" type="radio" id="title1" v-model="selectTitle" value="事業継続計画（BCP）" @change="changeTitle">
            <label class="form-check-label" for="title1">
                事業継続計画（BCP）
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" id="title2" v-model="selectTitle" value="業務継続計画（BCP）" @change="changeTitle">
            <label class="form-check-label" for="title2">
                業務継続計画（BCP）
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" id="titleOther" v-model="selectTitle" value="その他" @change="changeTitle">
            <label class="form-check-label" for="titleOther">
                その他
            </label>
            <input type="text" class="form-control w-50" v-model="textOther" @change="inputTitle">
        </div>
    </div>
    <div class="row ms-4 mt-5">
        <label>タイトル</label>
        <div class="row col-md-6">
            <input type="text" class="form-control" placeholder="" v-model="title" :class="{'is-invalid': title_message.length > 0}">
            <div class='invalid-feedback'>{{ title_message }}</div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 mt-5 col-md-4 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="入門｜1.　基本方針｜目的・基本方針 へ進む" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 mt-5 ms-3 col-md-2 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="目次構成 へ戻る" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if(typeof props.bcp_title_info.title !== 'undefined'){
                if(props.bcp_title_info.title === "事業継続計画（BCP）"){
                    selectTitle.value = "事業継続計画（BCP）";
                    title.value = "事業継続計画（BCP）";
                }else if(props.bcp_title_info.title === "業務継続計画（BCP）"){
                    selectTitle.value = "業務継続計画（BCP）";
                    title.value = "業務継続計画（BCP）";
                }else{
                    selectTitle.value = "その他";
                    textOther.value = props.bcp_title_info.title;
                    title.value = props.bcp_title_info.title;
                }
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('111100');
        const initTitle = '事業継続計画（BCP）';
        const title = ref(initTitle);
        const isProcessing = ref(false);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showUpdateRecords = async () => {
            // タイトルを登録
            const result = await registerIntroduction1();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/pdca/8';
                location.href = url;
            }
        }

        const showBack = async () => {
            // タイトルを登録
            const result = await registerIntroduction1();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // タイトルを登録
            const result = await registerIntroduction1();

            if (result == '200') {
                // 次のページへ遷移
                const url = '/bcp/bcp_introduction/2';
                location.href = url;
            }
        }

        const showContents = async () => {
            // タイトルを登録
            const result = await registerIntroduction1();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const title_message = ref("");
        // タイトル登録
        const registerIntroduction1 = async () => {
            isProcessing.value = true;
            title_message.value = true;
            const postUrl = '/api/bcp/registerIntroduction1';

            let bcpVersion = 1;
            if (props.bcp_version_info.version != '' && props.bcp_version_info.version != undefined) {
                bcpVersion = props.bcp_version_info.version;
            }

            return await axios.post(postUrl, {
                'corp_id': props.corp_info.id,
                'bcp_id': props.bcp_title_info.id,
                'title': title.value,
                'version': bcpVersion
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                if(err.response.status === 422){
                    if(err.response.data.errors.title){
                        title_message.value = err.response.data.errors.title[0];
                    }
                }
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const selectTitle = ref("");
        const textOther = ref("");

        const changeTitle = () => {
            if(selectTitle.value === "その他"){
                title.value = textOther.value;
            }else{
                title.value = selectTitle.value;
            }
        }

        const inputTitle = () => {
            title.value = textOther.value;
        }

        return {
            setLevel,
            title,
            isProcessing,
            callReplaceWord,
            showUpdateRecords,
            showNext,
            showBack,
            showContents,
            selectTitle,
            textOther,
            changeTitle,
            inputTitle,
            title_message,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        is_completed_introduction: {
            type: Boolean,
        },
        bcp_title_info: {
            type: Object,
        },
        bcp_version_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
