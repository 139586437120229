<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            入門コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>

    <div class="card">
        <div class="card-body">

            <div class="row">
                <div class="p2 col-md">
                    <label class="form-label">5. BCPの運用</label>
                    <label class="ms-3 bg-bcp-introduction text-light px-4 py-1 col-form-label text-nowrap">様式5</label>
                </div>
            </div>

            <div class="row mt-3 mx-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">BCPの定着</label>
                </div>
            </div>

            <div class="row mt-3 mx-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">○ BCPの重要性や進捗状況等を社内に周知するため、定期的に従業員に対して、以下の教育を実施する。</label>
                </div>
            </div>

            <div class="row mt-3 mx-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">教育計画</label>
                </div>
            </div>

            <div class="row mt-3 mx-1 width-min-600">
                <div class="p2 col-3 col-md-2 border border-gray width-min-100 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">誰が？</label>
                </div>

                <div class="p2 col-6 col-md-7 border border-gray width-min-300 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">何をする？</label>
                </div>

                <div class="p2 col-3 border border-gray width-min-150 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">いつ？もしくは<br>どのくらいの頻度で？</label>
                </div>
            </div>

            <div class="row mx-1 width-min-600" v-for="item in valueForms">
                <div class="p2 col-3 col-md-2 border border-gray width-min-100 border-top-0  d-flex justify-content-start">
                    <div class="border-white form-control">{{ item[1] || '&emsp;' }}</div>
                </div>

                <div class="p2 col-6 col-md-7 border border-gray width-min-300 border-top-0  d-flex justify-content-start">
                    <div class="border-white form-control">{{ item[3] || '&emsp;' }}</div>
                </div>

                <div class="p2 col-3 border border-gray width-min-150 border-top-0 d-flex justify-content-start">
                    <div class="border-white form-control">{{ item[2] || '&emsp;' }}</div>
                </div>
            </div>

            <div class="row mt-3 mx-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">BCPの見直し</label>
                </div>
            </div>

            <div class="row mt-3 mx-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">○ BCPの実効性を確保するため、以下の基準に基づきBCPの見直しを行う。</label>
                </div>
            </div>

            <div class="row mx-1 pb-5">
                <div class="col-12 border border-gray" v-for="item in standardList" :key="item.seq">
                    <div class="border-white form-control">{{ item.title || '&emsp;' }}</div>
                    <span class="second-line border-white form-control">{{ item.body }}</span>
                </div>
                <div class="col-12 border border-gray">
                    <div class="border-white form-control" v-html="convertNewLine(textOther)"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="BCP閲覧へ戻る" @click="showHome" />
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import levelIcon from '../common/UserLevelIconsComponent.vue';
import ReplaceWord from '../../function/CommonReplaceWord';
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_introduction_texts_info.length > 0) {
                props.bcp_introduction_texts_info.forEach(data => {
                    // textForms[columnName].value = props.bcp_introduction_texts_info.find(
                    //     (fetchData) => fetchData.column_name === columnName
                    // )?.text_value;
                    if(data.column_name === "N00061" || data.column_name === "N00062" || data.column_name === "N00063"){
                        if(textOther.value.length === 0){
                            textOther.value = data.text_value;
                        }else{
                            textOther.value = textOther.value + "\r\n" + data.text_value;
                        }
                    }
                });
            }

            if (props.bcp_introduction_values_info.length > 0) {
                const data = props.bcp_introduction_values_info
                    .filter((fetchData) => {
                        const column = fetchData.column_name.replace('N', '');
                        return Number(column) >= 56;
                    })
                    .sort((a, b) => {
                        const column1 = a.column_name.replace('N', '');
                        const column2 = b.column_name.replace('N', '');

                        if (column1 === column2) {
                            return a.seq < b.seq ? -1 : 1;
                        }
                        return Number(column1) < Number(column2) ? -1 : 1;
                    });
                const uniqueList = data.reduce((array, item) => {
                    const key = item.column_name;

                    return array.some(k => k === key)
                        ? array
                        : array.concat(key);
                }, []);

                uniqueList.forEach((key) => {
                    const filteredData = data.filter((d) => d.column_name === key && d.column_name !== 'N10061');
                    const obj = {};
                    filteredData.forEach((d) => obj[d.seq] = d.value)
                    valueForms.push(obj);
                });

                const wkArray = props.bcp_introduction_values_info.filter(item => item.column_name === 'N10061').map(item => item.seq);
                if(wkArray.length > 0){
                    wkArray.forEach(val=>{
                        let wkObj;
                        wkObj = textForms.filter(item=>item.seq === val);

                        if(wkObj.length > 0){
                            standardList.value.push({
                                columnName: wkObj[0].columnName,
                                title: wkObj[0].title,
                                body: wkObj[0].body,
                                seq: wkObj[0].seq,
                            });
                        }
                    });
                }
            }
        });

        const setLevel = ref('100000');
        const isMargin1 = ref(true);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const textForms = reactive([
            {
                columnName: 'N10061',
                title: "定期的な見直し",
                body: "毎年1回以上、事前対策の進捗状況や問題点をチェックし、必要に応じてBCPを見直す。",
                seq: 1,
            },
            {
                columnName: 'N10061',
                title: callReplaceWord("組織") + "の構造変更",
                body: "合併、買収、新規事業の開始、人事異動、組織改編など、内部環境に大きな変化があった場合にBCPを見直す。",
                seq: 2,
            },
            {
                columnName: 'N10061',
                title: "業務プロセスの変更",
                body: "業務プロセスの大幅な変更など、内部環境に大きな変化があった場合にBCPを見直す。",
                seq: 3,
            },
            {
                columnName: 'N10061',
                title: "サプライチェーンや主要パートナーの変更",
                body: "主要なサプライヤー、サービスプロバイダー、パートナー企業の変更や破綻、またはサプライチェーンの大幅な再編成があった場合にBCPを見直す。",
                seq: 4,
            },
            {
                columnName: 'N10061',
                title: "設備や技術の導入・更新",
                body: "新しいシステム、ソフトウェア、ハードウェア、設備の導入や既存技術の更新が行われた場合にBCPを見直す。",
                seq: 5,
            },
            {
                columnName: 'N10061',
                title: "法規制や業界標準の変更",
                body: "法律や業界の規制、標準、ガイドラインが変更された場合にBCPを見直す。",
                seq: 6,
            },
            {
                columnName: 'N10061',
                title: "組織の財務状況の変化",
                body: "重大な財務状況の変化（経済状況の悪化、予算の大幅な削減、資金繰りの変化など）があった場合にBCPを見直す。",
                seq: 7,
            },
            {
                columnName: 'N10061',
                title: "新たなリスクの出現やリスク評価の変化",
                body: "新しい脅威やリスク（自然災害の頻度増加、サイバー攻撃の増加、パンデミックなど）が明らかになった場合、または既存のリスク評価が変化した場合にBCPを見直す。",
                seq: 8,
            },
            {
                columnName: 'N10061',
                title: "BCP訓練で問題点が発見された場合や実際の緊急対応後のフィードバック",
                body: "BCPの訓練や演習を実施した結果、問題や改善点が見つかった場合。または実際の緊急対応後に得られた教訓や問題点を反映するために見直す。",
                seq: 9,
            },
        ]);

        const valueForms = reactive([]);

        const showBack = () => {
            const url = "/browse/N4";
            location.href = url;
        }

        const showHome = async () => {
            const url = '/browse/home';
            location.href = url;
        }

        const createArray = (number) => {
            return [...Array(number)];
        }

        const standardList = ref([]);

        const textOther = ref("");

        const convertNewLine = (text) => {
            return text.replace(/\n/g, '<br>');
        }

        return {
            setLevel,
            isMargin1,
            textForms,
            valueForms,
            showBack,
            showHome,
            createArray,
            standardList,
            callReplaceWord,
            textOther,
            convertNewLine,
        }
    },
    props:{
        bcp_introduction_texts_info: {
            type: Object,
        },
        bcp_introduction_values_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.form-control {
    color: common_colors.colors(resilience-black);
}

.border-gray {
    border-color: common_colors.colors(resilience-gray) !important;
}

.width-min-600 {
    min-width: 600px;
}

.second-line {
    display: inline-block;
    text-indent: 20px; /* インデントの幅を調整できます */
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border-gray {
        border-color: black !important;
    }
}
</style>
